import React, { ReactNode } from "react";
import { CenterContent, PageContainer } from "./results.styles";
import {
  QuizContainer,
  HeaderContainer,
  HeaderTitle,
  Description,
  NavigationContainer,
} from "../index.styles";
import { useNavigate } from "react-router-dom";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import Button from "../../button";

type ResultsProps = {
  onRestart?: () => void;
  header: string;
  paragraph: string;
  imageSrc: string | ReactNode;
  imgBackground?: string;
};

const Results = ({
  header,
  paragraph,
  imageSrc,
  imgBackground,
}: ResultsProps) => {
  const navigate = useNavigate();
  const isMobile = useMobileDetect();

  return (
    <PageContainer goHome>
      <QuizContainer>
        <CenterContent imgBackground={imgBackground}>
          {typeof imageSrc === "string" && (
            <img
              src={typeof imageSrc === "string" ? imageSrc : ""}
              alt={header}
              width="100%"
            />
          )}
          {typeof imageSrc === "object" && imageSrc}
        </CenterContent>

        <HeaderContainer>
          <HeaderTitle isMobile={isMobile}>{header}</HeaderTitle>
        </HeaderContainer>

        <Description>
          <p>{paragraph}</p>
        </Description>
      </QuizContainer>
      <NavigationContainer
        isMobile={isMobile}
        style={{ justifyContent: "center" }}
      >
        <Button
          text="Finish Quiz"
          colour="#225C90"
          onClick={() => {
            navigate("/");
          }}
          style={{
            minWidth: isMobile ? "7rem" : "10rem",
          }}
        />
      </NavigationContainer>
    </PageContainer>
  );
};

export default Results;
