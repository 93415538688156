import React, { useState } from "react";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import Button from "../button";
import Modal from "../modal";
import {
  CloseButton,
  DialogBoxBody,
  DialogBoxHeader,
  DialogBoxTitle,
  DialogBoxWrapper,
  ButtonContainer,
} from "./index.styles";

type ConfirmTeamRequestModalProps = {
  isOpen: boolean;
  title: string;
  body: string;
  onAccept: (grantPermissions: boolean) => void;
  onDeny: () => void;
  onClose: () => void;
};

const ConfirmTeamRequestModal = React.memo(
  ({
    isOpen,
    title,
    body,
    onAccept,
    onDeny,
    onClose,
  }: ConfirmTeamRequestModalProps) => {
    const isMobile = useMobileDetect();
    const [grantPermissions, setGrantPermissions] = useState(false);

    const handleAccept = () => {
      onAccept(grantPermissions);
      onClose();
    };

    const handleDeny = () => {
      onDeny();
      onClose();
    };

    return (
      <Modal isOpen={isOpen} closeModal={onClose}>
        <DialogBoxWrapper>
          <DialogBoxHeader>
            <DialogBoxTitle>{title}</DialogBoxTitle>
            <CloseButton
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={onClose}
            >
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="#282828"
              />
            </CloseButton>
          </DialogBoxHeader>
          <DialogBoxBody>
            <p>{body}</p>
            {/* <div>
              <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <input
                  type="checkbox"
                  checked={grantPermissions}
                  onChange={(e) => setGrantPermissions(e.target.checked)}
                />
                Grant Accept/Deny Permissions
              </label>
            </div> */}
          </DialogBoxBody>

          <ButtonContainer>
            <Button
              text="Accept"
              colour="#4CAF50"
              onClick={handleAccept}
              style={
                typeof isMobile !== "undefined" && isMobile
                  ? { minWidth: "140px" }
                  : {}
              }
            />
            <Button
              text="Deny"
              colour="#F44336"
              onClick={handleDeny}
              style={
                typeof isMobile !== "undefined" && isMobile
                  ? { minWidth: "140px" }
                  : {}
              }
            />
          </ButtonContainer>
        </DialogBoxWrapper>
      </Modal>
    );
  }
);

export default ConfirmTeamRequestModal;
