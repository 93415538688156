import React, { useEffect, useState } from "react";
import {
  StyledSpaceGrotesk,
  StyledManrope,
  ButtonContainer,
  NoneButton,
  DashboardHeaderContainer,
  StyledLink,
  ButtonGroup,
  BigButton,
  IconContainer,
  Image,
  SmallText,
  LargeText,
  StatusContainer,
  ProfileName,
  ProfileTitle,
  ProfileTextContainer,
  ProfileButton,
  StatusRow,
  ProfileImage,
  StatusButton,
  RSVPButton,
  StatusBar,
  StatusBar2,
  StatusText,
} from "./index.styles";
import { FaAngleRight } from "react-icons/fa6";

import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { HomeButton } from "./home-button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, setCharacterName } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {
  HACKLIFE_RELEASE,
  PERSONALITY_QUIZ_RELEASE,
  PHASE_ONE_RELEASE,
  TEAM_FINDER_RELEASE,
  RSVP_CLOSED,
  SCHEDULE_RELEASE,
  DISCORD_RELEASE,
  HACKER_PACK_RELEASE
} from "../../../utils/featureFlags";
import { charDetails } from "../../../components/quiz/characters";
import { ConstructionOutlined } from "@mui/icons-material";
type HomeProps = {
  isDrawerOpen?: boolean;
};

const HomePageContent: React.FC<HomeProps> = React.memo(({ isDrawerOpen }) => {
  const [catImg, setCatImg] = useState("none");
  const [stepOneEasterEgg, setStepOneEasterEgg] = useState<boolean>(false);
  const [showEasterEgg, setShowEasterEgg] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  const { didRSVP, isAccepted, isWaitlisted, isRejected } = useSelector(
    (state: RootState) => state.data
  );

  const { app_started, app_completed, character_name, redux_character_name } =
    useSelector((state: RootState) => state.application);

  const { userFName, userLName } = useSelector(
    (state: RootState) => state.data
  );

  // get first name from user name if exists and store in name
  let name = userFName
    ? userFName.charAt(0).toUpperCase() + userFName.slice(1).toLowerCase()
    : "";

  let fullName =
    userFName && userLName
      ? `${userFName.charAt(0).toUpperCase() + userFName.slice(1).toLowerCase()
      } ${userLName.charAt(0).toUpperCase() + userLName.slice(1).toLowerCase()
      }`
      : "Hacker";

  const dashboardHeader = "UofTHacks 12 Portal";
  const beforeApplyingText = (
    <>Applications are due November 29, 2024 @ 11:59 PM ET</>
  );
  const deadlinePassedText = (
    <>
      Oops! It looks like the application deadline for UofTHacks 12 has passed.
      Don’t worry—there’s always next year, and we’d love to see you then!
    </>
  );
  const rsvpDealinePassed = (
    <>
      Oops! It looks like the application deadline to RSVP has passed.
      We hope to see you next year!
    </>
  );
  const noApplicationText = (
    <>
      Oops! It looks like the application deadline for UofTHacks 12 has passed.
      Don’t worry—there’s always next year, and we’d love to see you then!
    </>
  );
  const afterApplyingText = (
    <>
      Thank you for applying to UofTHacks 12! We will be reviewing your
      application and will get back to you soon :) <br /> Meanwhile, you can
      follow us on Instagram (
      <StyledLink href="https://www.instagram.com/uofthacks" target="_blank">
        @uofthacks
      </StyledLink>
      ) to stay up to date with our latest announcements!
    </>
  );
  const rsvpText = isAccepted ? (
    <>
      You have reserved your spot for UofTHacks 12, this portal will be updated
      with more information as we approach the event. We look forward to seeing
      you there!
    </>
  ) : (
    <>
      Thank you for reserving your spot on the waitlist. We will update your status as we approach the hackathon if we have extra space for more hackers.
    </>
  );

  const noRSVPText = RSVP_CLOSED ? rsvpDealinePassed : isAccepted ? (
    <>
      Congratulations on being accepted to UofTHacks 12, make sure to RSVP by
      11:59pm ET on January 10th, 2025 to reserve your spot!
    </>
  ) : (
    <>
      You have been put on our waitlist for UofTHacks 12. Please submit your RSVP by
      11:59pm ET on January 10th, 2025 to reserve your spot on the waitlist.
    </>
  );

  const underReviewText = <>Your application is currently under review.</>;

  const rejectedText = <>Unfortunately, you were not selected to attend UofTHacks 12. We look forward to seeing your application for UofTHacks 13!</>;

  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    if (character_name) {
      const { imageSrc } =
        charDetails[character_name.toLowerCase() as keyof typeof charDetails];
      setProfileImage(imageSrc);
    }
  }, [character_name]);

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Escape") {
        setStepOneEasterEgg(false)
        setShowEasterEgg(false);
        event.preventDefault();
      }
      else if (event.key == "1") {
        setStepOneEasterEgg(true);
        event.preventDefault();
      }
      else if (event.key == "2" && stepOneEasterEgg) {
        setShowEasterEgg(true);
        event.preventDefault();
      }
      else {
        setStepOneEasterEgg(false)
        setShowEasterEgg(false);
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [stepOneEasterEgg, showEasterEgg]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <ButtonContainer style={{ marginTop: 0 }} mobile={isMobile}>
        {PHASE_ONE_RELEASE && (
          <>
            <DashboardHeaderContainer mobile={isMobile}>
              <StyledSpaceGrotesk mobile={isMobile}>
                {dashboardHeader}
              </StyledSpaceGrotesk>
              <StyledManrope>
                {didRSVP
                  ? rsvpText
                  : (isAccepted || isWaitlisted)
                    ? noRSVPText
                    : !app_completed
                      ? noApplicationText
                      : isRejected
                        ? rejectedText
                        : underReviewText}
              </StyledManrope>
              <StatusRow mobile={isMobile ? true : false}>
                {didRSVP && isAccepted && (
                  <ProfileButton onClick={() => navigate("/profile")}>
                    <ProfileImage
                      src={showEasterEgg ? "/assets/characters/cat.svg" : profileImage || "/assets/characters/cat.svg"}
                      easteregg={showEasterEgg}
                    />
                    <ProfileTextContainer>
                      <ProfileTitle>View Profile</ProfileTitle>
                      <ProfileName>{fullName}</ProfileName>
                    </ProfileTextContainer>
                    <FaAngleRight />
                  </ProfileButton>
                )}

                <StatusButton
                  onClick={
                    isAccepted && didRSVP && TEAM_FINDER_RELEASE
                      ? () => navigate("/team-finder")
                      : undefined
                  }
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "center" : "flex-start",
                    justifyContent: isMobile ? "center" : "flex-start",
                    cursor:
                      isAccepted && didRSVP && TEAM_FINDER_RELEASE ? "pointer" : "unset",
                    background:
                      app_completed && (!didRSVP || TEAM_FINDER_RELEASE)
                        ? "#225c90"
                        : "#4e5861",
                  }}
                >
                  {didRSVP && isAccepted ? (
                    <StatusContainer
                      style={{ alignItems: "flex-start", width: "100%" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: "flex-start",
                          gap: "12px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src="/assets/home/team.svg"
                          alt=""
                          style={{
                            width: "35px",
                            flexShrink: 0,
                            margin: "auto 0",
                          }}
                        />
                        <div style={{ width: "100%" }}>
                          <StatusBar2
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Assemble your team
                          </StatusBar2>
                          <StatusText
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {TEAM_FINDER_RELEASE ? "Team Builder (Beta)" : "Team Builder (Coming Soon!)"}
                          </StatusText>
                        </div>
                      </div>
                    </StatusContainer>
                  ) : (
                    <StatusContainer
                      style={{
                        alignItems: "flex-start",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: isMobile ? "flex-start" : "center",
                        flexDirection: "column",
                      }}
                    >
                      <StatusBar
                        status={
                          isAccepted
                            ? "accepted"
                            : isWaitlisted
                              ? "waitlisted"
                              : !app_completed
                                ? "noApplication"
                                : isRejected
                                  ? "rejected"
                                  : "pending"
                        }
                        style={{
                          padding: isMobile ? "0px" : "0px",
                          width: "100%",
                          justifyContent: "flex-start",
                        }}
                      >
                        Application Status
                      </StatusBar>
                      <StatusText style={{ width: "100%", textAlign: "left" }}>
                        {RSVP_CLOSED
                          ? didRSVP ? "Accepted" : "No Longer Under Consideration"
                          : isAccepted
                            ? "Accepted"
                            : isWaitlisted
                              ? "Waitlisted"
                              : !app_completed
                                ? "Unfortunately you did not submit an application"
                                : isRejected
                                  ? "No Longer Under Consideration"
                                  : "Under Review"}
                      </StatusText>
                    </StatusContainer>
                  )}
                  {(isAccepted || isWaitlisted) && !didRSVP && !RSVP_CLOSED && (
                    <RSVPButton
                      onClick={() => navigate("/rsvp")}
                      style={
                        isMobile
                          ? { width: "100%", marginTop: "12px" }
                          : { alignSelf: "center" }
                      }
                    >
                      RSVP Now
                      <FaAngleRight />
                    </RSVPButton>
                  )}
                </StatusButton>
              </StatusRow>
              <ButtonGroup mobile={isMobile}>
                {didRSVP && isAccepted && HACKER_PACK_RELEASE && (
                  <BigButton
                    onClick={() => window.open('/hacker-package.pdf', '_blank')}
                  >
                    <IconContainer>
                      <img
                        src="/assets/icons/page.svg"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </IconContainer>
                    <SmallText>Hackathon Info</SmallText>
                    <LargeText>Hacker Package</LargeText>
                  </BigButton>
                )}
                {didRSVP && isAccepted && SCHEDULE_RELEASE && (
                  <BigButton
                    onClick={() => navigate("/schedule")}
                  >
                    <IconContainer>
                      <img
                        src="/assets/icons/schedule-dark.svg"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </IconContainer>
                    <SmallText>See what's going on</SmallText>
                    <LargeText>Schedule</LargeText>
                  </BigButton>

                )}
              </ButtonGroup>
              <ButtonGroup mobile={isMobile}>
                {/* <BigButton style={isMobile ? { width: "95vw" } : { width: isAccepted ? "31%" : "48%" }}>
              <IconContainer>
                <img
                  src="/assets/home/crown.svg"
                  alt=""
                  style={{ width: "100%" }}
                />
              </IconContainer>
              <SmallText>Take the quiz</SmallText>
              <LargeText>Which hacker are you?</LargeText>
            </BigButton> */}
                {didRSVP && isAccepted && HACKLIFE_RELEASE && (
                  <BigButton
                    onClick={() => navigate("/game")}
                  >
                    <IconContainer>
                      <img
                        src="/assets/home-page/gamepad.svg"
                        alt=""
                        style={{ width: "100%", color: "black" }}
                      />
                    </IconContainer>
                    <SmallText>Hacker Game</SmallText>
                    <LargeText>HackLife</LargeText>
                  </BigButton>

                )}
                {didRSVP && isAccepted && DISCORD_RELEASE && (
                  <BigButton
                    onClick={() => (window.open('https://discord.gg/GYRCtTtqkM', '_blank', 'noopener,noreferrer'))}
                  >
                    <IconContainer>
                      <img
                        src="/assets/icons/chat-bubble.svg"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </IconContainer>
                    <SmallText>Join the Discord</SmallText>
                    <LargeText>Discord Server</LargeText>
                  </BigButton>

                )}
                <BigButton
                  onClick={() => navigate("/faq")}
                >
                  <IconContainer>
                    <img
                      src="/assets/home/faq.svg"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </IconContainer>
                  <SmallText>Have questions?</SmallText>
                  <LargeText>FAQ</LargeText>
                </BigButton>
              </ButtonGroup>
            </DashboardHeaderContainer>
          </>
        )}
      </ButtonContainer>
      {!PHASE_ONE_RELEASE && (
        <>
          <DashboardHeaderContainer mobile={isMobile}>
            <StyledSpaceGrotesk mobile={isMobile}>
              {dashboardHeader}
            </StyledSpaceGrotesk>
            <StyledManrope>{afterApplyingText}</StyledManrope>
            <ButtonContainer mobile={isMobile}>
              {/*<HomeButton*/}
              {/*  color="#FFD28E"*/}
              {/*  title="My Passport"*/}
              {/*  image="/assets/home-page/profile.svg"*/}
              {/*  onClick={() => {*/}
              {/*    navigate("/profile");*/}
              {/*  }}*/}
              <HomeButton
                // disabled={app_completed}
                disabled={true}
                color="#BAE8FC"
                title={
                  app_completed
                    ? // ? "View Application"
                    "View Application"
                    : !app_started
                      ? "Start Application"
                      : "Continue Your Application"
                }
                image={
                  app_completed
                    ? // ? "/assets/home-page/app.svg"
                    // : "/assets/home-page/locked.svg"
                    "/assets/home-page/app.svg"
                    : "/assets/home-page/app.svg"
                }
                onClick={() => {
                  // if (app_completed) {
                  //   dispatch(setAppPage(1));
                  // }
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  // if (!app_completed) {
                  //   navigate("/application");
                  // }
                  navigate("/application");
                }}
              />
              <HomeButton
                color="#B6FFF6"
                title="Which Hacker Are You?"
                image={
                  app_completed
                    ? "/assets/home-page/play.svg"
                    : "/assets/home-page/locked.svg"
                }
                disabled={!app_completed || !PERSONALITY_QUIZ_RELEASE}
                onClick={() => {
                  if (character_name === "" || character_name === undefined) {
                    dispatch(setCharacterName(redux_character_name));
                  }
                  if (app_completed) {
                    navigate("/quiz");
                  }
                }}
              />
            </ButtonContainer>
            <ButtonContainer mobile={isMobile}>
              <HomeButton
                color="#FFCDF7"
                title="FAQ / Contact Us"
                image="/assets/home-page/faq.svg"
                onClick={() => navigate("/faq")}
              />
              <HomeButton
                color="#FCE2BA"
                title="HackLife"
                image="/assets/home/gamepad.svg"
                onClick={() => navigate("/game")}
              />
            </ButtonContainer>
          </DashboardHeaderContainer>
        </>
      )}
      {/* WILL BE IN SAME BUTTON CONTAINER AS HACKER PACK */}
      {/* <ButtonContainer mobile={isMobile}>
        <HomeButton
          color="#C0F9EB"
          title="Hacker Schedule"
          image="/assets/home-page/schedule.svg"
          onClick={() => navigate("/schedule")}
        />
        <NoneButton />
      </ButtonContainer> */}
      {/* <ButtonContainer mobile={isMobile}>
        <HomeButton
          color="#FFD28E"
          title="View Profile"
          image="/assets/home-page/profile.svg"
        />
        <HomeButton
          color="red"
          title="Hacker Schedule"
          disabled
          image="/assets/home-page/locked.svg"
        />
      </ButtonContainer>
      */}
    </div>
  );
});

export { HomePageContent };
