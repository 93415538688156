import React from "react";
import { StyledButton } from "./index.styles";
import { StyledInterButton } from "../button/index.styles";

interface Props {
  text: string;
  image?: string;
  notTransparent?: boolean;
  colour?: string;
  textColour?: string;
  style?: any;
  onClick?: (() => void) | ((optionIndex: number) => void);
  editImage?: boolean;
  isMobile?: boolean | null;
  disabled?: boolean;
  filled?: boolean;
}

const Button = ({
  colour,
  textColour,
  onClick,
  text,
  style,
  notTransparent,
  image,
  editImage,
  isMobile,
  disabled,
  filled = true,
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <StyledButton
      notTransparent={notTransparent}
      colour={colour}
      textColour={textColour}
      onClick={onClick ? () => onClick(0) : undefined}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isMobile={isMobile}
      disabled={disabled}
      filled={filled}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick && onClick(0);
        }
      }}
    >
      {image && <img src={image} alt={text} />}
      <StyledInterButton>{text}</StyledInterButton>
    </StyledButton>
  );
};

export default Button;
