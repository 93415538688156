import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import * as teamService from "../services/teamFinderService";
import { useAuth } from "../providers/AuthProvider";
import {FetchError} from "../services/teamFinderService";

const handleAuthError = async (
    fn: () => Promise<any>,
    handleExpiredTokens: () => void
) => {
    try {
        return await fn();
    } catch (error: any) {
        if (error instanceof FetchError && error.status === 401) {
            handleExpiredTokens();
        }
        throw error;
    }
};

export const useFetchTeams = (queryParams: Record<string, any>, accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useQuery({
        queryKey: ["teams", queryParams],
        queryFn: () =>
            handleAuthError(() => teamService.fetchTeams(queryParams, accessToken), handleExpiredTokens),
        enabled: !!queryParams,
    });
};

export const useFetchTeamById = (team_id: number, accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useQuery({
        queryKey: ["team", team_id],
        queryFn: () =>
            handleAuthError(() => teamService.fetchTeamById(team_id, accessToken), handleExpiredTokens),
        enabled: !!team_id,
    });
};

export const useAddTeam = (accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useMutation({
        mutationFn: (data: {
            team_name: string;
            is_private?: boolean;
            looking_for_member?: boolean;
            team_project?: string;
            team_motto?: string;
            team_members?: number[];
        }) =>
            handleAuthError(() => teamService.addTeam(data, accessToken), handleExpiredTokens),
    });
};

export const useEditTeam = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: {
            team_id: number;
            team_name?: string;
            looking_for_member?: boolean;
            team_project?: string;
            team_motto?: string;
        }) =>
            handleAuthError(() => teamService.editTeam(data, accessToken), handleExpiredTokens),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: ["team", variables.team_id],
            });
            queryClient.invalidateQueries({
                queryKey: ["team", "my"],
            });
        },
    });
};

export const useTogglePrivacy = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { team_id: number }) =>
            handleAuthError(() => teamService.togglePrivacy(data, accessToken), handleExpiredTokens),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: ["team", variables.team_id],
            });
            queryClient.invalidateQueries({
                queryKey: ["team", "my"],
            });
        },
    });
};

export const useJoinTeam = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { team_pin: string }) =>
            handleAuthError(() => teamService.joinTeam(data, accessToken), handleExpiredTokens),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["team", "my"],
            });
        },
    });
};

export const useSearchTeamByPin = (team_pin: string, accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useQuery({
        queryKey: ["team", "search", team_pin],
        queryFn: () =>
            handleAuthError(() => teamService.searchTeamByPin(team_pin, accessToken), handleExpiredTokens),
        enabled: !!team_pin,
    });
};

export const useCreateJoinRequest = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { team_id: number }) =>
            handleAuthError(() => teamService.createJoinRequest(data, accessToken), handleExpiredTokens),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: ["teams"],
            });
            queryClient.invalidateQueries({
                queryKey: ["requests", "mine"],
            });
        },
    });
};

export const useCancelJoinRequest = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { request_id: number }) =>
            handleAuthError(() => teamService.cancelJoinRequest(data, accessToken), handleExpiredTokens),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: ["teams"],
            });
            queryClient.invalidateQueries({
                queryKey: ["requests", "mine"],
            });
        },
    });
};

export const useGetPendingRequests = (team_id: number, accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useQuery({
        queryKey: ["requests", "pending", team_id],
        queryFn: () =>
            handleAuthError(() => teamService.getPendingRequests(team_id, accessToken), handleExpiredTokens),
        enabled: !!team_id,
    });
};

export const useManageTeamRequest = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { request_id: number; action: "accept" | "deny" }) =>
            handleAuthError(() => teamService.manageTeamRequest(data, accessToken), handleExpiredTokens),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: ["requests", "pending"],
            });
            queryClient.invalidateQueries({
                queryKey: ["team", "my"],
            });
        },
    });
};

export const useGetMyRequests = (accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useQuery({
        queryKey: ["requests", "mine"],
        queryFn: () =>
            handleAuthError(() => teamService.getMyRequests(accessToken), handleExpiredTokens),
    });
};

export const useGetMyTeam = (accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useQuery({
        queryKey: ["team", "my"],
        queryFn: () =>
            handleAuthError(() => teamService.getMyTeam(accessToken), handleExpiredTokens),
    });
};

export const useGetHackerProfileById = (hacker_id: number, accessToken?: string) => {
    const { handleExpiredTokens } = useAuth();
    return useQuery({
        queryKey: ["hacker", hacker_id],
        queryFn: () =>
            handleAuthError(() => teamService.getHackerProfileById(hacker_id, accessToken), handleExpiredTokens),
        enabled: !!hacker_id,
    });
};

export const useLeaveTeam = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { team_id: number }) =>
            handleAuthError(() => teamService.leaveTeam(data, accessToken), handleExpiredTokens),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["team", "my"],
            });
        },
    });
};

export const useDeleteTeam = (accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { team_id: number }) =>
            handleAuthError(() => teamService.deleteTeam(data, accessToken), handleExpiredTokens),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["team", "my"],
            });
        },
    });
};

export const useRemoveTeamMember = (team_id: number, accessToken?: string) => {
    const queryClient = useQueryClient();
    const { handleExpiredTokens } = useAuth();

    return useMutation({
        mutationFn: (data: { team_id: number, hacker_id: number }) =>
            handleAuthError(() => teamService.removeTeamMember(data, accessToken), handleExpiredTokens),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["team", "my"],
            });
            queryClient.invalidateQueries({
                queryKey: ["team", team_id],
            });
        },
    });
}
