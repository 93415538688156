import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BackButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  color: #225c90;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
`;

const BackIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <BackButtonWrapper onClick={() => navigate(-1)}>
            <BackIcon src="/assets/icons/arrow-back.svg" alt="Back" />
        </BackButtonWrapper>
    );
};

export default BackButton;
