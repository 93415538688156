import React, { useState } from "react";
import styled from "styled-components";
import {
  CompletionBarContainer,
  CompletionContent,
  DescriptionBold,
  FadeInComponent,
  FadeInOnlyComponent,
  Popover,
  PopoverContent,
  ProgressBar,
  ProgressLevel,
  ProgressLabel,
  Step,
} from "./index.styles";
import { useMobileDetect } from "../../hooks/useMobileDetect";

interface ProjectCompletionBarProps {
  currentStep: number; // Current step in the project
  maxSteps?: number; // Maximum steps (default to 20)
  permanent_popover?: boolean; // Whether the popover should be permanent
}

export const ProjectCompletionBar: React.FC<ProjectCompletionBarProps> = ({
  currentStep = 0,
  maxSteps = 20,
  permanent_popover = false,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const isMobile = useMobileDetect();

  return (
    <CompletionBarContainer
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
    >
      <DescriptionBold>Project Completion</DescriptionBold>
      <CompletionContent mobile={isMobile}>
        <ProgressBar colour={currentStep > maxSteps ? "#FFCDF7" : "#0b3962"}>
          {/* {Array.from({ length: maxSteps }, (_, index) => {
            const isFilled = index < currentStep;
            return <Step key={index} isFilled={isFilled} />;
          })} */}
          {currentStep <= maxSteps && <ProgressLevel colour="#FFCDF7" level={Math.min((currentStep / maxSteps) * 100, 100)} />}
          <ProgressLevel colour="#B6FFF6" level={Math.max(Math.min(((currentStep - maxSteps) / maxSteps) * 100, 100), 0)} />
        </ProgressBar>
        <ProgressLabel>
          {Math.round((currentStep / maxSteps) * 100)}%
        </ProgressLabel>
      </CompletionContent>
      <FadeInOnlyComponent shouldFadeInOpp={showPopover} style={{ zIndex: 3 }}>
        {(showPopover || permanent_popover) && (
          <Popover>
            <PopoverContent>
              <p>
                The <strong>project completion bar</strong> represents how close
                you are to completing your current project. As you work on
                tasks, debug code, or solve challenges, your progress will
                increase.
              </p>
            </PopoverContent>
          </Popover>
        )}
      </FadeInOnlyComponent>
    </CompletionBarContainer >
  );
};
