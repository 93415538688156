"use client";


// IMPORTANT NOTE: FUTURE WORK IS REFACTORING HACKER PORTAL TO USE HOOKS AND REACT QUERY INSTEAD OF REDUX

// Soon, these functions and hook will be fully used but for now there is a mismatch
// Refer to Team Finder subapp for what it will look like

import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetData, resetRSVP, setIsAuthenticatedRedux } from "../redux/store";
import {useToaster} from "./ToasterProvider";

interface AuthContextProps {
    isAuthed: boolean;
    isInitialized: boolean;
    getAccessToken: () => string | null;
    getRefreshToken: () => string | null;
    setAccessToken: (token: string) => void;
    setRefreshToken: (token: string) => void;
    clearAuth: () => void;
    handleExpiredTokens: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();

    const { setToaster } = useToaster()

    const [accessToken, setAccessTokenState] = useState<string | null>(null);
    const [refreshToken, setRefreshTokenState] = useState<string | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const storedAccessToken = localStorage.getItem("accessToken");
        const storedRefreshToken = localStorage.getItem("refreshToken");

        setAccessTokenState(storedAccessToken);
        setRefreshTokenState(storedRefreshToken);
        setIsInitialized(true);
    }, []);

    useEffect(() => {
        const syncTokensFromStorage = () => {
            const storedAccessToken = localStorage.getItem("accessToken");
            const storedRefreshToken = localStorage.getItem("refreshToken");

            setAccessTokenState(storedAccessToken);
            setRefreshTokenState(storedRefreshToken);
        };

        window.addEventListener("storage", syncTokensFromStorage);

        return () => {
            window.removeEventListener("storage", syncTokensFromStorage);
        };
    }, []);

    const setAccessToken = (token: string) => {
        localStorage.setItem("accessToken", token);
        setAccessTokenState(token);
    };

    const setRefreshToken = (token: string) => {
        localStorage.setItem("refreshToken", token);
        setRefreshTokenState(token);
    };

    const clearAuth = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        setAccessTokenState(null);
        setRefreshTokenState(null);
    };

    const getAccessToken = () => accessToken;
    const getRefreshToken = () => refreshToken;

    const isAuthed = Boolean(accessToken && refreshToken);

    const handleExpiredTokens = () => {
        dispatch(setIsAuthenticatedRedux(false));
        dispatch(resetData());
        dispatch(resetRSVP());
        setToaster("Your session has expired. Redirecting to login.", "error");
        clearAuth();
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthed,
                isInitialized,
                getAccessToken,
                getRefreshToken,
                setAccessToken,
                setRefreshToken,
                clearAuth,
                handleExpiredTokens,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
