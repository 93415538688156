import styled from "styled-components";

export const HelperContainer = styled.div`
    background-color: #ffffff;
    border: 2px solid #0daef1;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
`;

export const HelperItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const HelperImage = styled.img`
    border: 2px solid #0daef1;
    border-radius: 8px;
    margin-bottom: 20px;
`;

export const HelperText = styled.p`
    font-size: 14px;
    line-height: 1.5;
    color: #0b3962;
    margin: 0;
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #19191B;
    &:hover {
        opacity: 0.7;
    }
`;

export const HelperItemsRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
`;

export const SingleHelperItem = styled(HelperItem)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
`;
