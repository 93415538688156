import styled from "styled-components";
import Inter from "../inter";
import InterSemiBold from "../inter-semi-bold";

const InfoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
    border: 1.5px solid var(--Brand-Primary-200, #0DAEF1);
  border-radius: 12px;
  padding: 16px;
  background-color: #fff;
  width: 260px;
  padding: 20px;
`;

const ProfilePicture = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 24px;
  margin-bottom: 16px;
  img {
    border-radius: 50%;
  }
`;

const CardDetails = styled.div`
  flex: 1;
  font-size: 14px;
  color: #333;
`;

const Badge = styled.span`
  margin: 2px 0px 2px 4px;
  display: inline-block;
  background-color: #bae8fc;
  color: #000;
  border-radius: 5px;
  padding: 7px 12px;
  font-size: 12px;
  font-weight: 500;
`;

const StyledInter = styled(Inter)``;

export { InfoCardWrapper, ProfilePicture, CardDetails, Badge, StyledInter };
