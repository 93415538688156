import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import Button from "../button";
import Modal from "../modal";
import {
    CloseButton,
    DialogBoxBody,
    DialogBoxHeader,
    DialogBoxTitle,
    DialogBoxWrapper,
    ButtonContainer,
} from "./index.styles";
import { useAddTeam } from "../../hooks/useTeamFinder";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useToaster } from "../../providers/ToasterProvider"

type CreateTeamModalProps = {
    isOpen: boolean;
    title: string;
    button1Text?: string;
    button2Text?: string;
    button1Colour?: string;
    button2Colour?: string;
    onClose: () => void;
};

const CreateTeamModal = React.memo(
    ({
        isOpen,
        title,
        button1Text = "Create Team",
        button2Text = "Cancel",
        button1Colour = "blue",
        button2Colour = "blue",
        onClose,
    }: CreateTeamModalProps) => {
        const { setToaster } = useToaster()
        const isMobile = useMobileDetect();
        const navigate = useNavigate();
        const { authToken, userID } = useSelector((state: RootState) => state.data);
        const { mutate: addTeam, isPending: isLoading } = useAddTeam(authToken);

        const [teamName, setTeamName] = useState("");
        const [teamDescription, setTeamDescription] = useState("");
        const [isPublic, setIsPublic] = useState(false);
        const [errorMessage, setErrorMessage] = useState<string | null>(null); // State to store error message
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [teamPin, setTeamPin] = useState<string | null>(null); // State to store team pin
        const [teamId, setTeamId] = useState<number | null>(null); // State to store team ID

        const handleCreateClick = () => {
            setErrorMessage(null);
            addTeam(
                {
                    team_name: teamName,
                    is_private: !isPublic,
                    team_motto: teamDescription,
                    team_members: [userID],
                },
                {
                    onSuccess: (data) => {
                        setTeamPin(data.team_pin);
                        setTeamId(data.team_id);
                        setShowConfirmation(true);
                    },
                    onError: (error: any) => {
                        setErrorMessage(error.message || "Failed to create team");
                    },
                }
            );
        };

        const handleExit = () => {
            setShowConfirmation(false);
            onClose();
        };

        const handleViewTeam = () => {
            if (teamId) {
                navigate(`/team-info/${teamId}`);
            } else {
                setToaster("Unable to navigate to the team page.", "error");
            }
        };

        return (
            <>
                {!showConfirmation && (
                    <Modal isOpen={isOpen} closeModal={onClose}>
                        <DialogBoxWrapper>
                            <DialogBoxHeader>
                                <DialogBoxTitle>{title}</DialogBoxTitle>
                                <CloseButton
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    onClick={onClose}
                                >
                                    <path
                                        d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                        fill="#282828"
                                    />
                                </CloseButton>
                            </DialogBoxHeader>
                            <DialogBoxBody>
                                <label>
                                    <strong>NOTE: Any inappropriate names/info will result into your team being deleted.</strong>
                                </label>
                                {errorMessage && (
                                    <div style={{ color: "red", marginBottom: "10px" }}>
                                        {errorMessage}
                                    </div>
                                )}
                                <div style={{ marginBottom: "10px" }}>
                                    <label>
                                        <strong>Team Name:</strong>
                                    </label>
                                    <input
                                        type="text"
                                        value={teamName}
                                        onChange={(e) => setTeamName(e.target.value)}
                                        placeholder="Enter team name"
                                        style={{
                                            display: "block",
                                            padding: "8px",
                                            width: "300px",
                                            marginTop: "5px",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </div>

                                <div style={{ marginBottom: "10px" }}>
                                    <label>
                                        <strong>Team Info:</strong>
                                    </label>
                                    <textarea
                                        value={teamDescription}
                                        onChange={(e) => setTeamDescription(e.target.value)}
                                        placeholder="Write about your team! Some potential topics: team skills, goals, project ideas."
                                        rows={6}
                                        style={{
                                            display: "block",
                                            padding: "8px",
                                            width: "300px",
                                            marginTop: "5px",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            resize: "vertical",
                                        }}
                                    />
                                </div>

                                <div style={{ marginBottom: "10px" }}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isPublic}
                                            onChange={(e) => setIsPublic(e.target.checked)}
                                            style={{ marginRight: "5px" }}
                                        />
                                        <strong>Make Team Public</strong>
                                    </label>
                                </div>
                            </DialogBoxBody>

                            <ButtonContainer>
                                <Button
                                    text={button2Text}
                                    colour={button2Colour}
                                    onClick={onClose}
                                    filled={false}
                                    style={
                                        typeof isMobile !== "undefined" && isMobile
                                            ? { minWidth: "140px" }
                                            : {}
                                    }
                                />
                                <Button
                                    text={button1Text}
                                    colour={button1Colour}
                                    onClick={handleCreateClick}
                                    disabled={isLoading}
                                    style={
                                        typeof isMobile !== "undefined" && isMobile
                                            ? { minWidth: "140px" }
                                            : {}
                                    }
                                />
                            </ButtonContainer>
                        </DialogBoxWrapper>
                    </Modal>
                )}

                {showConfirmation && (
                    <Modal isOpen={showConfirmation} closeModal={handleExit}>
                        <DialogBoxWrapper>
                            <DialogBoxHeader>
                                <DialogBoxTitle>Congrats!</DialogBoxTitle>
                                <CloseButton
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    onClick={handleExit}
                                >
                                    <path
                                        d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                        fill="#282828"
                                    />
                                </CloseButton>
                            </DialogBoxHeader>
                            <DialogBoxBody>
                                <p>Congrats, you created a team!</p>
                                <p>Share your unique team PIN to invite others to your team.</p>
                                <h3 style={{ margin: "20px 0", fontWeight: "bold", color: "#225C90" }}>
                                    Team PIN: {teamPin}
                                </h3>
                                <h3 style={{ margin: "20px 0", fontWeight: "bold", color: "#225C90" }}>
                                    Team ID: {teamId}
                                </h3>
                            </DialogBoxBody>
                            <ButtonContainer>
                                <Button
                                    text="Exit"
                                    colour="gray"
                                    onClick={handleExit}
                                    style={{ minWidth: "140px" }}
                                />
                                <Button
                                    text="View Team"
                                    colour="blue"
                                    onClick={handleViewTeam}
                                    style={{ minWidth: "140px" }}
                                />
                            </ButtonContainer>
                        </DialogBoxWrapper>
                    </Modal>
                )}
            </>
        );
    }
);

export default CreateTeamModal;
