import React, { useEffect, useState } from "react";
import { OptionButton, OptionText, OptionsContainer } from "./index.styles";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import { UndoRounded } from "@mui/icons-material";

interface Option {
  text: string;
  nextStep: Array<string>
  effects?: {
    energy?: number;
    projectCompletion?: number;
    inTeam?: boolean;
    dayProgress?: number;
    phase?: number;
  };
}

interface QuestionTypes {
  handleOptionClick: (index: any) => void;
  options?: Option[];
  background?: string;
  selectedBackground?: string;
  border?: string;
  selectedIndex: number | null;
  showOptions: boolean;
}

const OptionsComponent = ({
  handleOptionClick,
  options,
  background = "#FFFFFF",
  selectedBackground = "#0B3962",
  border = "#0B3962",
  selectedIndex,
  showOptions = true,
}: QuestionTypes) => {
  const isMobile = useMobileDetect();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  useEffect(() => {
    setSelectedOption(null);

    if (selectedIndex !== null) {
      setSelectedOption(selectedIndex);
    }
  });

  // only render if there are options to render
  const optionRender = options == undefined ? (<></>) : (
    <OptionsContainer showOptions={showOptions}>
      {options.map((option, index) => (
        <OptionButton
          key={index}
          isSelected={selectedOption === index}
          onClick={() => {
            setSelectedOption(index);
            handleOptionClick(index);
          }}
          optionColor={background}
          border={border}
          optionSelectedColor={selectedBackground}
        >
          <OptionText isMobile={isMobile}>{option.text}</OptionText>
        </OptionButton>
      ))}
    </OptionsContainer>
  );

  return (
    optionRender
  );
};

export default OptionsComponent;
