import Drawer from "../dashboard/drawer";
import React, { useEffect, useState } from "react";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
  StyledSpaceGrotesk,
  StyledManrope,
  DashboardHeaderContainer,
  ContentWrapper,
} from "./index.styles";
import RequestsCollection from "../../components/team-requests/team-requests-collection";
import BackButton from "../../components/button/back-button";
import {StyledButton} from "../../components/dashboard/content/index.styles";

const TeamRequestsComponent = () => {
  const isMobile = useMobileDetect();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };


  return (
    <>
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} contentType={"profile"} />
      <StyledButton onClick={toggleDrawer} isDrawerOpen={isDrawerOpen}>
        ☰
      </StyledButton>
      <ContentWrapper isDrawerOpen={isDrawerOpen} mobile={isMobile}>
        <BackButton/>
        <DashboardHeaderContainer mobile={isMobile}>
          <StyledSpaceGrotesk mobile={isMobile}>Requests</StyledSpaceGrotesk>
          <StyledManrope>People who want to join your team</StyledManrope>
        </DashboardHeaderContainer>
        <RequestsCollection />
      </ContentWrapper>
    </>
  );
};

export default TeamRequestsComponent;