import React from "react";
import InfoCard from "../info-card";
import { useGetHackerProfileById } from "../../hooks/useTeamFinder";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import LoadingComponent from "../../components/loading-component";

type HackerInfoProps = {
  hacker_id: number;
  removeHacker?: () => void;
};

const HackerInfo: React.FC<HackerInfoProps> = ({ hacker_id, removeHacker }) => {

  const { authToken } = useSelector((state: RootState) => state.data);
  const { data: hackerProfile, isLoading, error } = useGetHackerProfileById(hacker_id, authToken);

  if (isLoading) {
    return <LoadingComponent isOpen={true} />
  }

  if (error || !hackerProfile) {
    return <p>Failed to load hacker information.</p>;
  }

  const year_str_map: { [key: string]: string } = {
    "Year 1 (Freshman)": "1st Year",
    "Year 2 (Sophomore)": "2nd Year",
    "Year 3 (Junior)": "3rd Year",
    "Year 4 (Senior)": "4th Year",
    "Year 5+": "5th+ Year",
    "Graduate Student": "Grad",
  }

  const year_str = year_str_map[hackerProfile.level_of_study.replaceAll("'", "")] || hackerProfile.level_of_study;

  return (
    <InfoCard
      name={`${hackerProfile.hacker_fname} ${hackerProfile.hacker_lname}`}
      pronouns={hackerProfile.pronoun || ""}
      university={hackerProfile.school || ""}
      year={year_str || ""}
      username={hackerProfile.hacker_discord || ""}
      bio={""}
      // bio={hackerProfile.hacker_description || ""}
      isTeamAdmin={hackerProfile.is_captain || false}
      removeHacker={!hackerProfile.is_captain ? removeHacker : undefined}
    />
  );
};

export default HackerInfo;
