import HackerQuizComponent from "../../components/hacker-quiz";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainContainer = styled.div`
  animation: ${fadeIn} 0.7s ease-in-out;
`;

const HackerQuiz = () => {

  return (
    <MainContainer>
      <HackerQuizComponent />
    </MainContainer>
  );
};

export default HackerQuiz;
