import styled from "styled-components";
import SpaceGrotesk from "../../space-grotesk";

type DrawerWrapperProps = {
  isOpen: boolean;
  mobile?: boolean | null;
};

type DrawerButtonProps = {
  onClick?: () => void;
  title?: string;
  image?: string;
  disabled?: boolean;
  dontHover?: boolean;
};

const DrawerWrapper = styled.div<DrawerWrapperProps>`
  height: 100%;
  border: 1px solid var(--Black, #282828);
  background: var(--Default-Stroke, #225c90);
  box-shadow: 0px 4px 0px 0px #282828;

  color: #fff;
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-1000px")};
  transition: left 0.5s ease-in-out;
  z-index: 1;
  width: 250px;
  display: flex;
  overflow: hidden;
  ${({ mobile }) =>
    mobile &&
    `
    width: 100vw; 
    border-radius: 0;
    `}
  @media (max-height: 500px) {
    overflow-y: auto;
  }
`;

const DrawerContent = styled.div`
  padding: 20px 15px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const StyledSpaceGrotesk = styled(SpaceGrotesk)`
  color: var(--White, #f9f9f9);
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1px;
  font-size: 30px;
  display: flex;
  justify-content: flex-start;
`;

const DrawerButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 80%;
  margin-top: 1rem;
  gap: 1rem;
  flex-direction: column;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const LogOutButton = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 80%;
  margin-top: 3rem;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: calc(100% - 170px);
  margin-top: auto;
  border-radius: 5px;
  border: 1px solid #f9f9f9;
  &:hover {
    border: 1px solid red;
    transition: 0.3s ease-in-out;
  }
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    color: grey;
    transition: color 0.5s ease;
  }
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const StyledText = styled.div<DrawerButtonProps>`
  color: ${(props) =>
    props.disabled ? "var(--Gray, #7B7C7F)" : "var(--White, #F9F9F9)"};
  leading-trim: both;
  text-edge: cap;
  /* Body 2 (14px) */
  //font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledImage = styled.img`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const ButtonContainer = styled.div<DrawerButtonProps>`
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  gap: 0.75rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  &:hover {
    background-color: ${(props) =>
      props.disabled ? "var(--Gray, #555)" : "var(--Pink, #FFCDF7)"};
    transition: background-color 0.5s ease;
    border-radius: 5px;
  }
  ${({ dontHover }) =>
    dontHover &&
    `
    &:hover {
      background-color: transparent;
      transition: background-color 0.5s ease;
      border-radius: 5px;
    }
  `}
  &:hover ${StyledText} {
    color: ${({ dontHover }) => (dontHover ? undefined : "black")};
  }
  &:hover ${StyledImage} {
    filter: ${({ dontHover }) =>
      dontHover ? "none" : "invert(0%) brightness(0%)"};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 16px 0;
`;

export {
  DrawerWrapper,
  DrawerContent,
  StyledSpaceGrotesk,
  DrawerButtonContainer,
  LogOutButton,
  CloseButton,
  ButtonContainer,
  StyledText,
  StyledImage,
  Divider,
};
