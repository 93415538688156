import Inter from "../inter";
import styled, { keyframes } from "styled-components";
import DashboardContainer from "../dashboard-container";
import SpaceGrotesk from "../space-grotesk";
import Manrope from "../manrope";
import { InfoBoxContainer } from "../dashboard-container/index.styles";
import { StyledButton } from "../button/index.styles";

// Containers

const MainContainer = styled.div<StyledDashboardProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type StyledDashboardProps = {
  mobile?: boolean | null;
  isQuiz?: boolean;
  goHome?: boolean;
};

const StyledDashboard = styled.div<StyledDashboardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin: ${(props) => (props.mobile ? "0 4vw 0 4vw" : "")};
  min-width: ${(props) => (props.mobile ? "" : "100%")};
  align-content: center;
  position: relative;
`;

const LightBlueQuizContainer = styled(InfoBoxContainer) <{
  isMobile?: boolean | null;
}>`
  background: rgba(186, 232, 252, 0.25);
`;

const QuizContainer = styled.div<{ isMobile?: boolean | null }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${(props) => (props.isMobile ? "1rem" : "3rem")};
  max-width: ${(props) => (props.isMobile ? "90%" : "55rem")};
  box-sizing: border-box;
  gap: 1rem;
`;

const PlayerStatsContainer = styled(QuizContainer) <{
  isMobile?: boolean | null;
}>`
// padding: ${(props) => (props.isMobile ? "6px" : "auto")};
padding: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.isMobile ? "" : "space-around")};
  width: 100%;
`;

const QuizQuestion = styled.span`
  line-height: 1.4;
  color: #333;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: block;
  max-width: 100%;
  box-sizing: border-box;
`;

const HeaderContainer = styled.div<{ isMobile?: boolean | null }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;
`;

const DayContainer = styled.div<{ isMobile?: boolean | null }>`
  flex-grow: 1;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  order: ${(props) => (props.isMobile ? "2" : "0")};
`;

const TimeContainer = styled.div<{ isMobile?: boolean | null }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OptionsContainer = styled.div<{
  showOptions: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const NavigationContainer = styled.div<{ isMobile?: boolean | null }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 52rem;
  margin: 0 auto;
  padding: ${(props) => (props.isMobile ? "0 1rem" : "0 2rem")};
  box-sizing: border-box;

  ${({ isMobile }) =>
    isMobile &&
    `
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
  `}
`;

// Texts

const HeaderMainTitle = styled(SpaceGrotesk) <{ isMobile?: boolean | null }>`
  color: var(--Brand-Primary-300, #225c90);
  leading-trim: both;
  text-edge: cap;
  font-size: ${(props) => (props.isMobile ? "36px" : "48px")};
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.15rem;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  text-align: start;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const HeaderTitle = styled(SpaceGrotesk) <{ isMobile?: boolean | null }>`
  color: var(--Brand-Primary-300, #225c90);
  leading-trim: both;
  text-edge: cap;
  font-size: ${(props) => (props.isMobile ? "26px" : "36px")};
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.15rem;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  text-align: start;
`;

const HeaderSubtitle = styled(Manrope)`
  color: var(--Brand-Primary-400, #0b3962);
  margin: 0;

  /* Subtitle2 */
  font-size: var(--Subtitle2-size, 20px);
  font-style: normal;
  font-weight: var(--Subtitle2-weight, 600);
  line-height: var(--Subtitle2-lineHeight, 32px); /* 160% */
  letter-spacing: var(--Subtitle2-spacing, 0px);
`;

const Description = styled(Manrope)`
  color: #282828;
  text-edge: cap;
  margin: 0;
  /* Body 1 (16px) */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const DescriptionBold = styled(Manrope)`
  color: #282828;
  text-edge: cap;
  margin: 0;
  /* Body 1 (16px) */
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const QuestionText = styled(Manrope) <{ isMobile?: boolean | null }>`
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  font-size: ${(props) => (props.isMobile ? "1rem" : "1.1rem")};
  font-style: normal;
  font-weight: 400;
  line-height: ${(props) => (props.isMobile ? "1.5" : "normal")};
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
  box-sizing: border-box;
  text-align: left;
`;

const OptionText = styled.div<{ isMobile?: boolean | null }>`
  text-align: left;
  leading-trim: both;
  text-edge: cap;

  font-size: ${(props) => (props.isMobile ? "0.75rem" : "0.875rem")};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px;
  margin: 0px;
  color: inherit;
  width: 100%;
`;

const OptionButton = styled.button<{
  isSelected: boolean;
  optionColor?: string;
  optionSelectedColor?: string;
  border?: string;
}>`
  display: flex;
  padding: 15px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border: none;
  outline: none;
  width: 100%;

  border-radius: 0.3125rem;
  //border: 1px solid var(--Black, #282828);
  //outline: 2px solid transparent;
  background-color: #ffffff; /* Initial white background */
  color: #0b3962; /* Initial darker blue text color */
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    // outline: 2px solid ${(props) => props.border ?? "#0B3962"};
    background-color: #052d55; /* Darker blue background on hover */
    color: #ffffff; /* White text on hover */
    //border: 1px solid transparent;
    cursor: pointer;
  }

  ${(props) =>
    props.isSelected &&
    `
    // outline: 2px solid #282828;
    // border: 1px solid transparent;
    background-color: ${props.optionSelectedColor ?? "#0B3962"};
    color: #F9F9F9; /* Ensure selected state has white text */
    &:hover {
      // outline: 2px solid #282828;
      background-color: ${props.optionSelectedColor ?? "#0B3962"};
      color: #F9F9F9;
      // border: 1px solid transparent;
      cursor: pointer;
    }
  `}
`;

const QuestionCounter = styled(Manrope) <{ isMobile?: boolean | null }>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;

  /* Body 2 (14px) */
  font-size: 0.875rem;
  font-style: normal;
  margin-right: ${(props) => (props.isMobile ? "0px" : "30px")};
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
  padding: 0;
`;

const ButtonSecondary = styled(StyledButton) <{ isMobile?: boolean | null }>`
  color: #225c90;
  background: transparent;
  border: 1px solid #225c90;
  padding: 0 20px;
  height: 50px;
  min-width: 120px; /* Ensure it's not too small */
  width: fit-content; /* Adjust width based on content */
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  display: inline-flex; /* Allow the button to shrink to fit its content */
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  gap: 1rem;

  &:hover {
    color: white;
    background: #225c90;
    border-color: #225c90;
    cursor: pointer;
  }
`;

// Energy Bar + Project Completion Bar

const Popover = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(8px);
  background: white;
  border: 1px solid #0daef1;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 4px 16px 1px rgba(11, 57, 98, 0.25);
  z-index: 3;
`;

const PopoverSide = styled(Popover)`
  top: 0;
  left: 100%; /* Positions it fully to the right of the trigger element */
  margin-left: 8px; /* Adds spacing between the popover and the header */
  transform: none; /* No transform for static positioning */
  width: 300px;
`;

const PopoverTitle = styled.h3`
  margin: 0 0 4px;
  font-size: 14px;
`;

const PopoverContent = styled.p`
  margin: 0;
  font-size: 12px;
`;

const EnergyBarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  display: flex;
`;

const EnergyIconsContainer = styled.div`
  display: flex;
  gap: 2px;
`;

const CompletionBarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CompletionContent = styled.div<{ mobile: boolean | null }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: ${props => props.mobile ? '220px' : '300px'};
`;

const ProgressBar = styled.div<{ colour: string }>`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 3px;
  background: ${({ colour }) => colour};
  border-radius: 4px;
  height: 40px;
`;

const ProgressLevel = styled.div<{ colour: string; level: number }>`
  background: ${({ colour }) => colour};
  width: ${({ level }) => `${level}%`};
`;

const Step = styled.div<{ isFilled: boolean }>`
  flex: 1;
  background-color: ${({ isFilled }) => (isFilled ? "#FFCDF7" : "#225C90")};
  transition: background-color 3s ease;
  border-radius: 2px;
`;

const ProgressLabel = styled(DescriptionBold)`
  text-align: center;
`;

// Animations

const fadeIn = keyframes`
  from {
    opacity: 0;
    
    transform: translateX(-10%);
  }
  
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInOpp = keyframes`
  from {
    opacity: 0;
    transform: translateX(10%);
  }
  
  to {
    opacity: 1;
    transform: translateX(0);
  }

`;

const fadeInOnly = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeInComponent = styled.div<{ shouldFadeInOpp?: boolean }>`
  animation: ${(props) => (props.shouldFadeInOpp ? fadeInOpp : fadeIn)} 1s
    ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 5;
`;

const FadeInOnlyComponent = styled.div<{ shouldFadeInOpp?: boolean }>`
  animation: ${(props) => (props.shouldFadeInOpp ? fadeInOnly : fadeIn)} 1s
    ease-in-out; /* Applies the new fade-in animation */
  display: flex;
  flex-direction: column;
  transition: opacity 1s ease-in-out; /* Ensures fade-out is also smooth */
`;

export {
  MainContainer,
  StyledDashboard,
  QuizContainer,
  LightBlueQuizContainer,
  PlayerStatsContainer,
  HeaderContainer,
  DayContainer,
  TimeContainer,
  HeaderMainTitle,
  HeaderTitle,
  QuestionText,
  OptionsContainer,
  OptionButton,
  NavigationContainer,
  OptionText,
  QuestionCounter,
  Description,
  DescriptionBold,
  HeaderSubtitle,
  FadeInComponent,
  QuizQuestion,
  ButtonSecondary,
  // Energy bar + ProjectCompletion
  Popover,
  PopoverTitle,
  PopoverContent,
  PopoverSide,
  EnergyBarWrapper,
  Label,
  EnergyIconsContainer,
  CompletionBarContainer,
  CompletionContent,
  ProgressBar,
  ProgressLevel,
  Step,
  ProgressLabel,

  // Animations
  FadeInOnlyComponent,
  fadeInOnly, StyledButton,
};
