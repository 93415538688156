import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    ApplicationState,
    RSVPState,
    applicationSlice,
    rsvpSlice,
} from "./slices";

type DataState = {
    authToken: string;
    isAuthenticated: boolean;
    userFName: string;
    userLName: string;
    userID: number;
    jwtExp: number;
    refreshToken: string;
    isAccepted: boolean | null;
    isWaitlisted: boolean | null;
    isRejected: boolean | null;
    isRound2: boolean | null;
    didRSVP: boolean | null;
};

type RootState = {
    data: DataState;
    application: ApplicationState;
    rsvp: RSVPState;
};

const initialState: DataState = {
    authToken: "",
    isAuthenticated: false,
    userFName: "",
    userLName: "",
    userID: 0,
    jwtExp: 0,
    refreshToken: "",
    isAccepted: null,
    isWaitlisted: null,
    isRejected: null,
    isRound2: null,
    didRSVP: null,
};

const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        setAuthTokenRedux(state, action) {
            state.authToken = action.payload;
        },
        setIsAuthenticatedRedux(state, action) {
            state.isAuthenticated = action.payload;
        },
        setUserFName(state, action) {
            state.userFName = action.payload;
        },
        setUserLName(state, action) {
            state.userLName = action.payload;
        },
        setUserID(state, action) {
            state.userID = action.payload;
        },
        setJWTExp(state, action) {
            state.jwtExp = action.payload;
        },
        setRefreshToken(state, action) {
            state.refreshToken = action.payload;
        },
        setIsAccepted(state, action) {
            state.isAccepted = action.payload;
        },
        setIsWaitlisted(state, action) {
            state.isWaitlisted = action.payload;
        },
        setIsRejected(state, action) {
            state.isRejected = action.payload;
        },
        setDidRSVP(state, action) {
            state.didRSVP = action.payload;
        },
        setIsRound2(state, action) {
            state.isRound2 = action.payload;
        },
        resetData(state) {
            Object.assign(state, initialState);
        },
    },
});

const rootReducer = combineReducers({
    data: dataSlice.reducer,
    application: applicationSlice.reducer,
    rsvp: rsvpSlice.reducer,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export const {
    setAuthTokenRedux,
    setIsAuthenticatedRedux,
    setUserID,
    setUserFName,
    setUserLName,
    setJWTExp,
    setRefreshToken,
    setIsAccepted,
    setIsWaitlisted,
    setIsRejected,
    setIsRound2,
    setDidRSVP,
    resetData,
} = dataSlice.actions;

export const {
    setAppGender,
    setAppPage,
    setAppPronouns,
    setAppFirstName,
    setAppLastName,
    setAppEmail,
    setAppPreferredName,
    setAppCountry,
    setAppCity,
    setAppAge,
    setAppEthnicity,
    setAppDiscord,
    setAppSchool,
    setAppMajor,
    setAppYear,
    setAppResume,
    setAppResumeSubmitted,
    setAppGithub,
    setAppLinkedIn,
    setAppOtherLinks,
    setAppStarted,
    setAppCompleted,
    setAppTCInPerson,
    setAppTCMLH,
    setAppTCSubscribe,
    setAppHackathonAttended,
    setAppNumberHackathon,
    setAppParentEmail,
    setAppElevatorPitch,
    setAppUofTHacksExp,
    setAppLearningProcess,
    setAppOptional,
    setAppUofTHacksSuggestions,
    setQuizQ1,
    setQuizQ2,
    setQuizQ3,
    setQuizQ4,
    setQuizQ5,
    setQuizQ6,
    setCharacterName,
    setReduxCharacterName,
    resetApplication,
} = applicationSlice.actions;

export const {
    setAttending,
    setContactName,
    setShippingAddress,
    setHackerPhone,
    setContactPhone,
    setContactRelation,
    setDietryRestrictions,
    setMerchSize,
    setDemographic,
    resetRSVP,
} = rsvpSlice.actions;

export type { RootState };
