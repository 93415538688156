import React from "react";
import { DescriptionBold } from "./index.styles";
import styled from "styled-components";

interface ProgressBarProps {
  progressColor?: string;
  progress?: number;
  height?: string;
}
const ProgressBarContainer = styled.div`
  flex-grow: 1;
  position: relative;
  background: #fff;
  border-radius: 100px;
  border: 1.5px solid #0daef1;
  height: 20px;
  padding: 2px;
  display: flex;
`;

const Progress = styled.div<{ progress: number; progressColor?: string }>`
  background-image: linear-gradient(
      to right,
      #bae8fc 0%,
      #0daef1 35%,
      #225c90 65%,
      #0b3962 92%
  );
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 100%;
  width: ${(props) => `${props.progress}%`};
  }
`;

const OuterCircle = styled.span<{ progress: number }>`
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: #fff;
  border: 1.5px solid #0daef1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  top: 50%; /* Vertically centered */
  left: ${(props) => `${props.progress}%`}; /* Horizontally based on progress */
  z-index: 3;
`;

const InnerCircle = styled.span`
  background: #0daef1;
  position: relative;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
`;

const ProgressLabel = styled.label`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 4px;
`;

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progressColor = "",
  progress = 0,
}) => {

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ProgressBarContainer>
        <Progress progress={progress} progressColor={progressColor} />
        <OuterCircle progress={progress}>
          <InnerCircle>
            <SunIcon fill="#fff" stroke="#fff" />
          </InnerCircle>
        </OuterCircle>
      </ProgressBarContainer>
      <ProgressLabel>
        <DescriptionBold>{`${progress}%`}</DescriptionBold>
      </ProgressLabel>
    </div>
  );
};

export default ProgressBar;

// Sun Icon
interface SunIconProps {
  fill?: string;
  stroke?: string;
}

const SunIcon: React.FC<SunIconProps> = ({
  fill = "#fff",
  stroke = "#fff",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill={fill}
    >
      <g clip-path="url(#clip0_80_390)">
        <path
          d="M9.5 14.25C12.1234 14.25 14.25 12.1234 14.25 9.5C14.25 6.87665 12.1234 4.75 9.5 4.75C6.87665 4.75 4.75 6.87665 4.75 9.5C4.75 12.1234 6.87665 14.25 9.5 14.25Z"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.417 9.5L18.2087 9.5"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.5 1.5835V0.791829"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.5 18.2085V17.4168"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.833 15.8335L15.0413 15.0418"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.833 3.1665L15.0413 3.95817"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.16699 15.8335L3.95866 15.0418"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.16699 3.1665L3.95866 3.95817"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.791992 9.5L1.58366 9.5"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_80_390">
          <rect width="19" height="19" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
