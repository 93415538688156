export const LOCAL_BACKEND_SERVER = false;

export const PERSONALITY_QUIZ_RELEASE = false;

export const CLOSED_TESTING_REGISTER = false;

export const CHAT_BOT_RELEASE = false;

export const PHASE_ONE_RELEASE = true;

export const APPLICATIONS_CLOSED = true;

export const TEAM_FINDER_RELEASE = true;

export const MENTOR_MATCHING_RELEASE = false;

export const HACKLIFE_RELEASE = true;

export const RSVP_CLOSED = true;

export const SCHEDULE_RELEASE = true;

export const DISCORD_RELEASE = true;

export const HACKER_PACK_RELEASE = true;