import Drawer from "../dashboard/drawer";
import React, { useEffect, useState } from "react";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
    StyledSpaceGrotesk,
    StyledManrope,
    ButtonContainer,
    DashboardHeaderContainer,
    ContentWrapper,
    ButtonStyle, HelperHeaderText,
} from "./index.styles";
import HelperPrompt from "../../components/team-finder/helper-prompt";
import TeamCollection from "../../components/team-finder/team-collection";
import CreateTeamModal from "../../components/create-team-modal";
import PinInputModal from "../../components/pin-input-modal";
import { StyledButton } from "../../components/dashboard/content/index.styles";
import { useGetMyTeam } from "../../hooks/useTeamFinder";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import {
    TeamHeader,
    TeamIconContainer,
    TeamItem, TeamMembers,
    TeamNameLink
} from "../../components/team-finder/team-collection/index.styles";
import LoadingComponent from "../../components/loading-component";

const TeamFinderComponent = () => {
    const navigate = useNavigate();
    const isMobile = useMobileDetect();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [activeDialog, setActiveDialog] = React.useState<"pinInput" | "createTeam" | null>(null);
    const [showHelp, setShowHelp] = useState(false);

    const { authToken } = useSelector((state: RootState) => state.data);
    const { data: myTeamData, isLoading: isMyTeamLoading } = useGetMyTeam(authToken);

    useEffect(() => {
        setDrawerOpen(!isMobile);
    }, [isMobile]);

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    if (isMyTeamLoading) {
        return <LoadingComponent isOpen={true} />
    }

    return (
        <>
            <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} contentType={"profile"} />
            <StyledButton onClick={toggleDrawer} isDrawerOpen={isDrawerOpen}>
                ☰
            </StyledButton>
            <ContentWrapper isDrawerOpen={isDrawerOpen} mobile={isMobile}>
                <ButtonContainer style={{ marginTop: 0 }} mobile={isMobile}>
                    <DashboardHeaderContainer mobile={isMobile}>
                        <StyledSpaceGrotesk mobile={isMobile}>Team Builder</StyledSpaceGrotesk>
                        <StyledManrope>
                            Welcome to Team Builder! This tool let's you find other hackers who are also looking for a team, and manage your team throughout the hackathon.
                        </StyledManrope>
                        <StyledManrope>
                            To get started, either create a new team, or join an existing team from the list of public teams below. To join a private team, you'll need to enter the pin of the team using the "Input Pin" button.
                        </StyledManrope>
                    </DashboardHeaderContainer>
                </ButtonContainer>

                {myTeamData?.team_id ? (
                    <div style={{ marginTop: "20px", height: '100vh', cursor: "pointer" }}>
                        <HelperHeaderText mobile={isMobile}>
                            Nice! You are registered to a team.
                        </HelperHeaderText>
                        <StyledManrope style={{ marginBottom: "20px" }}>
                            Click the card below to view more information about your team or make any modifications.
                        </StyledManrope>
                        <TeamItem
                            onClick={() => navigate(`/team-info/${myTeamData.team_id}`)}
                            isMobile={isMobile}
                        >
                            <TeamHeader isMobile={isMobile}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: isMobile ? "column" : "row",
                                        gap: "8px",
                                    }}
                                >
                                    <TeamIconContainer>
                                        <img src="/assets/icons/team.svg" alt="Team Icon" />
                                    </TeamIconContainer>
                                    <div>
                                        <TeamNameLink>{myTeamData.team_name}</TeamNameLink>
                                        <TeamMembers>{myTeamData.team_motto}</TeamMembers>
                                    </div>
                                </div>
                            </TeamHeader>
                        </TeamItem>
                    </div>
                ) : (
                    <>
                        <HelperPrompt isVisible={showHelp} setHelper={setShowHelp} />

                        <ButtonContainer mobile={isMobile}>
                            <ButtonStyle
                                color="#BAE8FC"
                                title="Create Team"
                                onClick={() => {
                                    setActiveDialog("createTeam");
                                }}
                            >
                                <img
                                    src="/assets/icons/create-team.svg"
                                    alt="Create Team Icon"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        verticalAlign: "middle",
                                    }}
                                />
                                Create Team
                            </ButtonStyle>
                            <ButtonContainer mobile={isMobile}>
                                <ButtonStyle
                                    color="#B6FFF6"
                                    onClick={() => {
                                        setActiveDialog("pinInput");
                                    }}
                                >
                                    <img
                                        src="/assets/icons/search-team-pin.svg"
                                        alt="Search Team Pin Icon"
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            verticalAlign: "middle",
                                        }}
                                    />
                                    Input Pin
                                </ButtonStyle>
                                <ButtonStyle
                                    color="#FFCDF7"
                                    onClick={() => {
                                        setShowHelp(!showHelp);
                                    }}
                                >
                                    <b>?</b>
                                </ButtonStyle>
                            </ButtonContainer>
                        </ButtonContainer>

                        <TeamCollection />
                        <PinInputModal
                            isOpen={activeDialog === "pinInput"}
                            title="Find Team Member By Pin"
                            onClose={() => setActiveDialog(null)}
                        />
                        <CreateTeamModal
                            isOpen={activeDialog === "createTeam"}
                            title="Create Team"
                            onClose={() => setActiveDialog(null)}
                        />
                    </>
                )}
            </ContentWrapper>
        </>
    );
};

export default TeamFinderComponent;
