import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import {
    PassportPage,
    PassportHeading,
    PassportText,
    InfoWidget,
} from "./index.styles";

const ProfPage2 = React.memo(
    ({
         show,
         emgPhoneNum,
         dietCondition,
         hackerBucks,
         name,
         pronoun,
         program,
         age,
         school,
         currentYear,
     }: {
        show: boolean;
        emgPhoneNum: string;
        dietCondition: Array<string>;
        hackerBucks: number;
        age: string;
        name: string;
        pronoun: string;
        school: string;
        program: string;
        currentYear: string;
    }) => {
        const {} = useSelector((state: RootState) => state.application);
        const dispatch = useDispatch();

        const isMobile = useMobileDetect();

        const year_str_map: { [key: string]: string } = {
            "Year 1 (Freshman)": "1",
            "Year 2 (Sophomore)": "2",
            "Year 3 (Junior)": "3",
            "Year 4 (Senior)": "4",
            "Year 5+": "5+",
            "Graduate Student": "Grad",
        };

        const year_str = year_str_map[currentYear.replaceAll("'", "")] || currentYear;

        return (
            <PassportPage id="page-2" show={show}>
                <PassportText size="48px" mobile={isMobile}>
                    {name}
                </PassportText>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "8px",
                        marginBottom: "6px",
                        marginTop: "40px",
                    }}
                >
                    <div>
                        <PassportHeading mobile={isMobile}>PRONOUNS</PassportHeading>
                        <PassportText mobile={isMobile}>{pronoun}</PassportText>
                    </div>
                    <div>
                        <PassportHeading mobile={isMobile}>AGE</PassportHeading>
                        <PassportText mobile={isMobile}>{age}</PassportText>
                    </div>
                    <div>
                        <PassportHeading mobile={isMobile}>YEAR</PassportHeading>
                        <PassportText mobile={isMobile}>{year_str}</PassportText>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "16px",
                        marginBottom: "8px",
                        position: "relative",
                    }}
                >
                    <div style={{ flex: "1" }}>
                        <InfoWidget>
                            <PassportHeading mobile={isMobile}>
                                <img
                                    src="/assets/profile/graduation-cap.svg"
                                    alt="Icon"
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                        marginRight: "8px",
                                        verticalAlign: "middle",
                                    }}
                                />
                                SCHOOL
                            </PassportHeading>
                            <PassportText mobile={isMobile}>{school}</PassportText>
                        </InfoWidget>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "16px",
                        marginBottom: "8px",
                    }}
                >
                    <div style={{ flex: "1" }}>
                        <InfoWidget>
                            <PassportHeading mobile={isMobile}>
                                <img
                                    src="/assets/profile/bookmark-book.svg"
                                    alt="Icon"
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                        marginRight: "8px",
                                        verticalAlign: "middle",
                                    }}
                                />
                                PROGRAM
                            </PassportHeading>
                            <PassportText mobile={isMobile}>{program}</PassportText>
                        </InfoWidget>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "16px",
                        marginBottom: "8px",
                    }}
                >
                    <div style={{ flex: "1" }}>
                        <InfoWidget>
                            <PassportHeading mobile={isMobile}>
                                <img
                                    src="/assets/profile/apple-half-alt.svg"
                                    alt="Icon"
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                        marginRight: "8px",
                                        verticalAlign: "middle",
                                    }}
                                />
                                DIETARY RESTRICTIONS
                            </PassportHeading>
                            <PassportText mobile={isMobile}>
                                {dietCondition.length > 0 ? dietCondition.join(", ") : "None"}
                            </PassportText>
                        </InfoWidget>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "16px",
                    }}
                >
                    <div style={{ flex: "1" }}>
                        <InfoWidget>
                            <PassportHeading mobile={isMobile}>
                                HACKER BUCKS
                            </PassportHeading>
                            <PassportText mobile={isMobile}>{hackerBucks} Bucks</PassportText>
                            <PassportText mobile={isMobile} style={{ marginTop: "8px", fontSize: "14px", color: "#555" }}>
                                Visit the merch shop to redeem your bucks for goodies and earn more by attending events!
                            </PassportText>
                        </InfoWidget>
                    </div>
                </div>
            </PassportPage>
        );
    }
);

export default ProfPage2;
