import styled from "styled-components";

export const GrayButton = styled.button`
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    width: auto;
    align-self: flex-start; 

    &:hover {
        background-color: #e8e8e8;
    }
`;

export const RadioButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 10px;
`;

export const RadioButton = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;

  input {
    margin: 0;
  }
`;

export const SubContainer = styled.div`
    border: 1.5px solid var(--Brand-Primary-200, #0DAEF1);
    background: radial-gradient(
            69.75% 40.92% at 11.7% 11.66%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 205, 247, 0.2) 50%
    ),
    linear-gradient(
            198deg,
            rgba(186, 232, 252, 0.6) 33.38%,
            rgba(254, 254, 255, 0.6) 84.04%
    );
  margin-top: 16px;
  padding: 20px;
  border-radius: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
`;

export const SaveButton = styled.button`
  background-color: #225c90;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #1b4a74;
  }
`;

export const CancelButton = styled(SaveButton)`
  background-color: transparent;
  color: #225c90;
  border: 1px solid #225c90;

  &:hover {
    background-color: #f5f5f5;
  }
`;


export const ToggleContainer = styled.div`
    border: 1.5px solid var(--Brand-Primary-200, #0DAEF1);
    background: radial-gradient(
            69.75% 40.92% at 11.7% 11.66%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 205, 247, 0.2) 50%
    ),
    linear-gradient(
            198deg,
            rgba(186, 232, 252, 0.6) 33.38%,
            rgba(254, 254, 255, 0.6) 84.04%
    );
  display: flex;
  align-items: center;
  justify-content: space-between;
    margin-top: 16px;
    padding: 20px;
    border-radius: 8px;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 20px;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
    }
  }

  input:checked + span {
    background-color: #225C90;
  }

  input:checked + span:before {
    transform: translateX(20px);
  }
`;