import Drawer from "../dashboard/drawer";
import React, { useEffect, useState } from "react";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
  StyledSpaceGrotesk,
  StyledManrope,
  ButtonContainer,
  DashboardHeaderContainer,
  ContentWrapper,
  ButtonStyle,
  ButtonText
} from "./index.styles";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../../components/dashboard/content/index.styles";

const TeamFinderInfoComponent = () => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const goBack = () => {
    navigate(-1);
  };



  return (
    <>
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} contentType={"profile"} />
      <StyledButton onClick={toggleDrawer} isDrawerOpen={isDrawerOpen}>
        ☰
      </StyledButton>
      <ContentWrapper isDrawerOpen={isDrawerOpen} mobile={isMobile}>
        <ButtonContainer style={{ marginTop: 0 }} mobile={isMobile}>
          <DashboardHeaderContainer mobile={isMobile}>
            <StyledSpaceGrotesk mobile={isMobile}>
              Information
            </StyledSpaceGrotesk>
            <StyledManrope>How The Team Builder Works</StyledManrope>
          </DashboardHeaderContainer>
          <ButtonStyle onClick={goBack}>
            <ButtonText>Back</ButtonText>
          </ButtonStyle>
        </ButtonContainer>
        On the team builder page, you'll see a list of public teams that you can request to join. You can request to join as many teams as you want, and then wait to be approved by one.
      </ContentWrapper>
    </>
  );
};

export default TeamFinderInfoComponent;
