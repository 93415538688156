import styled from "styled-components";

export const TeamCollectionContainer = styled.div<{ isMobile?: boolean | null }>`
    border: 1.5px solid var(--Brand-Primary-200, #0DAEF1);
    background: radial-gradient(
            69.75% 40.92% at 11.7% 11.66%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 205, 247, 0.2) 50%
    ),
    linear-gradient(
            198deg,
            rgba(186, 232, 252, 0.6) 33.38%,
            rgba(254, 254, 255, 0.6) 84.04%
    );
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "20px" : "50px")};
    margin: ${({ isMobile }) => (isMobile ? "10px 0" : "20px 0")};
`;

export const CancelButton = styled.button<{ isMobile?: boolean | null }>`
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "6px 12px" : "8px 16px")};
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e04343;
    }
`;

export const JoinButton = styled.button<{ isMobile?: boolean | null }>`
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "6px 12px" : "8px 16px")};
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #45a049;
    }

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`;

export const DisabledJoinButton = styled.button<{ isMobile?: boolean | null }>`
    background-color: #f5f5f5; 
    color: #888888;
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "6px 12px" : "8px 16px")};
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
    border: 1px solid #dddddd;
    cursor: not-allowed;
    text-align: center;
`;

export const TeamNameLink = styled.span<{ isMobile?: boolean | null }>`
    font-weight: bold;
    font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
    color: #0b3962;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const TeamList = styled.div<{ isMobile?: boolean | null }>`
    margin-bottom: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
`;

export const TeamItem = styled.div<{ isMobile?: boolean | null }>`
    border: 1.5px solid var(--Brand-Primary-200, #0DAEF1);
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "10px" : "12px")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "10px" : "12px")};
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
`;

export const TeamHeader = styled.div<{ isMobile?: boolean | null }>`
    display: flex;
    align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
    justify-content: space-between;
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

export const TeamIconContainer = styled.div<{ isMobile?: boolean }>`
    margin-right: ${({ isMobile }) => (isMobile ? "8px" : "12px")};
    img {
        width: ${({ isMobile }) => (isMobile ? "30px" : "40px")};
        height: ${({ isMobile }) => (isMobile ? "30px" : "40px")};
    }
`;

export const DescriptionHeader = styled.span<{ isMobile?: boolean }>`
    margin-top: ${({ isMobile }) => (isMobile ? "8px" : "10px")};
    font-weight: bold;
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
    color: #0b3962;
`;

export const TeamMembers = styled.div<{ isMobile?: boolean | null }>`
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
    color: #333;
`;

export const TeamDescription = styled.div<{ isMobile?: boolean | null }>`
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
    color: #555;
    margin-top: ${({ isMobile }) => (isMobile ? "8px" : "12px")};
`;

export const RequestButton = styled.button<{ isMobile?: boolean | null }>`
    background-color: #225c90;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: ${({ isMobile }) => (isMobile ? "6px 20px" : "8px 30px")};
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
    cursor: pointer;

    &:hover {
        background-color: #0b9ed2;
    }
`;

export const RequestedButton = styled(RequestButton)`
    background-color: #d3d3d3;
    color: #6d6d6d;
    cursor: not-allowed;
`;
