import styled from "styled-components";
import SpaceGrotesk from "../../components/space-grotesk";
import Inter from "../../components/inter";
import Manrope from "../../components/manrope";

type HomePageContentProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
  newHeader?: boolean | null;
};

type ButtonProps = {
  color?: string;
  disabled?: boolean;
};

type ContentWrapperProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
  profile?: boolean | null;
};

const DashboardHeaderContainer = styled.div<HomePageContentProps>`
  border-radius: 10px;
  color: var(--White, #225c90);
  padding: 3.125rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  ${({ mobile }) =>
    mobile &&
    `
    max-width: 70vw;
  `}
`;

const PublicTeamsSection = styled.div<HomePageContentProps>``;

const ContentWrapper = styled.div<ContentWrapperProps>`
  margin-left: ${({ isDrawerOpen }) => (isDrawerOpen ? "250px" : "0")};
  transition: margin-left 0.3s ease-in-out;
  background: linear-gradient(
    to bottom,
    rgba(13, 174, 241, 0.1),
    rgba(255, 205, 247, 0.1)
  );
  padding: ${({ mobile }) =>
    mobile
      ? "3.75rem 1.25rem"
      : "3.75rem"};
  ${({ mobile, isDrawerOpen }) =>
    isDrawerOpen &&
    mobile &&
    `
    overflow: hidden;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
    margin-left: 0;
    `}
`;

const StyledSpaceGrotesk = styled(SpaceGrotesk) <HomePageContentProps>`
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "40px" : "60px")};
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  color: #225c90;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HelperHeaderText = styled(SpaceGrotesk) <HomePageContentProps>`
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "30px" : "40px")};
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  color: #225c90;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledManrope = styled(Manrope) <HomePageContentProps>`
  leading-trim: both;
  text-edge: cap;
  /* Body 1 (16px) */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #225c90;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const ButtonContainer = styled.div<HomePageContentProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
`;

const ButtonStyle = styled.div<ButtonProps>`
  background-color: ${({ color }) => color};
  padding: 1rem 1.75rem;
  color: #0B3962;
  border-radius: 10px;
  border: 1px solid #0DAEF1;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #ECECEC;
    border: 1px solid #0b96d4;
    cursor: not-allowed;
    opacity: 0.7;
  `}

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  gap: 1.25rem;
  align-items: center;
  align-content: center;
  align-self: center;
  outline: 1px solid transparent;
  transition: 0.2s ease-in-out;
  ${({ disabled }) =>
    !disabled &&
    `
  &:hover {
    outline: 1px solid var(--Black, #0b96d4);
  `}
}
`;

const ButtonText = styled(Inter) <ButtonProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const PublicTeamsTable = styled.table`
  width: 100%;
  border-collapse: separate;
  margin-top: 20px;
`;

const PublicTeamsHeader = styled.th`
  background-color: #e9f7ff; 
  color: #007acc; 
  font-weight: 600;
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #d1eaff;
  border-radius: 8px 8px 0 0; 
`;

const PublicTeamsRow = styled.tr`
  cursor: pointer;
  background-color: #ffffff; 
  transition: background-color 0.3s ease; 
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  
  &:hover {
    background-color: #f0f8ff; 
  }
`;

const PublicTeamsCell = styled.td`
  padding: 14px 12px; 
  border: none; 
  color: #444444;
  font-size: 14px;
  vertical-align: middle; 
`;

export {
  StyledSpaceGrotesk,
  StyledManrope,
  ButtonContainer,
  DashboardHeaderContainer,
  ContentWrapper,
  ButtonStyle,
  ButtonText,
  HelperHeaderText,
  PublicTeamsSection,
  PublicTeamsTable,
  PublicTeamsHeader,
  PublicTeamsRow,
  PublicTeamsCell,
};
