import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import { useSearchTeamByPin } from "../../hooks/useTeamFinder";
import Button from "../button";
import Modal from "../modal";
import {
    CloseButton,
    DialogBoxBody,
    DialogBoxHeader,
    DialogBoxTitle,
    DialogBoxWrapper,
    ButtonContainer,
} from "./index.styles";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { useToaster } from "../../providers/ToasterProvider";

type PinInputModalProps = {
    isOpen: boolean;
    title: string;
    button1Text?: string;
    button2Text?: string;
    button1Colour?: string;
    button2Colour?: string;
    onClose: () => void;
};

const PinInputModal = React.memo(
    ({
         isOpen,
         title,
         button1Text = "Find Team",
         button2Text = "Cancel",
         button1Colour = "blue",
         button2Colour = "blue",
         onClose,
     }: PinInputModalProps) => {
        const isMobile = useMobileDetect();
        const navigate = useNavigate();
        const { authToken } = useSelector((state: RootState) => state.data);
        const { setToaster } = useToaster();

        const [teamPin, setTeamPin] = useState("");
        const [searchTriggered, setSearchTriggered] = useState(false);

        const { data: teamData, isLoading, isError, error } = useSearchTeamByPin(
            searchTriggered ? teamPin : "",
            authToken
        );

        const handleFindTeam = () => {
            if (!teamPin) {
                setToaster("Please enter a valid team pin.", "info");
                return;
            }
            setSearchTriggered(true);
        };

        const handleNavigation = () => {
            if (teamData) {
                navigate(`/team-info/${teamData.team_id}`);
                onClose();
            }
        };

        React.useEffect(() => {
            if (teamData) {
                handleNavigation();
            }
            if (isError) {
                setToaster(error?.message || "Failed to find team. Please try again.", "error");
                setSearchTriggered(false);
            }
        }, [teamData, isError, error]);

        return (
            <Modal isOpen={isOpen} closeModal={onClose}>
                <DialogBoxWrapper>
                    <DialogBoxHeader>
                        <DialogBoxTitle>{title}</DialogBoxTitle>
                        <CloseButton
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={onClose}
                        >
                            <path
                                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                fill="#282828"
                            />
                        </CloseButton>
                    </DialogBoxHeader>
                    <DialogBoxBody>
                        <div style={{ marginBottom: "10px" }}>
                            <label>
                                <strong>Team Pin:</strong>
                            </label>
                            <input
                                type="text"
                                value={teamPin}
                                onChange={(e) => setTeamPin(e.target.value)}
                                placeholder="Enter team pin"
                                style={{
                                    display: "block",
                                    width: "100%",
                                    padding: "8px",
                                    marginTop: "5px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                }}
                            />
                        </div>
                        {isLoading && <p>Searching for team...</p>}
                    </DialogBoxBody>

                    <ButtonContainer>
                        <Button
                            text={button2Text}
                            colour={button2Colour}
                            onClick={onClose}
                            filled={false}
                            style={
                                typeof isMobile !== "undefined" && isMobile
                                    ? { minWidth: "140px" }
                                    : {}
                            }
                        />
                        <Button
                            text={button1Text}
                            colour={button1Colour}
                            onClick={handleFindTeam}
                            disabled={isLoading}
                            style={
                                typeof isMobile !== "undefined" && isMobile
                                    ? { minWidth: "140px" }
                                    : {}
                            }
                        />
                    </ButtonContainer>
                </DialogBoxWrapper>
            </Modal>
        );
    }
);

export default PinInputModal;
