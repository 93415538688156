import styled, { keyframes } from "styled-components";
import Subjectivity from "../../subjectivity";
import Inter from "../../inter";
import InterSemiBold from "../../inter-semi-bold";

type HomePageContentProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
};

type SelectorProps = {
  selected?: boolean;
  mobile?: boolean | null;
};

type EventProps = {
  colour?: string;
  mobile?: boolean | null;
};

type EventInfoProps = {
  width?: string;
  align?: string;
  bold?: boolean | null;
  mobile?: boolean | null;
};

const StyledSubjectivity = styled(Subjectivity)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Subjectivity;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "36px" : "48px")};
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
`;

const StyledInterSemiBold = styled(InterSemiBold)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  text-align: left;
  font-family: InterSemiBold;
  font-size: ${(props) => (props.isMobile ? "16px" : "24px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const StyledInter = styled(Inter)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: ${(props) => (props.isMobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; 
  }
`;

const MainContainer = styled.div`
  animation: ${fadeIn} 0.7s ease-in-out;
`;

const DaySelector = styled.div<SelectorProps>`
  display: flex;
  color: ${({ selected }) => (selected ? "#F9F9F9" : "#555555")};
  min-width: ${({ mobile }) => (mobile ? "88px" : "120px")};
  ${({ mobile }) => (mobile ? "width: 88px;" : "")}
  padding: ${({ mobile }) => (mobile ? "10px 16px" : "20px 20px")};
  justify-content: center;
  align-items: center;
  gap: ${({ mobile }) => (mobile ? "12px" : "10px")};
  // border-radius: 5px;
  // border: 1px solid var(--Gray, #555);
  background: ${({ selected }) => (selected ? "#225C90" : "#BAE8FC")};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: ${({ mobile }) => (mobile ? "20px" : "24px")};
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    cursor: pointer;
  }
`

const Event = styled.div<EventProps>`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: ${({ mobile }) => (mobile ? "20px 20px" : "24px 40px")};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  background: ${({ colour }) => (colour ? colour: "#FFFFFF")};
  margin: 10px 0;
`

const EventHeader = styled(Event)`
  padding: 30px 40px;
  margin: 24px 0;
`

const EventInfo = styled(StyledInter)<EventInfoProps>`
  flex: ${({ mobile }) => (mobile ? "" : "1")};
  ${({ mobile }) => (mobile ? "font-size: 16px;" : "")}
  width: ${({ width, mobile }) => (mobile ? "" : width ? width: "250px")};
  text-align: ${({ align }) => (align ? align: "left")};
  ${({ bold }) => (bold 
    ? "font-family: InterSemiBold; font-weight: 600; width: 100%; margin-bottom: 12px;"
    : "")}
`

const EventHeaderInfo = styled(StyledInterSemiBold)<EventInfoProps>`
  flex: 1;
  width: ${({ width }) => (width ? width: "250px")};
  text-align: ${({ align }) => (align ? align: "left")};
  color: #FFFFFF;
  margin: 0;
`

const StyledLegendKey = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const ColourBox = styled.div<EventProps>`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--Black, #282828);
  background: ${({ colour }) => (colour ? colour: "#000000")};
`

export {
  StyledSubjectivity,
  StyledInter,
  StyledInterSemiBold,
  StyledImage,
  MainContainer,
  DaySelector,
  Event,
  EventInfo,
  EventHeader,
  EventHeaderInfo,
  StyledLegendKey,
  ColourBox,
};
