import React, { useEffect, useState } from "react";
import {
  StyledSubjectivity,
  StyledImage,
  MainContainer,
  DaySelector,
  Event,
  EventInfo,
  EventHeader,
  EventHeaderInfo,
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { useNavigate } from "react-router-dom";
import LegendKey from "./LegendKey";
import { SCHEDULE_RELEASE } from "../../../utils/featureFlags";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { API_BASE_URL } from "../../../api/apiConfig";

type EventType = {
  id: string;
  name: string;
  start_time: string;
  end_time?: string | null;
  room: string | null;
  day: number;
  type: string;
};

type LegendType = {
  [key: string]: {
    colour: string;
    event: string;
  };
};

type HomeProps = {
  isDrawerOpen?: boolean;
};

const legendKeys = ["general", "sponsor", "mini", "food"];

const legend: LegendType = {
  general: { colour: "#FFFFFF", event: "General Event" },
  sponsor: { colour: "#BAE8FC", event: "Sponsor Event" },
  mini: { colour: "#FFCDF7", event: "Mini Event" },
  food: { colour: "#B6FFF6", event: "Food Event" },
};

const Schedule: React.FC<HomeProps> = ({ isDrawerOpen }) => {
  const [day, setDay] = useState(1);
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const { authToken } = useSelector((state: RootState) => state.data);
  const [scheduleData, setScheduleData] = useState<EventType[][]>([[], [], []]);

  const parseEventType = (type: number): string => {
    switch (type) {
      case 0:
        return "general";
      case 1:
        return "sponsor";
      case 2:
        return "mini";
      case 3:
        return "food";
      default:
        return "none"; //these are admin shifts
    }
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/12/schedule/get`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ shift_type: 0 }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch schedule data");
        }

        const result = await response.json();

        if (Array.isArray(result.schedules)) {
          const parsedData = result.schedules.map((event: any) => ({
            id: String(event.schedule_id),
            name: event.schedule_name,
            start_time: new Date(event.schedule_start_time).toLocaleTimeString(
              [],
              {
                hour: "2-digit",
                minute: "2-digit",
              }
            ),
            end_time: event.schedule_end_time
              ? new Date(event.schedule_end_time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
              : null,
            room: event.event_room || "N/A",
            day: new Date(event.schedule_start_time).getDay(),
            type: parseEventType(event.event_type),
          }));

          const organizedData: EventType[][] = [[], [], []];
          parsedData.forEach((event: EventType) => {
            if (event.day === 5) organizedData[0].push(event); // fri
            if (event.day === 6) organizedData[1].push(event); // sat
            if (event.day === 0) organizedData[2].push(event); // sun
          });

          console.log("Organized Data:", organizedData);
          setScheduleData(organizedData);
        } else {
          console.error("Unexpected API format:", result);
        }
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    };

    fetchSchedule();
  }, [authToken]);

  const renderEvents = () => {
    const events = scheduleData[day - 1];
    return events
      .filter((event) => event.type !== "none") // exclude admin shifts
      .map((event) => (
        <Event
          key={event.id}
          colour={legend[event.type]?.colour || "#000"}
          mobile={isMobile}
        >
          <EventInfo
            width="250px"
            align="left"
            bold={isMobile}
            mobile={isMobile}
          >
            {event.name}
          </EventInfo>
          <EventInfo
            width="100px"
            align={isMobile ? "left" : "center"}
            mobile={isMobile}
          >
            {event.start_time}
            {event.end_time ? ` - ${event.end_time}` : ""}
          </EventInfo>
          <EventInfo width="200px" align="right" mobile={isMobile}>
            {event.room || "N/A"}
          </EventInfo>
        </Event>
      ));
  };

  const eventLegend = legendKeys.map((eventType) => (
    <LegendKey
      key={eventType}
      colour={legend[eventType]?.colour || "#000"}
      name={legend[eventType]?.event || "Unknown"}
    />
  ));

  return (
    <MainContainer>
      <div style={{ width: isMobile ? "100%" : "90%" }}>
        <StyledSubjectivity mobile={isMobile}>
          <StyledImage
            src="/assets/home-page/back-button.svg"
            onClick={() => navigate("/")}
          />
          Schedule
        </StyledSubjectivity>

        <div
          style={{
            display: "flex",
            gap: isMobile ? "16px" : "32px",
            justifyContent: isMobile ? "space-between" : "flex-start",
            marginBottom: "20px",
          }}
        >
          <DaySelector
            selected={day === 1}
            mobile={isMobile}
            onClick={() => setDay(1)}
          >
            Friday, January 17
          </DaySelector>
          <DaySelector
            selected={day === 2}
            mobile={isMobile}
            onClick={() => setDay(2)}
          >
            Saturday, January 18
          </DaySelector>
          <DaySelector
            selected={day === 3}
            mobile={isMobile}
            onClick={() => setDay(3)}
          >
            Sunday, January 19
          </DaySelector>
        </div>

        {!isMobile && (
          <EventHeader colour="#282828">
            <EventHeaderInfo width="250px" align="left">
              Event
            </EventHeaderInfo>
            <EventHeaderInfo width="100px" align="center">
              Time
            </EventHeaderInfo>
            <EventHeaderInfo width="200px" align="right">
              Room
            </EventHeaderInfo>
          </EventHeader>
        )}

        {scheduleData[day - 1].length === 0 ? (
          <p>No events scheduled for this day.</p>
        ) : (
          renderEvents()
        )}

        {!isMobile && (
          <div style={{ marginTop: "40px", display: "flex", gap: "48px" }}>
            {eventLegend}
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export { Schedule };
