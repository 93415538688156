import styled from "styled-components";

export const AcceptButton = styled.button`
  background-color: #225c90;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1b4a74;
  }
`;

export const DenyButton = styled(AcceptButton)`
  background-color: transparent;
  color: #225c90;
  border: 1.5px solid #225c90;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ViewProfileLink = styled.button`
  background: none;
  border: none;
  color: #225c90;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin-top: 8px;

  &:hover {
    color: #1b4a74;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 8px;
`;

export const RequestContent = styled.div`
  flex: 1;
`;

export const RequestActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;