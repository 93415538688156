import React, { useEffect, useState } from "react";
import Drawer from "../dashboard/drawer";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
    StyledSpaceGrotesk,
    StyledManrope,
    ButtonContainer,
    DashboardHeaderContainer,
    ContentWrapper,
    TeamInfoContainer,
    SectionHeaderText,
    ActionButton,
} from "./index.styles";
import DialogBox from "../../components/dialog-box";
import { useNavigate } from "react-router-dom";
import HackerInfo from "../../components/hacker-info";
import TeamInfoSection from "./team-info-section";
import BackButton from "../../components/button/back-button";
import { StyledButton } from "../../components/dashboard/content/index.styles";
import {
    useFetchTeamById,
    useCreateJoinRequest,
    useCancelJoinRequest,
    useGetMyRequests,
    useGetMyTeam,
    useDeleteTeam,
    useJoinTeam,
    useRemoveTeamMember
} from "../../hooks/useTeamFinder";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useToaster } from "../../providers/ToasterProvider";
import { useLeaveTeam } from "../../hooks/useTeamFinder";

const TeamInfoComponent = () => {
    const isMobile = useMobileDetect();
    const navigate = useNavigate();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [activeDialog, setActiveDialog] = useState<"leaveTeam" | "requestToJoin" | "cancelRequest" | "deleteTeam" | "removeMember" | null>(null);
    const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);
    const { authToken, userID } = useSelector((state: RootState) => state.data);
    const { setToaster } = useToaster();

    const { id } = useParams<{ id: string }>();
    const teamId = parseInt(id || "0", 10);

    const { data: teamData, isLoading: isTeamLoading } = useFetchTeamById(teamId, authToken);
    const { data: myTeamData, isLoading: isMyTeamLoading } = useGetMyTeam(authToken);
    const { mutate: createJoinRequest } = useCreateJoinRequest(authToken);
    const { mutate: cancelJoinRequest } = useCancelJoinRequest(authToken);
    const { mutate: deleteTeam } = useDeleteTeam(authToken);
    const { data: requestedTeams = [], isLoading: loadingRequestedTeams } = useGetMyRequests(authToken);
    const { mutate: leaveTeam } = useLeaveTeam(authToken);
    const { mutate: joinTeam } = useJoinTeam(authToken);
    const { mutate: removeTeamMember } = useRemoveTeamMember(teamId, authToken);

    const isAdmin = myTeamData && myTeamData?.is_captain == 1;
    const isTeamMember = myTeamData && myTeamData?.team_id == teamId;
    const userRequestStatus = requestedTeams.find((request: any) => request.team_id === teamData?.team_id)?.request_status;

    const requestId = requestedTeams.find((team: any) => team.team_id === teamId)?.request_id;

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    const handleJoinRequest = () => {
        createJoinRequest(
            { team_id: teamId },
            {
                onSuccess: () => {
                    setToaster("Join request sent successfully!", "success");
                },
                onError: (error) => {
                    setToaster("Failed to send join request", "error");
                    console.error("Failed to send join request", error);
                },
            }
        );
    };

    const handleCancelRequest = () => {
        if (!requestId) return;

        cancelJoinRequest(
            { request_id: requestId },
            {
                onSuccess: () => {
                    setToaster("Join request canceled successfully!", "success");
                },
                onError: (error) => {
                    setToaster("Failed to cancel join request", "error");
                    console.error("Failed to cancel join request", error);
                },
            }
        );
    };

    const handleJoinTeam = (teamPin: string) => {
        joinTeam(
            { team_pin: teamPin },
            {
                onSuccess: () => {
                    setToaster("Successfully joined the team!", "success");
                    navigate("/team-finder");
                },
                onError: (error) => {
                    setToaster("Failed to join the team", "error");
                    console.error("Failed to join the team", error);
                },
            }
        );
    };

    const handleDeleteTeam = () => {
        deleteTeam(
            { team_id: teamId },
            {
                onSuccess: () => {
                    setToaster("Team deleted successfully!", "success");
                    navigate("/team-finder");
                },
                onError: (error) => {
                    setToaster("Failed to delete team", "error");
                    console.error("Failed to delete team", error);
                },
            }
        );
    };

    const handleLeaveTeam = () => {
        leaveTeam(
            { team_id: teamId },
            {
                onSuccess: () => {
                    setToaster("You have left the team successfully!", "success");
                    navigate("/team-finder");
                },
                onError: (error) => {
                    setToaster("Failed to leave the team", "error");
                    console.error("Failed to leave the team", error);
                },
            }
        );
    };

    const handleRemoveMember = (hackerId: number) => {
        removeTeamMember(
            { team_id: teamId, hacker_id: hackerId },
            {
                onSuccess: () => {
                    setToaster("Member removed successfully!", "success");
                },
                onError: (error) => {
                    setToaster("Failed to remove member", "error");
                    console.error("Failed to remove member", error);
                },
            }
        );
    };

    useEffect(() => {
        setDrawerOpen(!isMobile);
    }, [isMobile]);

    if (isTeamLoading || loadingRequestedTeams) {
        return <p>Loading team information...</p>;
    }

    if (!teamData) {
        return <p>Team not found.</p>;
    }

    return (
        <>
            <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} contentType={"profile"} />
            <StyledButton onClick={toggleDrawer} isDrawerOpen={isDrawerOpen}>
                ☰
            </StyledButton>
            <ContentWrapper isDrawerOpen={isDrawerOpen} mobile={isMobile}>
                <BackButton />
                <ButtonContainer style={{ marginTop: 0 }} mobile={isMobile}>
                    <DashboardHeaderContainer mobile={isMobile}>
                        <StyledSpaceGrotesk mobile={isMobile}>Team Info</StyledSpaceGrotesk>
                        {isTeamMember && (
                            <StyledManrope>
                                Your team is not officially confirmed. You will need to register with your team on DoraHacks on the date
                                of the event to confirm your group members.
                            </StyledManrope>
                        )}
                    </DashboardHeaderContainer>
                </ButtonContainer>

                <TeamInfoContainer>
                    <TeamInfoSection />

                    <SectionHeaderText>Members ({teamData.team_members?.length || 0})</SectionHeaderText>

                    <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem", marginBottom: "20px" }}>
                        {teamData.team_members.map((member: any) => (
                            <HackerInfo
                                key={member.hacker_id}
                                hacker_id={member.hacker_id}
                                removeHacker={isAdmin ? () => {
                                    setSelectedMemberId(member.hacker_id);
                                    setActiveDialog("removeMember");
                                } : undefined}
                            />
                        ))}
                    </div>

                    <div style={{ marginBottom: "20px", color: "red", fontSize: "1rem" }}>
                        {!teamData.looking_for_member && <p>This team is currently not looking for new members.</p>}
                        {(teamData.team_members?.length || 0) >= 4 && <p>This team is full.</p>}
                    </div>

                    <ButtonContainer>
                        {isAdmin && (
                            <>
                                <ActionButton onClick={() => setActiveDialog("deleteTeam")}>Delete Team</ActionButton>
                                <ActionButton onClick={() => navigate("/team-requests")}>Incoming Requests</ActionButton>
                            </>
                        )}
                        {isTeamMember && !isAdmin && (
                            <ActionButton onClick={() => setActiveDialog("leaveTeam")}>Leave Team</ActionButton>
                        )}
                        {!isAdmin && !isTeamMember && (
                            <>
                                {userRequestStatus === "PEN" && (
                                    <ActionButton onClick={() => setActiveDialog("cancelRequest")}>Cancel Request</ActionButton>
                                )}
                                {userRequestStatus === "ACC" && (
                                    <>
                                        <ActionButton onClick={() => handleJoinTeam(teamData.team_pin)}>Join Team</ActionButton>
                                        <ActionButton onClick={() => setActiveDialog("cancelRequest")}>Cancel Request</ActionButton>
                                    </>

                                )}
                                {userRequestStatus === "RJE" && (
                                    <>
                                        <p style={{ color: "red" }}>Your join request was rejected.</p>
                                        <ActionButton onClick={() => setActiveDialog("cancelRequest")}>Cancel Request</ActionButton>
                                    </>
                                )}
                                {!userRequestStatus && (
                                    <ActionButton
                                        disabled={!teamData.looking_for_member || (teamData.team_members?.length || 0) >= 4}
                                        onClick={() => setActiveDialog("requestToJoin")}
                                    >
                                        Request to Join Team
                                    </ActionButton>
                                )}
                            </>
                        )}
                    </ButtonContainer>
                </TeamInfoContainer>
            </ContentWrapper>
            <DialogBox
                isOpen={activeDialog === "leaveTeam"}
                title={"Leave Team"}
                body={"Are you sure you want to leave the team?"}
                button1Text={"Leave Team"}
                button2Text={"Cancel"}
                button1Click={() => {
                    handleLeaveTeam();
                    setActiveDialog(null);
                }}
                button2Click={() => {
                    setActiveDialog(null);
                }}
                onClose={() => {
                    setActiveDialog(null);
                }}
            />
            <DialogBox
                isOpen={activeDialog === "requestToJoin"}
                title={"Request to Join Team"}
                body={"Are you sure you want to join this team?"}
                button1Text={"Request"}
                button2Text={"Cancel"}
                button1Click={() => {
                    handleJoinRequest();
                    setActiveDialog(null);
                }}
                button2Click={() => {
                    setActiveDialog(null);
                }}
                onClose={() => {
                    setActiveDialog(null);
                }}
            />
            <DialogBox
                isOpen={activeDialog === "cancelRequest"}
                title={"Cancel Request"}
                body={"Are you sure you want to cancel your join request?"}
                button1Text={"Cancel Request"}
                button2Text={"Go Back"}
                button1Click={() => {
                    handleCancelRequest();
                    setActiveDialog(null);
                }}
                button2Click={() => {
                    setActiveDialog(null);
                }}
                onClose={() => {
                    setActiveDialog(null);
                }}
            />
            <DialogBox
                isOpen={activeDialog === "deleteTeam"}
                title={"Delete Team"}
                body={"Are you sure you want to delete this team? This action cannot be undone."}
                button1Text={"Delete Team"}
                button2Text={"Cancel"}
                button1Click={() => {
                    handleDeleteTeam();
                    setActiveDialog(null);
                }}
                button2Click={() => {
                    setActiveDialog(null);
                }}
                onClose={() => {
                    setActiveDialog(null);
                }}
            />
            <DialogBox
                isOpen={activeDialog === "removeMember"}
                title={"Remove Member"}
                body={"Are you sure you want to remove this member from the team?"}
                button1Text={"Remove"}
                button2Text={"Cancel"}
                button1Click={() => {
                    if (selectedMemberId !== null) {
                        handleRemoveMember(selectedMemberId);
                    }
                    setActiveDialog(null);
                }}
                button2Click={() => {
                    setActiveDialog(null);
                }}
                onClose={() => {
                    setActiveDialog(null);
                }}
            />
        </>
    );
};

export default TeamInfoComponent;
