import React, { ReactNode } from "react";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
  StyledDashboard,
  InfoBoxContainer,
  ColourText,
  Image,
  StyledSubjectivity,
  ModuleBackground,
  HandIcon,
  AcornIcon,
} from "./index.styles";
import StarSVG from "./star-icon";
import { useNavigate } from "react-router-dom";

type StyledDashboardProps = {
  mobile?: boolean | null;
};
type DashboardProps = StyledDashboardProps & {
  children: ReactNode;
  isQuiz?: boolean;
  goHome?: boolean;
  homeText?: boolean;
};

const DashboardContainer: React.FC<DashboardProps> = React.memo(
  ({ children, isQuiz, goHome, homeText = true }) => {
    const isMobile = useMobileDetect();
    const navigate = useNavigate();
    return (
      <StyledDashboard mobile={isMobile} isQuiz={isQuiz}>
        <ModuleBackground src={"/backgrounds/home.png"} />
        <AcornIcon src={"/backgrounds/acorn.svg"} />
        <HandIcon src={"/backgrounds/peace-hand.svg"} />
        {homeText && (
          <StyledSubjectivity mobile={isMobile}>
            <ColourText
              mobile={isMobile}
              isQuiz={isQuiz}
              onClick={() => {
                if (goHome) {
                  navigate("/");
                }
              }}
              goHome={goHome}
            >
              UofTHacks 12
            </ColourText>
          </StyledSubjectivity>
        )}
        <InfoBoxContainer mobile={isMobile}>{children}</InfoBoxContainer>
      </StyledDashboard>
    );
  }
);

export default DashboardContainer;
