import styled, { keyframes } from "styled-components";
import Inter from "../../inter";
import InterSemiBold from "../../inter-semi-bold";
import SpaceGrotesk from "../../space-grotesk";
import Manrope from "../../manrope";

type HomePageContentProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
  newHeader?: boolean | null;
};

type ButtonProps = {
  color?: string;
  disabled?: boolean;
};

const DashboardHeaderContainer = styled.div<HomePageContentProps>`
  border-radius: 10px;
  color: var(--White, #225c90);
  padding: 3.125rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  ${({ mobile }) =>
    mobile &&
    `
    max-width: 90vw;
  `}
`;

const AcceptedDashboardContainer = styled.div<HomePageContentProps>`
  border-radius: 10px;
  border: 1px solid var(--Black, #282828);
  background: var(--Black, #282828);
  ${({ mobile, newHeader }) =>
    !mobile &&
    newHeader &&
    `
    background: var(--Black, #282828);
    background-image: 
    url("/assets/stars/purple-star.svg"), 
    url("/assets/stars/small-star.svg"),
    url("/assets/stars/purple-star.svg"),
    url("/assets/stars/purple-star.svg");
    background-repeat: no-repeat;
    background-position: 
    15% 0%, 
    75% 10%,
    85% 80%, 
    60% 100%; 
`}

  color: var(--White, #f9f9f9);
  padding: 3.125rem 2.5rem;
  display: flex;
  width: 100%;
  gap: 1.5rem;
  ${({ mobile }) =>
    mobile &&
    `
    max-width: 70vw;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `}

  ${({ newHeader }) =>
    newHeader &&
    `
  position: relative;
  `}
`;

const AcceptedDashboardTextContainer = styled.div<HomePageContentProps>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const StyledSpaceGrotesk = styled(SpaceGrotesk) <HomePageContentProps>`
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "30px" : "60px")};
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  color: #225c90;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledManrope = styled(Manrope) <HomePageContentProps>`
  leading-trim: both;
  text-edge: cap;
  /* Body 1 (16px) */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #225c90;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const StyledManropeNoDecoration = styled(StyledManrope)`
  &::before {
    display: none;
  }
`;

const RSVPDateText = styled.b<HomePageContentProps>`
  color: var(--White, #f9f9f9);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const RSVPButtonContainer = styled.div<HomePageContentProps>`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
  justify-content: ${({ mobile }) => (mobile ? "flex-start" : "flex-end")};
`;

const ButtonContainer = styled.div<HomePageContentProps>`
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
  padding-right: ${({ mobile }) => (mobile ? "0" : "1.5rem")};
`;

const ButtonStyle = styled.div<ButtonProps>`
  background-color: ${({ color }) => color};
  padding: 1.25rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #0DAEF1;
  width: 100%;
  max-width: 75vw;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #ECECEC;
    border: 1px solid #0b96d4;
    cursor: not-allowed;
    opacity: 0.7;
  `}

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  gap: 1.25rem;
  align-items: center;
  align-content: center;
  align-self: center;
  height: 100px;
  outline: 1px solid transparent;
  transition: 0.2s ease-in-out;
  ${({ disabled }) =>
    !disabled &&
    `
  &:hover {
    outline: 1px solid var(--Black, #0b96d4);
  `}
}
`;

const ButtonText = styled(Inter) <ButtonProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const NoneButton = styled.div`
  padding: 1.25rem 1.5rem;
  width: 100%;
  max-width: 70vw;
  opacity: 0;
`;

const StyledImage = styled.img`
  object-fit: contain;
  width: 40px;
  height: 40px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const Image = styled.img<HomePageContentProps>`
  display: ${(props) => (props.mobile ? "none" : "block")};
  position: absolute;
  top: 40px;
  right: 30px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const DashboardSubtitleBolded = styled(InterSemiBold) <HomePageContentProps>`
  margin: 0;
  padding: 0;
  color: var(--White, #f9f9f9);
  display: inline;
  text-align: left;
  font-size: 16px;
`;

const StyledLink = styled.a`
  color: #225c90;
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-10px, -20px);
  }
  40% {
    transform: translate(-20px, -10px);
  }
  60% {
    transform: translate(-20px, 0);
  }
  80% {
    transform: translate(10px, 20px);
  }
  90% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(10px, 0);
  }

`;
const BouncingImage = styled.img<{ display: any; mobile: any }>`
  object-fit: contain;
  width: 40px;
  height: 40px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  display: ${(props) => (props.mobile ? "none" : props.display)};
  width: 100px;
  height: auto;
  right: 70px;
  top: 40px;
  animation: ${bounceAnimation} 2s infinite;
  position: absolute;
`;

// Add these new styled components
const BigButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: white;
  border: 1.5px solid var(--Brand-Primary-200, #0daef1);
  border-radius: 20px;
  flex: 1;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const IconContainer = styled.div`
  margin-bottom: 8px;

  img {
    width: 32px;
    height: 32px;
  }
`;

const SmallText = styled.div`
  color: #666666;
  font-size: 14px;
  text-align: left;
`;

const LargeText = styled.div`
  color: #225c90;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`;

const ButtonGroup = styled.div<{ mobile: boolean | null }>`
  display: flex;
  flex-direction: ${props => props.mobile ? 'column' : 'row'};;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StatusRow = styled.div<{ mobile: boolean }>`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 24px;
  flex-direction: ${props => props.mobile ? 'column' : 'row'};
`;

const ProfileButton = styled.button`
  display: flex;
  align-items: center;
  padding: 16px;
  background: transparent;
  border: none;
  border-radius: 10px;
  flex: 1;
  gap: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(13, 174, 241, 0.1);
  }
`;

const ProfileImage = styled.img<{ easteregg?: boolean }>`
  width: 60px;
  height: 60px;
  object-fit: cover;

  ${({ easteregg }) => easteregg && `
    animation: spin 3s linear infinite;

    @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
  `}
`;

const ProfileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const ProfileTitle = styled.div`
  color: #666666;
  font-size: 14px;
`;

const ProfileName = styled.div`
  color: #225c90;
  font-size: 18px;
  font-weight: 600;
`;

const StatusText = styled.div`
  padding-top: 5px;
  color: #ffffff;
  font-size: 20px;
`;

const StatusBar = styled.div<{
  status?: "accepted" | "pending" | "waitlisted" | "noApplication" | "rejected";
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 14px;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ status }) =>
    status === "accepted"
      ? "#4CAF50" // Green
      : status === "waitlisted"
        ? "#FFCDF7" // Waitlisted
        : status === "noApplication"
          ? "#A9A9A9" // Grey
          : status === "rejected"
            ? "#FF0000" // Rejected
            : "#FFC107" // Under Review
  };
    display: inline-block;
  }
`;

const StatusBar2 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
`;

const StatusButton = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  // background: #225c90;
  border-radius: 10px;
  flex: 1;
  gap: 16px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-left: 5px;
  height: 100%;
`;

const RSVPButton = styled.button`
  padding: 8px 16px;
  border-radius: 6px;
  width: 35%;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: #0b96d4;
  }
`;

export {
  StyledSpaceGrotesk,
  StyledManrope,
  ButtonContainer,
  ButtonStyle,
  ButtonText,
  NoneButton,
  StyledImage,
  DashboardHeaderContainer,
  Image,
  DashboardSubtitleBolded,
  StyledLink,
  BouncingImage,
  RSVPDateText,
  RSVPButtonContainer,
  AcceptedDashboardContainer,
  AcceptedDashboardTextContainer,
  BigButton,
  IconContainer,
  SmallText,
  LargeText,
  ButtonGroup,
  RSVPButton,
  StatusRow,
  ProfileButton,
  ProfileImage,
  ProfileTextContainer,
  ProfileTitle,
  ProfileName,
  StatusButton,
  StatusContainer,
  StatusText,
  StatusBar,
  StatusBar2,
  StyledManropeNoDecoration,
};
