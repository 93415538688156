import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { RootState } from "../../../redux/store";
import {
  PassportPage,
  PassportImage,
  PassportPhoto,
  PassportText,
  PassportHeading,
  InfoWidget,
} from "./index.styles";
import QRCode from "react-qr-code";
import { charDetails } from "../../quiz/characters";

const ProfPage1 = React.memo(({ show }: { show: boolean }) => {
  const { } = useSelector((state: RootState) => state.application);
  const isMobile = useMobileDetect();
  const dispatch = useDispatch();
  const [showingQRCode, setShowingQRCode] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowingQRCode(false);
    }, 1);
  }, []);

  const character =
    useSelector((state: RootState) => state.application.character_name) || "";

  const { header, imageSrc, imgBackground } = charDetails[
    character.toLowerCase() as keyof typeof charDetails
  ]
    ? charDetails[character.toLowerCase() as keyof typeof charDetails]
    : {
      //testing- by default for now
      header: "The Axolotl - The Inquisitive Scholar",
      imageSrc: "/assets/characters/axolotl.svg",
      imgBackground: "#F9F9F9",
    };
  const [characterName, shortDescription] = header.split(" - ");

  return (
    <PassportPage id="page-1" show={show}>

      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          flexWrap: "wrap",
          alignContent: "center",
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
      // onClick={() => {
      //   setShowingQRCode(!showingQRCode);
      // }}
      >
        <PassportPhoto>
          <PassportImage
            showingQR={showingQRCode}
            imgBackground={imgBackground}
          >
            <img
              src={imageSrc}
              alt="passport photo"
              style={{
                width: "100%",
                display: showingQRCode ? "none" : "block",
              }}
            />
          </PassportImage>
        </PassportPhoto>
        <div style={{ width: "100%" }}>
          <InfoWidget>
            <PassportText mobile={isMobile}>
              {" "}
              {characterName || "Unknown Character"}
            </PassportText>
            <PassportHeading mobile={isMobile}>
              {shortDescription || "No description available."}
            </PassportHeading>
          </InfoWidget>
        </div>
      </div>
    </PassportPage>
  );
});

export default ProfPage1;
