import React from "react";
import {
    HelperContainer,
    HelperImage,
    HelperItemsRow,
    HelperText,
    CloseButton,
    SingleHelperItem,
} from "./index.styles";
import { HelperHeaderText } from "../index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";

interface HelperPromptProps {
    isVisible: boolean;
    setHelper: (value: boolean) => void;
}

const HelperPrompt = ({ isVisible, setHelper }: HelperPromptProps) => {
    const isMobile = useMobileDetect();

    const handleClose = () => {
        setHelper(false);
    };

    if (!isVisible) return null;

    return (
        <HelperContainer style={{ padding: isMobile ? "20px" : "50px" }}>
            <CloseButton onClick={handleClose}>X</CloseButton>

            <HelperHeaderText style={{ fontSize: isMobile ? "16px" : "30px" }}>
                How it works
            </HelperHeaderText>

            <HelperItemsRow
                style={{
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "center" : "flex-start",
                }}
            >
                <SingleHelperItem style={{ width: isMobile ? "100%" : "30%" }}>
                    <HelperImage
                        src={"assets/illustrations/create-team.svg"}
                        alt="Create Team Icon"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                    <HelperText style={{ textAlign: isMobile ? "center" : "left" }}>
                        Click the Create Team button and provide details about the type of team you’re looking to form.
                    </HelperText>
                </SingleHelperItem>
                <SingleHelperItem style={{ width: isMobile ? "100%" : "30%" }}>
                    <HelperImage
                        src={"assets/illustrations/input-pin.svg"}
                        alt="Input Pin Icon"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                    <HelperText style={{ textAlign: isMobile ? "center" : "left" }}>
                        Input the pin of an already existing team.
                    </HelperText>
                </SingleHelperItem>
                <SingleHelperItem style={{ width: isMobile ? "100%" : "30%" }}>
                    <HelperImage
                        src={"assets/illustrations/request-join.svg"}
                        alt="Request Join Icon"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                    <HelperText style={{ textAlign: isMobile ? "center" : "left" }}>
                        Request to join publicly listed teams.
                    </HelperText>
                </SingleHelperItem>
            </HelperItemsRow>
        </HelperContainer>
    );
};

export default HelperPrompt;
