import { API_BASE_URL, API_TYPE, API_VERSION } from "../api/apiConfig";

const API_PREFIX = `${API_BASE_URL}/${API_VERSION}`;

const getAuthHeaders = (accessToken?: string) => {
    const headers: Record<string, string> = { "Content-Type": "application/json" };
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }
    return headers;
};

export class FetchError extends Error {
    status?: number;

    constructor(message: string, status?: number) {
        super(message);
        this.status = status;

        Object.setPrototypeOf(this, FetchError.prototype);
    }
}

const fetchWithErrorHandling = async (
    url: string,
    options: RequestInit,
    errorMessage: string
) => {
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            const errorBody = await response.json();

            throw new FetchError(
                errorBody.message || errorMessage,
                response.status
            );
        }
        return response.json();
    } catch (error) {
        if (!(error instanceof FetchError)) {
            console.error(`Unexpected error in fetch: ${url}`, error);
        }
        throw error;
    }
};

export const fetchTeams = async (
    queryParams: Record<string, any> = {},
    accessToken?: string
) => {
    const query = new URLSearchParams(queryParams).toString();
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/get_all?${query}`,
        {
            headers: getAuthHeaders(accessToken),
        },
        "Failed to fetch teams"
    );
};

export const addTeam = async (
    data: {
        team_name: string;
        is_private?: boolean;
        looking_for_member?: boolean;
        team_project?: string;
        team_motto?: string;
        team_members?: number[];
    },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/add`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to add team"
    );
};

export const editTeam = async (
    data: {
        team_id: number;
        team_name?: string;
        looking_for_member?: boolean;
        team_project?: string;
        team_motto?: string;
    },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/edit`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to edit team"
    );
};

export const togglePrivacy = async (
    data: { team_id: number },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/toggle`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to toggle team privacy"
    );
};

export const joinTeam = async (
    data: { team_pin: string },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/join`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to join team"
    );
};

export const searchTeamByPin = async (
    team_pin: string,
    accessToken?: string
) => {
    const queryParams = new URLSearchParams({ pin: team_pin }).toString();
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/search?${queryParams}`,
        {
            method: "POST",
            headers: getAuthHeaders(accessToken),
        },
        "Failed to find team by pin"
    );
};

export const createJoinRequest = async (
    data: { team_id: number },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/requests/create`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to create join request"
    );
};

export const cancelJoinRequest = async (
    data: { request_id: number },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/requests/cancel`,
        {
            method: "DELETE",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to cancel join request"
    );
};

export const getPendingRequests = async (
    team_id: number,
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/requests/pending?team_id=${team_id}`,
        {
            headers: getAuthHeaders(accessToken),
        },
        "Failed to fetch pending requests"
    );
};

export const getMyRequests = async (
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/requests/mine`,
        {
            headers: getAuthHeaders(accessToken),
        },
        "Failed to fetch my requests"
    );
};

export const getMyTeam = async (
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/my-team`,
        {
            headers: getAuthHeaders(accessToken),
        },
        "Failed to fetch my team"
    );
};

export const manageTeamRequest = async (
    data: { request_id: number; action: "accept" | "deny" },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/requests/manage`,
        {
            method: "POST",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to manage team request"
    );
};

export const getHackerProfileById = async (
    hacker_id: number,
    accessToken?: string
) => {
    const queryParams = new URLSearchParams({ hacker_id: String(hacker_id) }).toString();
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/hacker?${queryParams}`,
        {
            headers: getAuthHeaders(accessToken),
        },
        "Failed to fetch hacker profile"
    );
};

export const leaveTeam = async (
    data: { team_id: number },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/leave`,
        {
            method: "DELETE",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to leave team"
    );
};

export const deleteTeam = async (
    data: { team_id: number },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/delete`,
        {
            method: "DELETE",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to delete team"
    );
};

export const removeTeamMember = async (
    data: { team_id: number, hacker_id: number },
    accessToken?: string
) => {
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/remove-member`,
        {
            method: "DELETE",
            body: JSON.stringify(data),
            headers: getAuthHeaders(accessToken),
        },
        "Failed to remove team member"
    );
};

export const fetchTeamById = async (
    team_id: number,
    accessToken?: string
) => {
    const queryParams = new URLSearchParams({ team_id: String(team_id) }).toString();
    return fetchWithErrorHandling(
        `${API_PREFIX}/${API_TYPE.TEAM}/info?${queryParams}`,
        {
            headers: getAuthHeaders(accessToken),
        },
        "Failed to fetch team info"
    );
};
