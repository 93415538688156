import React, { ReactHTMLElement, useEffect, useRef, useState } from "react";
import {
  StyledSubjectivity,
  StyledInter,
  StyledInterSemiBold,
  StyledSpaceGrotesk,
  StyledImage,
  MainContainer,
  Passport,
  PassportHeader,
  PassportBody,
  PassportFooter,
  PassportButton,
  PassportButtons,
  PassportPage,
  QRName,
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, setCharacterName } from "../../../redux/store";
import { ButtonContainer } from "../drawer/index.styles";
import ProfPage1 from "./profile-page-1";
import ProfPage2 from "./profile-page-2";
import ProfPage3 from "./profile-page-3";
import ProfPage4 from "./profile-page-4";
import ProfPage5 from "./profile-page-5";
import ProfPage6 from "./profile-page-6";
import { API_BASE_URL, API_TYPE, API_VERSION } from "../../../api/apiConfig";
import QRCode from "react-qr-code";
import Button from "../../button";
import { useDispatch } from "react-redux";
import { resolve } from "path";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";

type HomeProps = {
  isDrawerOpen?: boolean;
};

const Profile: React.FC<HomeProps> = ({ isDrawerOpen }) => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refBlue = useRef(null);
  const refPink = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const passportFooterRef = useRef<HTMLDivElement>(null);

  const [footerWidth, setFooterWidth] = useState(0);
  const [numPages] = useState(3);

  useEffect(() => {
    const footerElement = passportFooterRef.current;
    if (footerElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setFooterWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(footerElement);

      // Clean up function
      return () => {
        resizeObserver.unobserve(footerElement);
      };
    }
  }, [passportFooterRef]);

  const { authToken } = useSelector((state: RootState) => state.data);
  const [age, setAge] = useState("N/A");
  const [currentYear, setCurrentYear] = useState("N/A");
  const [dietCondition, setDietCondition] = useState(["N/A"]);
  const [emgName, setEmgName] = useState("");
  const [emgPhoneNum, setEmgPhoneNum] = useState("");
  const [emgRelation, setEmgRelation] = useState("");
  const [major, setMajor] = useState("N/A");
  const [pronoun, setPronoun] = useState("N/A");
  const [school, setSchool] = useState("N/A");
  const [hackerBucks, setHackerBucks] = useState(0);
  const [stampColl, setStampColl] = useState("");
  const [userFName, setUserFName] = useState("FirstName");
  const [userLName, setUserLName] = useState("LastName");
  const [userID, setUserID] = useState("");
  const [userPersonality, setUserPersonality] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [downloadQR, setDownloadQr] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  const fetchProfileData = () => {
    fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const {
              hacker_fname,
              hacker_lname,
              dietary_condition,
              hacker_bucks,
              pronoun,
              school,
              major,
              age,
              level_of_study
            } = data;
            setAge(age ? age : "N/A");
            setCurrentYear(level_of_study ? level_of_study : "N/A");
            setDietCondition(dietary_condition ? dietary_condition.split(',').map((item: any) => item.trim()) : []);
            // setEmgName(emg_name ? emg_name : "N/A");
            // setEmgPhoneNum(emg_phone_num ? emg_phone_num : "N/A");
            // setEmgRelation(emg_relation ? emg_relation : "N/A");
            setMajor(major ? major : "N/A");
            setPronoun(pronoun ? pronoun : "N/A");
            setHackerBucks(hacker_bucks);
            setSchool(school ? school : "N/A");
            // setStampColl(stamp_coll ? stamp_coll : "N/A");
            setUserFName(hacker_fname ? hacker_fname : "N/A");
            // setUserID(hacker_id ? hacker_id : "N/A");
            setUserLName(hacker_lname ? hacker_lname : "N/A");
            // dispatch(
            //   setCharacterName(user_personality ? user_personality : "N/A")
            // );
          });

          //Get QR code info
          // fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/qr`, {
          //   method: "GET",
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: `Bearer ${authToken}`,
          //   },
          // }).then((response) => {
          //   if (response.ok) {
          //     response.json().then((data) => {
          //       setQrCode(data["qr_code"]);
          //     });
          //   }
          // });
        } else {
          throw new Error('Response not OK');
        }
      })
      .catch((error) => {
        if (retryCount < MAX_RETRIES) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
            fetchProfileData();
          }, RETRY_DELAY);
        } else {
          console.error('Failed to fetch profile data after multiple attempts:', error);
          // Navigate to /home after all retries fail
          navigate('/home');
        }
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    fetchProfileData();
  }, []);

  const [page, setPage] = useState(1);
  const [isLeftPage, setIsLeftPage] = useState(true);

  const getNumberOfLettersAllowedInOnePage = () => {
    return Math.floor(((footerWidth - 10) / 2) * 0.111);
  };

  const letter = "<";

  const nameID = `${userFName.toUpperCase()}`;

  const repeatCountName = getNumberOfLettersAllowedInOnePage() - nameID.length;
  const repeatedLetterName =
    repeatCountName > 0 ? letter.repeat(repeatCountName) : "";
  const namePageFooter = nameID + repeatedLetterName;

  const pageNum = `Page ${page + (isMobile ? page : 0) - (isMobile && isLeftPage ? 1 : 0)
    }`;

  const repeatCountPage = getNumberOfLettersAllowedInOnePage() - pageNum.length;
  const repeatedLetterPage =
    repeatCountPage > 0 ? letter.repeat(repeatCountPage) : "";
  const pageNumFooter = repeatedLetterPage + pageNum;

  const nameIDMobile = `${userFName.toUpperCase()}`;

  const repeatCountPageMobile =
    getNumberOfLettersAllowedInOnePage() * 2 -
    (pageNum.length + nameIDMobile.length);
  const repeatedLetterPageMobile =
    repeatCountPageMobile > 0 ? letter.repeat(repeatCountPageMobile) : "<";
  const pageNumFooterMobile = nameIDMobile + repeatedLetterPageMobile + pageNum;

  const htmlToImageConvert = (qr: string) => {
    if (qr == "blue") {
      setIsDownloading(true);
      setTimeout(
        () =>
          toPng(refBlue.current as unknown as HTMLElement, { cacheBust: false })
            .then((dataUrl) => {
              saveAs(dataUrl, "UofTHacks_11_QR_Code");
              // const link = document.createElement("a");
              // link.download = "UofTHacks11_QR_Code.png";
              // link.href = dataUrl;
              // link.click();
              setIsDownloading(false);
            })
            .catch((err) => {
              console.log(err);
            }),
        1000
      );
    } else {
      setIsDownloading(true);
      setTimeout(() =>
        toPng(refPink.current as unknown as HTMLElement, {
          cacheBust: false,
        }).then((dataUrl) => {
          saveAs(dataUrl, "UofTHacks_11_QR_Code");
          // const link = document.createElement("a");
          // link.download = "UofTHacks11_QR_Code.png";
          // link.href = dataUrl;
          // link.click();
          setIsDownloading(false);
        })
      );
    }
  };

  // if (downloadQR) {
  //   return (
  //     <MainContainer>
  //       <StyledImage
  //         src="/assets/home-page/back-button.svg"
  //         onClick={() => window.location.reload()}
  //       />
  //       <div
  //         style={{
  //           width: "100%",
  //           display: "flex",
  //           flexWrap: "wrap",
  //           flexDirection: isMobile ? "column" : "row",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           gap: isDrawerOpen ? "40px" : "80px",
  //         }}
  //       >
  //         <div
  //           id="blue"
  //           style={{
  //             display: "flex",
  //             width: isDownloading
  //               ? "1000px"
  //               : isMobile
  //                 ? "50%"
  //                 : isDrawerOpen
  //                   ? "30%"
  //                   : "20%",
  //             flexDirection: "column",
  //             alignItems: "center",
  //             gap: "20px",
  //           }}
  //         >
  //           <div
  //             ref={refBlue}
  //             style={{
  //               backgroundColor: "#2850A0",
  //               borderRadius: "15px",
  //               position: "relative",
  //               width: isDownloading ? "1000px" : "100%",
  //               paddingTop: "130%",
  //               overflow: "hidden",
  //             }}
  //           >
  //             <img
  //               src="/assets/profile/qr/ticket.svg"
  //               alt="ticket"
  //               style={{
  //                 position: "absolute",
  //                 left: "0",
  //                 top: "2%",
  //                 width: "25%",
  //               }}
  //             />
  //             <img
  //               src="/assets/profile/qr/UofTHacks_logo.svg"
  //               alt="uoft logo"
  //               style={{
  //                 position: "absolute",
  //                 left: "30%",
  //                 top: "10%",
  //                 width: "39%",
  //               }}
  //             />
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 left: "30%",
  //                 top: "39%",
  //                 width: "39%",
  //                 textAlign: "center",
  //               }}
  //             >
  //               <QRName
  //                 isDownloading={isDownloading}
  //                 isMobile={isMobile}
  //                 isDrawerOpen={isDrawerOpen}
  //               >
  //                 {userFName} {userLName}
  //               </QRName>
  //             </div>
  //             <div
  //               style={{
  //                 width: "41%",
  //                 padding: "7%",
  //                 backgroundColor: "#FFFFFF",
  //                 display: "flex",
  //                 alignItems: "center",
  //                 borderRadius: "8px",
  //                 position: "absolute",
  //                 top: "46%",
  //                 left: "22.5%",
  //               }}
  //             >
  //               <QRCode
  //                 value={qrCode}
  //                 style={{ width: "100%", height: "0%" }}
  //               />
  //             </div>
  //             <img
  //               src="/assets/profile/qr/star.svg"
  //               alt="star"
  //               style={{
  //                 position: "absolute",
  //                 width: "24%",
  //                 left: "76%",
  //                 top: "86%",
  //               }}
  //             />
  //           </div>
  //           <Button
  //             text="Download"
  //             colour="#F0A000"
  //             textColour="#000000"
  //             onClick={() => htmlToImageConvert("blue")}
  //           />
  //         </div>
  //         <div
  //           style={{
  //             display: "flex",
  //             width: isDownloading
  //               ? "1000px"
  //               : isMobile
  //                 ? "50%"
  //                 : isDrawerOpen
  //                   ? "30%"
  //                   : "20%",
  //             flexDirection: "column",
  //             alignItems: "center",
  //             gap: "20px",
  //           }}
  //         >
  //           <div
  //             ref={refPink}
  //             style={{
  //               backgroundColor: "#A050A0",
  //               borderRadius: "15px",
  //               position: "relative",
  //               width: isDownloading ? "1000px" : "100%",
  //               paddingTop: "130%",
  //               overflow: "hidden",
  //             }}
  //           >
  //             <img
  //               src="/assets/profile/qr/record.svg"
  //               alt="record"
  //               style={{
  //                 position: "absolute",
  //                 left: "75%",
  //                 top: "0",
  //                 width: "25%",
  //               }}
  //             />
  //             <img
  //               src="/assets/profile/qr/UofTHacks_logo.svg"
  //               alt="uoft logo"
  //               style={{
  //                 position: "absolute",
  //                 left: "30%",
  //                 top: "10%",
  //                 width: "39%",
  //               }}
  //             />
  //             <div
  //               style={{
  //                 position: "absolute",
  //                 left: "30%",
  //                 top: "39%",
  //                 width: "39%",
  //                 textAlign: "center",
  //               }}
  //             >
  //               <QRName
  //                 isDownloading={isDownloading}
  //                 isMobile={isMobile}
  //                 isDrawerOpen={isDrawerOpen}
  //               >
  //                 {userFName} {userLName}
  //               </QRName>
  //             </div>
  //             <div
  //               style={{
  //                 width: "41%",
  //                 padding: "7%",
  //                 backgroundColor: "#FFFFFF",
  //                 display: "flex",
  //                 alignItems: "center",
  //                 borderRadius: "8px",
  //                 position: "absolute",
  //                 top: "46%",
  //                 left: "22.5%",
  //               }}
  //             >
  //               <QRCode
  //                 value={qrCode}
  //                 style={{ width: "100%", height: "0%" }}
  //               />
  //             </div>
  //             <img
  //               src="/assets/profile/qr/star_diamond.svg"
  //               alt="star"
  //               style={{
  //                 position: "absolute",
  //                 width: "24%",
  //                 left: "0%",
  //                 top: "80%",
  //               }}
  //             />
  //           </div>
  //           <Button
  //             text="Download"
  //             colour="#F0A000"
  //             textColour="#000000"
  //             onClick={() => htmlToImageConvert("pink")}
  //           />
  //         </div>
  //       </div>
  //     </MainContainer>
  //   );
  // }

  return (
    <MainContainer>
      <div>
        <StyledSpaceGrotesk mobile={isMobile}>
          <StyledImage
            src="/assets/home-page/back-button.svg"
            onClick={() => navigate("/")}
          />
          Profile
        </StyledSpaceGrotesk>
        <Passport id="passport" mobile={isMobile}>
          <PassportHeader>
            <span
              style={{
                display: "block",
                flex: 1,
                height: "1px",
                backgroundColor: "#225C90",
                margin: "0 10px",
                border: "1px solid #225C90",
              }}
            />
            <img
              src="/assets/profile/profile-header.svg"
              alt="Header Frame"
              style={{ width: "100%", height: "auto" }}
            />
            <span
              style={{
                display: "block",
                flex: 1,
                height: "1px",
                backgroundColor: "#225C90",
                margin: "0 10px",
                border: "1px solid #225C90",
              }}
            />
          </PassportHeader>

          <PassportBody>
            {isMobile && page === 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ProfPage1 show={true} />
                <ProfPage2
                  show={true}
                  emgPhoneNum={emgPhoneNum}
                  dietCondition={dietCondition}
                  age={age}
                  name={userFName + " " + userLName}
                  pronoun={pronoun}
                  hackerBucks={hackerBucks}
                  school={school}
                  program={major}
                  currentYear={currentYear}
                />
              </div>
            ) : (
              <ProfPage2
                show={page === 1 && isLeftPage}
                emgPhoneNum={emgPhoneNum}
                dietCondition={dietCondition}
                age={age}
                name={userFName + " " + userLName}
                pronoun={pronoun}
                school={school}
                hackerBucks={hackerBucks}
                program={major}
                currentYear={currentYear}
              />
            )}

            <ProfPage3
              show={isMobile ? page == 2 && isLeftPage : page == 2}
              isMobile={isMobile}
              isDrawerOpen={isDrawerOpen}
            />
            <ProfPage5
              show={isMobile ? page == 3 && isLeftPage : page == 3}
              isMobile={isMobile}
              isDrawerOpen={isDrawerOpen}
              stamp_coll={stampColl}
            />
            {!isMobile && <span />}
            <ProfPage1
              show={isMobile ? page == 1 && !isLeftPage : page == 1}
            //qrCode={qrCode}
            />
            <ProfPage4
              show={isMobile ? page == 2 && !isLeftPage : page == 2}
              isMobile={isMobile}
              isDrawerOpen={isDrawerOpen}
              stamp_coll={stampColl}
            />
            <ProfPage6
              show={isMobile ? page == 3 && !isLeftPage : page == 3}
              isMobile={isMobile}
              isDrawerOpen={isDrawerOpen}
              stamp_coll={stampColl}
            />
          </PassportBody>
          <PassportFooter>
            <span
              style={{
                display: "block",
                flex: 1,
                height: "1px",
                backgroundColor: "#225C90",
                margin: "0 10px",
                border: "1px solid #225C90",
              }}
            />
            <img
              src="/assets/profile/profile-footer.svg"
              alt="Footer Frame"
              style={{ width: "100%", height: "auto" }}
            />
            <span
              style={{
                display: "block",
                flex: 1,
                height: "1px",
                backgroundColor: "#225C90",
                margin: "0 10px",
                border: "1px solid #225C90",
              }}
            />
          </PassportFooter>
        </Passport>
        {/* <PassportButtons mobile={isMobile}>
          <PassportButton
            disabled={!((isMobile && !isLeftPage) || page > 1)}
            src={
              (isMobile && !isLeftPage) || page > 1
                ? "/assets/profile/profile-left-hover.svg"
                : "/assets/profile/profile-left.svg"
            }
            onClick={() => {
              if (isMobile) {
                if (!isLeftPage) {
                  setIsLeftPage(true);
                } else {
                  if (page - 1 > 0) {
                    setPage(page - 1);
                    setIsLeftPage(false);
                  }
                }
              } else {
                if (page - 1 > 0) {
                  setPage(page - 1);
                  setIsLeftPage(true);
                }
              }
            }}
          />
          <Button
            text="Download QR code"
            colour="#F0A000"
            textColour="000000"
            onClick={() => setDownloadQr(true)}
          />

          <PassportButton
            disabled={!((isMobile && isLeftPage) || page < numPages)}
            src={
              (isMobile && isLeftPage) || page < numPages
                ? "/assets/profile/profile-right-hover.svg"
                : "/assets/profile/profile-right.svg"
            }
            onClick={() => {
              if (isMobile) {
                if (isLeftPage) {
                  setIsLeftPage(false);
                } else {
                  if (page + 1 <= numPages) {
                    setPage(page + 1);
                    setIsLeftPage(true);
                  }
                }
              } else {
                if (page + 1 <= numPages) {
                  setPage(page + 1);
                  setIsLeftPage(true);
                }
              }
            }}
          />
        </PassportButtons> */}
      </div>
    </MainContainer>
  );
};

export { Profile };
