import React, { useState } from "react";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import {
    AcceptButton,
    ButtonGroup,
    DenyButton,
    RequestActions,
    RequestContent,
} from "./index.styles";
import ConfirmTeamRequestModal from "../../../components/confirm-team-request-modal";
import { RequestItem } from "../index.styles";
import { useGetMyTeam, useGetPendingRequests, useManageTeamRequest } from "../../../hooks/useTeamFinder";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useToaster } from "../../../providers/ToasterProvider";
import HackerInfo from "../../../components/hacker-info";

const RequestsCollection = () => {
    const isMobile = useMobileDetect();
    const [activeDialog, setActiveDialog] = useState<{
        action: "accept" | "deny";
        requestId: number;
    } | null>(null);

    const { authToken } = useSelector((state: RootState) => state.data);
    const { setToaster } = useToaster();

    const { data: myTeamData, isLoading: isMyTeamLoading } = useGetMyTeam(authToken);
    const { data: pendingRequests, isLoading } = useGetPendingRequests(myTeamData?.team_id, authToken);
    const { mutate: manageRequest, isPending: isManagingRequest } = useManageTeamRequest(authToken);

    const finalizeRequest = (requestId: number, action: "accept" | "deny") => {
        manageRequest(
            { request_id: requestId, action },
            {
                onSuccess: () => {
                    setToaster(`Request ${action == "deny" ? "deni" : action}ed successfully!`, "success");
                    setActiveDialog(null);
                },
                onError: (error) => {
                    setToaster(`Failed to ${action} request: ${error.message}`, "error");
                    setActiveDialog(null);
                },
            }
        );
    };

    if (isLoading || isMyTeamLoading) {
        return <p>Loading requests...</p>;
    }

    if (!pendingRequests || pendingRequests.length === 0) {
        return <p>No pending requests.</p>;
    }

    return (
        <div>
            {pendingRequests.map((request: any, index: number) => (
                <RequestItem
                    key={index}
                    style={{
                        flexDirection: isMobile ? "column" : "row",
                        gap: isMobile ? "16px" : "12px",
                        textAlign: isMobile ? "center" : "left",
                    }}
                >
                    <RequestContent>
                        <HackerInfo
                            key={request.hacker_id}
                            hacker_id={request.hacker_id}
                        />
                    </RequestContent>
                    <RequestActions
                        style={{
                            alignItems: isMobile ? "center" : "flex-end",
                            marginTop: isMobile ? "8px" : "0",
                        }}
                    >
                        <ButtonGroup
                            style={{
                                justifyContent: isMobile ? "center" : "flex-start",
                                gap: isMobile ? "8px" : "12px",
                            }}
                        >
                            <AcceptButton
                                style={{
                                    padding: isMobile ? "6px 12px" : "8px 16px",
                                    fontSize: isMobile ? "12px" : "14px",
                                }}
                                disabled={isManagingRequest}
                                onClick={() =>
                                    setActiveDialog({
                                        action: "accept",
                                        requestId: request.request_id,
                                    })
                                }
                            >
                                Accept/Deny
                            </AcceptButton>
                            {/* <DenyButton
                                style={{
                                    padding: isMobile ? "6px 12px" : "8px 16px",
                                    fontSize: isMobile ? "12px" : "14px",
                                }}
                                disabled={isManagingRequest}
                                onClick={() =>
                                    setActiveDialog({
                                        action: "deny",
                                        requestId: request.request_id,
                                    })
                                }
                            >
                                Deny
                            </DenyButton> */}
                        </ButtonGroup>
                    </RequestActions>
                </RequestItem>
            ))}

            {activeDialog && (
                <ConfirmTeamRequestModal
                    isOpen={activeDialog != null}
                    title={
                        "Accept/Deny Request"
                        // activeDialog.action === "accept"
                        //     ? `Accept Request`
                        //     : `Deny Request`
                    }
                    body={`Would you like to accept or deny this request?`}
                    // body={`Are you sure you want to ${activeDialog.action === "accept" ? "accept" : "deny"
                    //     } this request?`}
                    onAccept={() => {
                        // if (activeDialog.action === "accept") {
                        finalizeRequest(activeDialog.requestId, "accept");
                        // }
                    }}
                    onDeny={() => {
                        // if (activeDialog.action === "deny") {
                        finalizeRequest(activeDialog.requestId, "deny");
                        // }
                        // if (activeDialog.action === "deny") {
                        //     finalizeRequest(activeDialog.requestId, "deny");
                        // }
                    }}
                    onClose={() => {
                        setActiveDialog(null);
                    }}
                />
            )}
        </div>
    );
};

export default RequestsCollection;
