import { useMobileDetect } from "../../hooks/useMobileDetect";
import { ReactNode, useState } from "react";
import {
  ButtonSecondary,
  HeaderMainTitle,
  PopoverContent,
  PopoverSide,
  FadeInOnlyComponent,
} from "./index.styles";

interface HeaderProps {
  title: string;
  titleEndIcon?: ReactNode;
  popoverContent?: string | ReactNode;
  buttonText?: string | ReactNode;
  buttonStartIcon?: ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  titleEndIcon,
  popoverContent,
  buttonText = "Back to Dashboard",
  buttonStartIcon,
}) => {
  const isMobile = useMobileDetect();
  const [showHeadingPopover, setShowHeadingPopover] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        flexGrow: 1,
        flexDirection: "column",
        margin: isMobile ? "0 4vw 0 4vw" : "",
        padding: isMobile ? "" : "0 4.5rem",
        width: "fit-content",
        marginBottom: "1.5rem",
        position: "relative",
        zIndex: "3",
      }}
    >
      {buttonText && (
        <a href="/">
          <ButtonSecondary>
            {buttonStartIcon}
            {buttonText}
          </ButtonSecondary>
        </a>
      )}
      <div
        onMouseEnter={() => setShowHeadingPopover(true)}
        onMouseLeave={() => setShowHeadingPopover(false)}
      >
        <HeaderMainTitle>
          {title}
          {titleEndIcon}
        </HeaderMainTitle>
        {popoverContent && (
          <FadeInOnlyComponent
            style={{ zIndex: 5 }}
            shouldFadeInOpp={showHeadingPopover}
          >
            {showHeadingPopover && (
              <PopoverSide>
                <PopoverContent>{popoverContent}</PopoverContent>
              </PopoverSide>
            )}
          </FadeInOnlyComponent>
        )}
      </div>
    </div>
  );
};
