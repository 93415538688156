import React, { ReactNode, useState, useEffect } from "react";
import { PiConfettiDuotone } from "react-icons/pi";
import Results from "./results/results";
import Button from "./button";
import {
  QuizContainer,
  HeaderContainer,
  HeaderTitle,
  QuestionText,
  NavigationContainer,
  StyledDashboard,
  FadeInComponent,
  MainContainer,
  PlayerStatsContainer,
  LightBlueQuizContainer,
  DayContainer,
} from "./index.styles";
import {
  questions
} from "./questions";
import OptionsComponent from "./option-component";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
  InfoBoxContainer,
  ModuleBackground,
} from "../dashboard-container/index.styles";
import EnergyBar from "./energy-bar";
import { ProjectCompletionBar } from "./project-completion-bar";
import ProgressBar from "./progress-bar";
import { FaChevronLeft } from "react-icons/fa";
import { BsFillQuestionCircleFill, BsFillSignStopFill } from "react-icons/bs";
import { Header } from "./header";
import InstructionsComponent from "./instructions";

interface ResultState {
  header: string;
  paragraph: string;
  imageSrc: string | ReactNode;
}

enum NextStepSpecial {
  CONTINUE = 0,
  STARTED_BEFORE_HACKATHON = -1,
  SUCCESS = -2,
  ARGUING_WITH_ORGANIZERS = -3,
  BRIBERY = -4,
  TELLING_JUDGES_TO_WAIT = -5,
}

const HackerQuizComponent: React.FC = () => {
  const [showInstructions, setShowInstructions] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(questions["start"]);

  const startState = new Map<string, any>([
    ["day", 0],
    ["phase", 0],
    ["dayProgress", 0],
    ["energy", 5],
    ["projectCompletion", 0],
    ["inTeam", false],
  ]);
  const [states, updateStates] = useState<Map<string, any>>(
    () =>
      startState
  );
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [showResults, setShowResults] = useState(false);
  const [fadeInOpp, setFadeInOpp] = useState(false);
  const isMobile = useMobileDetect();

  const [resultState, setResultState] = useState<ResultState>({
    header: "Hackathon Adventure Complete!",
    paragraph: "",
    imageSrc: <PiConfettiDuotone size={112} color={"#0B3962"} />,
  });

  const getNewState = (updates: Map<string, any>) => {
    const newState = new Map<string, any>(states);
    updates.forEach((value, key) => {
      key == "inTeam" ? newState.set(key, value) : newState.set(key, newState.get(key) + value);
    });

    if (newState.get("dayProgress") >= 100) {
      newState.set("dayProgress", 0);
      newState.set("day", newState.get("day") + 1);
    }

    newState.set("projectCompletion", Math.min(Math.max(newState.get("projectCompletion"), 0), 20));
    return newState;
  };

  if (showInstructions) {
    return <InstructionsComponent onStartQuiz={() => {
      setShowInstructions(false);
      updateStates(startState);
      setCurrentQuestion(questions["start"]);
      // setCurrentQuestion(questions["whatToDo"]);
      setGameOver(false);
    }} />;
  }

  if (showResults) {
    return (
      <Results
        header={resultState.header}
        paragraph={resultState.paragraph}
        imageSrc={resultState.imageSrc}
      />
    );
  }

  // Special Cases
  // const handleSpecialCase = (specialCase: NextStepSpecial) => {
  //   switch (specialCase) {
  //     case NextStepSpecial.STARTED_BEFORE_HACKATHON:
  //       setResultState({
  //         header: "Disqualified!",
  //         paragraph:
  //           "Starting before the hackathon is against the rules! While your enthusiasm is admirable, it's important to maintain fair play. Try again and start fresh during the official hackathon period.",
  //         imageSrc: <BsFillSignStopFill size={112} color={"#0B3962"} />,
  //       });
  //       return true;

  //     case NextStepSpecial.SUCCESS:
  //       setResultState({
  //         header: "Congratulations!",
  //         paragraph: `You've successfully completed the hackathon! You are eligible to receive additional hacker merch during the hackathon.`,
  //         imageSrc: <PiConfettiDuotone size={112} color={"#0B3962"} />,
  //       });
  //       return true;

  //     case NextStepSpecial.ARGUING_WITH_ORGANIZERS:
  //       setResultState({
  //         header: "Disqualified!",
  //         paragraph: `Unfortunately, you should not be arguging with the organizers. It's important to maintain a positive and respectful attitude. Try again and start fresh during the official hackathon period.`,
  //         imageSrc: <HiEmojiSad size={112} color={"#0B3962"} />,
  //       });
  //       return true;

  //     case NextStepSpecial.BRIBERY:
  //       setResultState({
  //         header: "Disqualified!",
  //         paragraph: `Unfortunately, bribery is against the rules! It's important to maintain fair play. Try again and start fresh during the official hackathon period.`,
  //         imageSrc: <HiEmojiSad size={112} color={"#0B3962"} />,
  //       });
  //       return true;

  //     case NextStepSpecial.TELLING_JUDGES_TO_WAIT:
  //       setResultState({
  //         header: "Disqualified!",
  //         paragraph: `Unfortunately, given the time constraints of the hackathon, it's important to respect the judges' time. Try again and start fresh during the official hackathon period.`,
  //         imageSrc: <HiEmojiSad size={112} color={"#0B3962"} />,
  //       });
  //       return true;

  //     case NextStepSpecial.CONTINUE:
  //     default:
  //       return false;
  //   }
  // };

  const handleOptionClick = (index: any) => {
    setSelectedOption(index);
  };

  const handleNext = (optionIndex: number) => {
    if (currentQuestion.options == undefined) {
      return;
    }
    const selectedOption = currentQuestion.options[optionIndex];

    if (selectedOption.effects == undefined) {
      return;
    }

    const new_effects = new Map(Object.entries(selectedOption["effects"]));

    updateStates(getNewState(new_effects));

    // if (
    //   typeof selectedOption.nextStep === "number" &&
    //   selectedOption.nextStep < 0
    // ) {
    //   const isSpecialCase = handleSpecialCase(
    //     selectedOption.nextStep as NextStepSpecial
    //   );
    //   if (isSpecialCase) {
    //     setShowResults(true);
    //     return;
    //   }
    // }

    // // Determine next step based on nextStep
    // if (selectedOption.nextStep === 0) {
    //   // Transition to appropriate question set
    //   if (currentQuestionSet === preHackathonQuestions) {
    //     const nextSet =
    //       gameMode === "solo" ? soloHackathonQuestions : teamHackathonQuestions;
    //     setCurrentQuestionSet(nextSet);
    //     setCurrentQuestion(0);
    //   } else {
    //     setShowResults(true);
    //   }
    //   setSelectedOption(null);
    //   return;
    // }

    // // Find next question by ID in current set
    // const nextQuestion = currentQuestionSet.find(
    //   (q) => q.id === selectedOption.nextStep
    // );
    // if (nextQuestion) {
    //   const nextIndex = currentQuestionSet.indexOf(nextQuestion);
    //   setCurrentQuestion(nextIndex);
    // }
    const next_energy = new_effects.get("energy") ? new_effects.get("energy") : 0;
    const next_day_progress = new_effects.get("dayProgress") ? new_effects.get("dayProgress") : 0;
    const next_completion = new_effects.get("projectCompletion") ? new_effects.get("projectCompletion") : 0;
    if (states.get("energy") + next_energy <= 0) {
      setCurrentQuestion(questions["gameOverNoEnergy"]);
      setGameOver(true);
      setSelectedOption(null);
    } else if ((states.get("dayProgress") + next_day_progress) == 80 && states.get("day") == 3) {
      setCurrentQuestion(questions["hackathonEnding"]);
      setSelectedOption(null);
    } else if (states.get("projectCompletion") + next_completion >= 20) {
      setCurrentQuestion(questions["gameOverComplete"]);
      setGameOver(true);
      setSelectedOption(null)
    }
    else {
      const nextQuestionKey = selectedOption.nextStep[Math.floor(Math.random() * selectedOption.nextStep.length)];
      setCurrentQuestion(questions[nextQuestionKey]);
      setGameOver(nextQuestionKey.startsWith("gameOver"));
      setSelectedOption(null);
    }
  };

  // if (showResults) {
  //   return (
  //     <Results
  //       header={resultState.header}
  //       paragraph={
  //         resultState.paragraph ||
  //         `
  //         ${projectCompletion >= 100
  //           ? "Congratulations! You successfully completed your project in the hackathon!"
  //           : "Congratulations! You submitted your project to the hackathon!"
  //         }
  //       `
  //       }
  //       imageSrc={resultState.imageSrc}
  //     />
  //   );
  // }

  // const finishDay =
  //   currentQuestion === currentQuestionSet.length - 1
  //     ? "Start the hackathon"
  //     : "Continue";

  // const finishQuiz =
  //   (currentQuestionSet === soloHackathonQuestions ||
  //     currentQuestionSet === teamHackathonQuestions) &&
  //     currentQuestion === currentQuestionSet.length - 1
  //     ? "Finish"
  //     : finishDay;

  return (
    <MainContainer>
      <Header
        title="HackLife"
        buttonText="Back to Dashboard"
        buttonStartIcon={<FaChevronLeft />}
        titleEndIcon={<BsFillQuestionCircleFill size={32} />}
        popoverContent={
          <>
            <p>
              This optional, fun challenge is crafted to make your hackathon
              experience even more exciting. The quiz is entirely optional but
              offers an engaging way to sharpen your skills and think through
              some real-world hackathon scenarios.
            </p>
            <p>
              Plus, there’s a surprise prize waiting for hackers who earn top
              scores!
            </p>
          </>
        }
      />
      <StyledDashboard>
        <ModuleBackground src={"/backgrounds/home.png"} />
        <LightBlueQuizContainer>
          <PlayerStatsContainer isMobile={isMobile}>
            <EnergyBar energyLevel={states.get("energy")} maxEnergy={8} />
            <ProjectCompletionBar
              currentStep={states.get("projectCompletion")}
              maxSteps={20}
            />
          </PlayerStatsContainer>
        </LightBlueQuizContainer>
      </StyledDashboard>
      <StyledDashboard>
        <InfoBoxContainer>
          <QuizContainer isMobile={isMobile}>
            <>
              {/* Header */}
              <HeaderContainer isMobile={isMobile}>
                <DayContainer isMobile={isMobile}>
                  {!gameOver ?
                    (<>
                      <HeaderTitle isMobile={isMobile}>
                        Day {states.get("day")}: {states.get("phase") == 0 ? "Pre-Hackathon" : states.get("phase") == 1 ? "Hackathon" : "Judging"}
                      </HeaderTitle>
                      <ProgressBar progress={states.get("dayProgress")} />
                      {currentQuestion.text == "You arrived early and was one of the first people to check in. You collected your swag bag and have some time to kill before the opening ceremony. How will you spend the time?" &&
                        <img
                          src="/assets/game/arrive.png"
                          alt="arriving"
                          style={{ borderRadius: "10px", border: "1px solid #0DAEF1" }}
                        />
                      }
                      {currentQuestion.text == "You decide to go solo! How should you prepare?" &&
                        <img
                          src="/assets/game/thinking.png"
                          alt="thinking"
                          style={{ borderRadius: "10px", border: "1px solid #0DAEF1" }}
                        />
                      }
                      {currentQuestion.text == "You had an amazing coding session! Everything worked as expected, and you made significant progress." &&
                        <img
                          src="/assets/game/working.png"
                          alt="working"
                          style={{ borderRadius: "10px", border: "1px solid #0DAEF1" }}
                        />
                      }
                      {currentQuestion.text == "The hackathon is coming to an end. How will you finish strong?" &&
                        <img
                          src="/assets/game/ending.png"
                          alt="working"
                          style={{ borderRadius: "10px", border: "1px solid #0DAEF1" }}
                        />
                      }
                    </>
                    ) :
                    (<>
                      <HeaderTitle isMobile={isMobile}>
                        {currentQuestion.text == "You have completed your project, and successfully won the UofTHacks 12 HackLife Game! We look forward to see your real projects during judging on Sunday." ? "Congratulations!" : "Game Over"}
                      </HeaderTitle>
                    </>
                    )}
                </DayContainer>
                {/* <TimeContainer>
                      <HeaderSubtitle>Current time</HeaderSubtitle>
                      <HeaderTitle isMobile={isMobile}>
                        {question.time}
                      </HeaderTitle>
                    </TimeContainer> */}
              </HeaderContainer>
              {/* Questions + Options */}
              <FadeInComponent shouldFadeInOpp={fadeInOpp}>
                <QuestionText isMobile={isMobile}>
                  {currentQuestion.text}
                </QuestionText>
                <OptionsComponent
                  handleOptionClick={handleOptionClick}
                  options={currentQuestion.options}
                  selectedIndex={selectedOption}
                  showOptions={true}
                />
              </FadeInComponent>
            </>
          </QuizContainer>
          {/* Next and Previous Buttons */}
          <NavigationContainer isMobile={isMobile}>
            <Button
              text={"Reset"}
              colour="#0B3962"
              onClick={() =>
                setShowInstructions(true)
              }
              style={{
                minWidth: isMobile ? "8rem" : "10rem",
                marginRight: isMobile ? "0" : "auto",
              }}
            />
            <Button
              text={"Next"}
              colour="#0B3962"
              onClick={() =>
                selectedOption !== null && handleNext(selectedOption)
              }
              disabled={selectedOption === null}
              style={{
                minWidth: isMobile ? "8rem" : "10rem",
                marginLeft: isMobile ? "0" : "auto",
              }}
            />
          </NavigationContainer>
        </InfoBoxContainer>
      </StyledDashboard>
    </MainContainer>
  );
};

export default HackerQuizComponent;
