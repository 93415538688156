import React from "react";
import {
    InfoCardWrapper,
    ProfilePicture,
    CardDetails,
    Badge,
    StyledInter
} from "./index.styles";

interface InfoCardProps {
    name: string;
    pronouns?: string;
    role?: string; // admin or developer
    bio?: string;
    university?: string;
    year?: string;
    skills?: string[];
    shiftTime?: string; // for mentor matching only
    username: string;
    isTeamAdmin?: boolean; // for team finder only
    onLeaveTeam?: () => void; // for team finder only
    removeHacker?: () => void; // optional callback to remove a hacker
}

const InfoCard: React.FC<InfoCardProps> = ({
                                               name,
                                               pronouns,
                                               role,
                                               bio,
                                               university,
                                               year,
                                               skills,
                                               shiftTime,
                                               username,
                                               isTeamAdmin,
                                               onLeaveTeam,
                                               removeHacker
                                           }) => {
    return (
        <InfoCardWrapper>
            <StyledInter>
                <CardDetails>
                    <h3 style={{ marginTop: "0" }}>
                        {name} {pronouns && `(${pronouns})`}
                        {isTeamAdmin && <Badge>Group Admin</Badge>}
                    </h3>
                    {role && <strong>{role}</strong>}
                    {year && university && (
                        <strong>
                            {year} @ {university}
                        </strong>
                    )}
                    <p>
                        <img
                            src={"/assets/icons/discord.svg"}
                            alt="Discord Icon"
                            style={{ width: "16px", height: "16px", marginRight: "8px" }}
                        />
                        {username}
                    </p>
                    {bio && (
                        <p>
                            <strong>About me:</strong> <br />
                            {bio}
                        </p>
                    )}
                    {skills && (
                        <div>
                            Proficient skills:<br />
                            {skills.map((skill, index) => (
                                <Badge key={index}>{skill}</Badge>
                            ))}
                        </div>
                    )}
                    {shiftTime && (
                        <p>
                            <strong>Shift time:</strong> <br />
                            {shiftTime}
                        </p>
                    )}
                    {removeHacker && (
                        <button
                            onClick={removeHacker}
                            style={{
                                marginTop: "10px",
                                background: "none",
                                border: "none",
                                color: "red",
                                cursor: "pointer",
                                fontSize: "1rem"
                            }}
                        >
                            Remove Member
                        </button>
                    )}
                </CardDetails>
            </StyledInter>
        </InfoCardWrapper>
    );
};

export default InfoCard;
