import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { PassportPage } from "./index.styles";
import {
  StampContainer,
  StampApplied,
  StampAccepted,
  StampRSVP,
} from "./stamps.styles";

const ProfPage3 = React.memo(
  ({
    show,
    isMobile,
    isDrawerOpen,
  }: {
    show: boolean;
    isMobile: boolean | null;
    isDrawerOpen?: boolean | null;
  }) => {
    const {} = useSelector((state: RootState) => state.application);

    return (
      <PassportPage id="page-3" show={show}>
        {/* <StampContainer>
          <StampApplied 
            src="/assets/profile/stamps/stamp1.svg" 
            alt="stamp applied"
            mobile={isMobile} 
            isDrawerOpen={isDrawerOpen} 
          />
          <div style={{
            display: "flex", 
            width: "100%",
            justifyContent: "right",
            alignItems: "center",
            height: "10px",
          }}>
            <StampAccepted 
              src="/assets/profile/stamps/stamp2.svg" 
              alt="stamp accepted" 
              mobile={isMobile} 
              isDrawerOpen={isDrawerOpen} 
            />
          </div>
          <StampRSVP 
            src="/assets/profile/stamps/stamp3.svg" 
            alt="stamp rsvp" 
            mobile={isMobile} 
            isDrawerOpen={isDrawerOpen} 
          />
        </StampContainer> */}
      </PassportPage>
    );
  }
);

export default ProfPage3;
