import React from "react";
import { Header } from "./header";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FaChevronLeft } from "react-icons/fa";
import Button from "./button";
import {
  MainContainer,
  StyledDashboard,
  QuizContainer,
  HeaderContainer,
  HeaderTitle,
  QuestionText,
  NavigationContainer,
  DayContainer,
  FadeInComponent,
  LightBlueQuizContainer,
  PlayerStatsContainer,
} from "./index.styles";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import ProgressBar from "./progress-bar";
import {
  ModuleBackground,
  InfoBoxContainer,
} from "../dashboard-container/index.styles";
import EnergyBar from "./energy-bar";
import { ProjectCompletionBar } from "./project-completion-bar";
import { IoGameControllerOutline } from "react-icons/io5";

interface InstructionsComponentProps {
  onStartQuiz: () => void;
}

const InstructionsComponent: React.FC<InstructionsComponentProps> = ({
  onStartQuiz,
}) => {
  const isMobile = useMobileDetect();

  return (
    <MainContainer>
      <Header
        title="HackLife"
        buttonText="Back to Dashboard"
        buttonStartIcon={<FaChevronLeft />}
        titleEndIcon={<BsFillQuestionCircleFill size={32} />}
        popoverContent={
          <>
            <p>
              This optional, fun challenge is crafted to make your hackathon
              experience even more exciting. The quiz is entirely optional but
              offers an engaging way to sharpen your skills.
            </p>
          </>
        }
      />
      <StyledDashboard>
        <ModuleBackground src={"/backgrounds/home.png"} />
        <LightBlueQuizContainer>
          <PlayerStatsContainer isMobile={isMobile}>
            <EnergyBar energyLevel={5} maxEnergy={8} permanent_popover={true} />
            <ProjectCompletionBar currentStep={6} maxSteps={20} permanent_popover={true} />
          </PlayerStatsContainer>
        </LightBlueQuizContainer>
      </StyledDashboard>
      <StyledDashboard>
      </StyledDashboard>
      <StyledDashboard>
        <InfoBoxContainer>
          <QuizContainer isMobile={isMobile}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IoGameControllerOutline size={122} color="#225c90" />
            </div>

            <HeaderTitle isMobile={isMobile}>Welcome to HackLife!</HeaderTitle>

            <FadeInComponent>
              <QuestionText isMobile={isMobile}>
                This optional, text-based simulator game is
                designed to simulate your hackathon experience.
                The game is entirely optional but offers an engaging way to
                sharpen your skills and think through some real-world hackathon
                scenarios.
              </QuestionText>
              <QuestionText isMobile={isMobile}>
                NOTE: This game is entirely optional and for fun. This game does not reward hacker bucks or any reward.
              </QuestionText>
            </FadeInComponent>
          </QuizContainer>

          <NavigationContainer isMobile={isMobile}>
            <Button
              text="Start!"
              colour="#0B3962"
              onClick={onStartQuiz}
              style={{
                minWidth: isMobile ? "8rem" : "10rem",
                marginLeft: isMobile ? "0" : "auto",
              }}
            />
          </NavigationContainer>
        </InfoBoxContainer>
      </StyledDashboard>
    </MainContainer>
  );
};

export default InstructionsComponent;
