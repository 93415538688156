export const binTexts = {
  M: {
    header: "The Moose - The Hero Hacker",
    paragraph:
      "You are the Moose of the Pack! You fearlessly tackle complex coding challenges with innovation, much like a moose navigating dense forests. Your antlers of ingenuity help you break down barriers and forge new paths in the tech world. Just as moose often gather in herds, you thrive in collaborative environments. You're a natural team player, always eager to share your knowledge and expertise. Your ability to foster teamwork and elevate others' contributions sets you apart!",
    imageSrc: "/assets/characters/moose.svg",
    charName: "Moose",
  },
  G: {
    header: "The Goat - The Reliable Leader",
    paragraph:
      "You are the GOAT! Within your team, you're the reliable backbone, carrying the weight with ease, much like a goat traversing rugged terrains. With your strong technical skills, you provide unwavering support and direction. Just as goats carry their load, you tackle complex challenges, lifting your team to new heights in the tech world. You thrive on collaboration, seamlessly integrating your expertise with your teammates. Your ability to adapt and shoulder responsibilities sets you apart as the dependable force.",
    imageSrc: "/assets/characters/goat.svg",
    charName: "Goat",
  },
  A: {
    header: "The Axolotl - The Inquisitive Scholar",
    paragraph:
      "You are the inquisitive Axolotl! Your insatiable curiosity drives you to explore the depths of knowledge, much like an axolotl navigating its aquatic world. With a determined spirit, you delve into various subjects, akin to an axolotl's exploration. You seek insights from every angle, always ready to unearth new perspectives. Your collaborative nature shines as you bring talents together for collective growth. Your commitment to ethical innovation ensures that knowledge propels positive change.",
    imageSrc: "/assets/characters/axolotl.svg",
    charName: "Axolotl",
  },
  F: {
    header: "The Falcon - The Innovator",
    paragraph:
      "You are the Fantastic Falcon! You soar beyond boundaries with visionary eyes, spotting cutting-edge concepts and solutions. Fueled by boundless innovation, you dive into challenges with grace, much like a falcon navigating the skies. Just as falcons are known for their agility, you're a team player, assembling dynamic groups that elevate projects to success. Your ethical compass guides you to harness innovation for the greater good, ensuring technology benefits all.",
    imageSrc: "/assets/characters/eagle.svg",
    charName: "Falcon",
  },
  H: {
    header: "The Hedgehog - The User-Centric Designer",
    paragraph:
      "You are a hardworking Hedgehog! Just as hedgehogs have a unique blend of softness and spines, you bring a balanced approach to the tech world. Your focus is on creating user-centered experiences that are both intuitive and impactful. Like hedgehogs' quills protecting them, your attention to detail safeguards user interactions by ensuring seamless functionality and user-friendly interfaces. Your ability to blend design aesthetics with technical functionality sets you apart as a crucial contributor to your team.",
    imageSrc: "/assets/characters/hedgehog.svg",
    charName: "Hedgehog",
  },
  O: {
    header: "The Owl - The Problem-Solver",
    paragraph:
      "You are the wise Owl! Your analytical mind excels at untangling challenges, much like an owl's wisdom. With logical prowess, you navigate tech puzzles with ease, spotting solutions where others may falter. Just as owls see in the dark, you perceive the unseen, uncovering answers that might be hidden. You thrive in collaboration, adeptly assembling teams to conquer any obstacle. Your commitment to ethical decision-making ensures tech solutions benefit everyone.",
    imageSrc: "/assets/characters/owl.svg",
    charName: "Owl",
  },
};

export const charDetails = {
  moose: {
    header: "The Moose - The Hero Hacker",
    paragraph:
      "You are the Moose of the Pack! You fearlessly tackle complex coding challenges with innovation, much like a moose navigating dense forests. Your antlers of ingenuity help you break down barriers and forge new paths in the tech world. Just as moose often gather in herds, you thrive in collaborative environments. You're a natural team player, always eager to share your knowledge and expertise. Your ability to foster teamwork and elevate others' contributions sets you apart!",
    imageSrc: "/assets/characters/Moose_pfp.png",
    imgBackground: "var(--Light-Pink, #FFCFE7)",
  },
  goat: {
    header: "The Goat - The Reliable Leader",
    paragraph:
      "You are the GOAT! Within your team, you're the reliable backbone, carrying the weight with ease, much like a goat traversing rugged terrains. With your strong technical skills, you provide unwavering support and direction. Just as goats carry their load, you tackle complex challenges, lifting your team to new heights in the tech world. You thrive on collaboration, seamlessly integrating your expertise with your teammates. Your ability to adapt and shoulder responsibilities sets you apart as the dependable force.",
    imageSrc: "/assets/characters/Goat_pfp.png",
    imgBackground: "var(--Light-Orange, #FFD28E)",
  },
  axolotl: {
    header: "The Axolotl - The Inquisitive Scholar",
    paragraph:
      "You are the inquisitive Axolotl! Your insatiable curiosity drives you to explore the depths of knowledge, much like an axolotl navigating its aquatic world. With a determined spirit, you delve into various subjects, akin to an axolotl's exploration. You seek insights from every angle, always ready to unearth new perspectives. Your collaborative nature shines as you bring talents together for collective growth. Your commitment to ethical innovation ensures that knowledge propels positive change.",
    imageSrc: "/assets/characters/Axolotl_pfp.png",
    imgBackground: "var(--Light-Blue, #E1EBFF)",
  },
  falcon: {
    header: "The Falcon - The Innovator",
    paragraph:
      "You are the Fantastic Falcon! You soar beyond boundaries with visionary eyes, spotting cutting-edge concepts and solutions. Fueled by boundless innovation, you dive into challenges with grace, much like a falcon navigating the skies. Just as falcons are known for their agility, you're a team player, assembling dynamic groups that elevate projects to success. Your ethical compass guides you to harness innovation for the greater good, ensuring technology benefits all.",
    imageSrc: "/assets/characters/Eagle_pfp.png",
    imgBackground: "var(--Light-Yellow, #FFF0A4)",
  },
  hedgehog: {
    header: "The Hedgehog - The User-Centric Designer",
    paragraph:
      "You are a hardworking Hedgehog! Just as hedgehogs have a unique blend of softness and spines, you bring a balanced approach to the tech world. Your focus is on creating user-centered experiences that are both intuitive and impactful. Like hedgehogs' quills protecting them, your attention to detail safeguards user interactions by ensuring seamless functionality and user-friendly interfaces. Your ability to blend design aesthetics with technical functionality sets you apart as a crucial contributor to your team.",
    imageSrc: "/assets/characters/Hedgehog_pfp.png",
    imgBackground: "var(--Light-Purple, #E3A1E3)",
  },
  owl: {
    header: "The Owl - The Problem-Solver",
    paragraph:
      "You are the wise Owl! Your analytical mind excels at untangling challenges, much like an owl's wisdom. With logical prowess, you navigate tech puzzles with ease, spotting solutions where others may falter. Just as owls see in the dark, you perceive the unseen, uncovering answers that might be hidden. You thrive in collaboration, adeptly assembling teams to conquer any obstacle. Your commitment to ethical decision-making ensures tech solutions benefit everyone.",
    imageSrc: "/assets/characters/Owl_pfp.png",
    imgBackground: "var(--Light-Teal, #C0F9EB)",
  },
};
