import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import {
    RequestButton,
    TeamCollectionContainer,
    TeamDescription,
    TeamHeader,
    TeamItem,
    TeamList,
    TeamMembers,
    TeamIconContainer,
    DescriptionHeader,
    TeamNameLink,
    CancelButton,
    JoinButton,
    DisabledJoinButton,
} from "./index.styles";
import { HelperHeaderText } from "../../../components/team-finder/index.styles";
import DialogBox from "../../dialog-box";
import {
    useFetchTeams,
    useGetMyRequests,
    useCreateJoinRequest,
    useCancelJoinRequest,
    useJoinTeam,
} from "../../../hooks/useTeamFinder";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { useToaster } from "../../../providers/ToasterProvider";

const TeamCollection = () => {
    const navigate = useNavigate();
    const isMobile = useMobileDetect();
    const { authToken, userID } = useSelector((state: RootState) => state.data);
    const { setToaster } = useToaster();

    const [expandedTeam, setExpandedTeam] = useState<string | null>(null);
    const [activeDialog, setActiveDialog] = useState<{
        action: "cancel";
        requestId: string;
    } | null>(null);

    const { data: availableTeams = [], isLoading: loadingAvailableTeams } = useFetchTeams(
        { is_private: false, exclude_hacker_id: userID, space_available: true, looking_for_member: true},
        authToken
    );

    const { data: requestedTeams = [], isLoading: loadingRequestedTeams } = useGetMyRequests(authToken);
    const { mutate: createJoinRequest } = useCreateJoinRequest(authToken);
    const { mutate: cancelJoinRequest } = useCancelJoinRequest(authToken);
    const { mutate: joinTeam } = useJoinTeam(authToken);

    const toggleExpand = (teamName: string) => {
        setExpandedTeam(expandedTeam === teamName ? null : teamName);
    };

    const handleRequestToJoin = (teamId: string) => {
        createJoinRequest(
            { team_id: parseInt(teamId) },
            {
                onSuccess: () => {
                    setToaster("Join request sent successfully!", "success");
                },
                onError: (error) => {
                    setToaster("Failed to send join request", "error");
                    console.error("Failed to send join request", error);
                },
            }
        );
    };

    const handleCancelRequest = (requestId: string) => {
        cancelJoinRequest(
            { request_id: parseInt(requestId) },
            {
                onSuccess: () => {
                    setToaster("Join request canceled successfully!", "success");
                },
                onError: (error) => {
                    setToaster("Failed to cancel join request", "error");
                    console.error("Failed to cancel join request", error);
                },
            }
        );
    };

    const handleJoinTeam = (teamPin: string) => {
        joinTeam(
            { team_pin: teamPin },
            {
                onSuccess: () => {
                    setToaster("Successfully joined the team!", "success");
                },
                onError: (error) => {
                    setToaster("Failed to join the team", "error");
                    console.error("Failed to join the team", error);
                },
            }
        );
    };

    return (
        <TeamCollectionContainer isMobile={isMobile}>
            <HelperHeaderText style={{ fontSize: isMobile ? "20px" : "30px" }}>Requested To Join</HelperHeaderText>
            <TeamList>
                {loadingRequestedTeams ? (
                    <div>Loading requested teams...</div>
                ) : requestedTeams.length > 0 ? (
                    requestedTeams.map((team: any) => (
                        <TeamItem key={team.team_id} onClick={() => toggleExpand(team.team_name)} isMobile={isMobile}>
                            <TeamHeader isMobile={isMobile}>
                                <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "8px" }}>
                                    <TeamIconContainer>
                                        <img src="/assets/icons/team.svg" alt="Team Icon" />
                                    </TeamIconContainer>
                                    <div>
                                        <TeamNameLink
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                navigate(`/team-info/${team.team_id}`);
                                            }}
                                        >
                                            {team.team_name}
                                        </TeamNameLink>
                                        <TeamMembers>{team.team_members.length} team members</TeamMembers>
                                        {team.team_members.length >= 4 && (
                                            <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                                This team is full (4 or more members).
                                            </p>
                                        )}
                                        {!team.looking_for_member && (
                                            <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                                This team is currently not looking for new members.
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    {team.request_status === "ACC" && team.looking_for_member && team.team_members.length < 4 ? (
                                        <JoinButton onClick={() => handleJoinTeam(team.team_pin)}>
                                            Join Team
                                        </JoinButton>
                                    ) : team.request_status === "ACC" && !team.looking_for_member ? (
                                        <DisabledJoinButton disabled>
                                            Accepted but not looking for members
                                        </DisabledJoinButton>
                                    ) : team.request_status === "RJE" ? (
                                        <DisabledJoinButton disabled>
                                            Request Rejected
                                        </DisabledJoinButton>
                                    ) : (
                                        <></>
                                    )}
                                    <CancelButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveDialog({ action: "cancel", requestId: team.request_id });
                                        }}
                                    >
                                        Cancel Request
                                    </CancelButton>
                                    <img
                                        src={
                                            expandedTeam === team.team_name
                                                ? "/assets/icons/accordian-up.svg"
                                                : "/assets/icons/accordian-down.svg"
                                        }
                                        alt={expandedTeam === team.team_name ? "Collapse" : "Expand"}
                                        style={{ marginLeft: "8px", cursor: "pointer" }}
                                    />
                                </div>
                            </TeamHeader>
                            {expandedTeam === team.team_name && (
                                <>
                                    <DescriptionHeader>Description</DescriptionHeader>
                                    <TeamDescription>{team.team_motto}</TeamDescription>
                                </>
                            )}
                        </TeamItem>
                    ))
                ) : (
                    <p>No requests to join teams.</p>
                )}
            </TeamList>

            <HelperHeaderText style={{ fontSize: isMobile ? "20px" : "30px" }}>Available Teams</HelperHeaderText>
            <TeamList>
                {loadingAvailableTeams ? (
                    <div>Loading available teams...</div>
                ) : availableTeams.length > 0 ? (
                    availableTeams.map((team: any) => (
                        <TeamItem key={team.team_id} onClick={() => toggleExpand(team.team_name)} isMobile={isMobile}>
                            <TeamHeader isMobile={isMobile}>
                                <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "8px" }}>
                                    <TeamIconContainer>
                                        <img src="/assets/icons/team.svg" alt="Team Icon" />
                                    </TeamIconContainer>
                                    <div>
                                        <TeamNameLink
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                navigate(`/team-info/${team.team_id}`);
                                            }}
                                        >
                                            {team.team_name}
                                        </TeamNameLink>
                                        <TeamMembers>{team.team_members.length} team members</TeamMembers>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <RequestButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRequestToJoin(team.team_id);
                                        }}
                                    >
                                        Request
                                    </RequestButton>
                                    <img
                                        src={
                                            expandedTeam === team.team_name
                                                ? "/assets/icons/accordian-up.svg"
                                                : "/assets/icons/accordian-down.svg"
                                        }
                                        alt={expandedTeam === team.team_name ? "Collapse" : "Expand"}
                                        style={{ marginLeft: "8px", cursor: "pointer" }}
                                    />
                                </div>
                            </TeamHeader>
                            {expandedTeam === team.team_name && (
                                <>
                                    <DescriptionHeader>Description</DescriptionHeader>
                                    <TeamDescription>{team.team_motto}</TeamDescription>
                                </>
                            )}
                        </TeamItem>
                    ))
                ) : (
                    <p>No available teams to join.</p>
                )}
            </TeamList>

            {activeDialog && (
                <DialogBox
                    isOpen={activeDialog != null}
                    title="Cancel Request"
                    body="Are you sure you want to cancel this request?"
                    button1Text="Cancel Request"
                    button2Text="Go Back"
                    button1Click={() => {
                        if (activeDialog.action === "cancel") {
                            handleCancelRequest(activeDialog.requestId);
                        }
                        setActiveDialog(null);
                    }}
                    button2Click={() => {
                        setActiveDialog(null);
                    }}
                    onClose={() => {
                        setActiveDialog(null);
                    }}
                />
            )}
        </TeamCollectionContainer>
    );
};

export default TeamCollection;
