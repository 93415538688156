import { QuizQuestion } from "./index.styles";

interface Question {
  text: React.ReactNode;
  options?: {
    text: string;
    nextStep: Array<string>
    effects?: {
      energy?: number;
      projectCompletion?: number;
      inTeam?: boolean;
      dayProgress?: number;
      phase?: number;
    };
  }[];
}

export const questions: { [key: string]: Question } = {
  start: {
    text: "You got accepted into UofTHacks! It's the night before the hackathon, what's the game plan?",
    options: [
      {
        text: "Go all-in. Treat it like a major competition, and focus on coming up with a groundbreaking idea.",
        nextStep: ["findTeam"],
        effects: {
          dayProgress: 50
        },
      },
      {
        text: "Have fun! Aim to learn something new, meet cool people, and just enjoy the experience.",
        nextStep: ["findTeam"],
        effects: {
          dayProgress: 50
        },
      },
      {
        text: "Take a balanced approach: Compete seriously but also enjoy the collaborative and educational side of the event.",
        nextStep: ["findTeam"],
        effects: {
          dayProgress: 50
        },
      },
    ],
  },
  findTeam: {
    text: "You see that there is a team finder app in the portal to help you find a team. You need to decide whether you want to work in a team or go solo. What do you do?",
    options: [
      {
        text: "Go solo! You’re taking on the challenge alone, and while it’ll be tough, you’re excited to prove you can handle it.",
        nextStep: ["goingSolo"],
        effects: {
          dayProgress: 40
        },
      },
      {
        text: "You applied with a group of friends and all of your friends got in! You already have a team set up and you are super excited for the hackathon.",
        nextStep: ["teamFound"],
        effects: {
          dayProgress: 40,
          inTeam: true
        },
      },
      {
        text: "Log onto the UofTHacks Portal and use the team finder to look for teammates.",
        nextStep: ["teamFound", "teamNotFound"],
        effects: {
          dayProgress: 40,
        },
      },
    ],
  },
  teamFound: {
    text: "You have a team! What will you do now?",
    options: [
      {
        text: "Stay up talking with your new teammates and get to know them better.",
        nextStep: ["dayOfHackathon"],
        effects: {
          dayProgress: 10,
          energy: -1,
          inTeam: true
        },
      },
      {
        text: "Bond with your team a bit, and then call it a night. You know you need to be well-rested for the hackathon.",
        nextStep: ["dayOfHackathon"],
        effects: {
          dayProgress: 10,
          energy: 1,
          inTeam: true
        },
      },
      {
        text: "Start building your app in preparation for the hackathon.",
        nextStep: ["gameOverStartingEarly"],
        effects: {
          dayProgress: 10,
          inTeam: true
        },
      },
    ],
  },
  teamNotFound: {
    text: "Unfortunately you were not able to find a team. How should you proceed?",
    options: [
      {
        text: "Get a head start on your project! It's only fair since you're working alone.",
        nextStep: ["gameOverStartingEarly"],
        effects: {
          dayProgress: 10
        },
      },
      {
        text: "Don't worry about it! Get a good night's rest so you're ready for the hackathon. And who knows, you might find a team at the event!",
        nextStep: ["dayOfHackathon"],
        effects: {
          dayProgress: 10,
          energy: 1
        },
      },
      {
        text: "Get upset that you couldn't find a team. Spend the night complaining to your family that you have to work solo.",
        nextStep: ["dayOfHackathon"],
        effects: {
          dayProgress: 10,
          energy: -1
        },
      },
    ],
  },
  goingSolo: {
    text: "You decide to go solo! How should you prepare?",
    options: [
      {
        text: "You grab your friends and hit the local clubs for the night to drink and party!",
        nextStep: ["dayOfHackathon"],
        effects: {
          energy: -2,
          dayProgress: 10,
        },
      },
      {
        text: "You're too excited for the hackthon. You keep yourself occupied by reading the news and going on social media.",
        nextStep: ["dayOfHackathon"],
        effects: {
          energy: -1,
          dayProgress: 10,
        },
      },
      {
        text: "You decide to call it a night. You know you won't be sleeping much during the hackathon, gotta get as much rest now while you can!",
        nextStep: ["dayOfHackathon"],
        effects: {
          energy: 2,
          dayProgress: 10,
        },
      },
      {
        text: "You decide to start working on your project before the hackathon! You want to win and you want to get ahead of everyone.",
        nextStep: ["gameOverStartingEarly"],
        effects: {}
      },
    ],
  },
  dayOfHackathon: {
    text: "It's the day of the hackathon! There's a couple hours before the hackathon starts. What should you do?",
    options: [
      {
        text: "Double check that you have all the things you need, and start making your way to the event.",
        nextStep: ["arrivedEarly"],
        effects: {
          dayProgress: 20
        },
      },
      {
        text: "Pass the time by watching TV and playing video games.",
        nextStep: ["arrived"],
        effects: {
          dayProgress: 20
        },
      },
    ],
  },
  arrivedEarly: {
    text: "You arrived early and was one of the first people to check in. You collected your swag bag and have some time to kill before the opening ceremony. How will you spend the time?",
    options: [
      {
        text: "Go grab some food nearby, you didn't have time to eat before and you're starving!",
        nextStep: ["openingCeremony"],
        effects: {
          energy: 1,
          dayProgress: 20
        },
      },
      {
        text: "Run back home to grab your switch. You realized you'll need to have something to do during breaks.",
        nextStep: ["openingCeremony"],
        effects: {
          energy: -1,
          dayProgress: 20
        },
      },
      {
        text: "Socialize with some of the hackers! Now's the perfect time to make some new friends.",
        nextStep: ["openingCeremony"],
        effects: {
          dayProgress: 20
        },
      },
    ],
  },
  arrived: {
    text: "You arrived to the hackathon just in time! You collected your swag bag and organize your things.",
    options: [
      {
        text: "Continue",
        nextStep: ["openingCeremony"],
        effects: {
          dayProgress: 20
        },
      },
    ],
  },
  openingCeremony: {
    text: "The Opening Ceremony is about to start! What should you do?",
    options: [
      {
        text: "Scan into the auditorium and find a seat near the front to get the best view of the stage.",
        nextStep: ["hackathonStart"],
        effects: {
          phase: 1,
          dayProgress: 20
        },
      },
      {
        text: "Barge into the auditorium and demand a seat near the front. You want to make sure you get the best view of the stage.",
        nextStep: ["gameOverDisruptive"],
        effects: {},
      },
    ],
  },
  gameOverStartingEarly: {
    text: "Starting your project before the hackathon starts is not allowed! You have been disqualified from the hackathon.",
  },
  gameOverDisruptive: {
    text: "You were disruptive during the opening ceremony and got kicked out of the hackathon.",
  },
  gameOverNoEnergy: {
    text: "You were too tired to continue the hackathon and decided to go home and sleep for the rest of the weekend.",
  },

  hackathonStart: {
    text: "The hackathon has officially started! You learned what the theme for this year is, and are ready to start hacking.",
    options: [
      {
        text: "Let's Go!",
        nextStep: ["whatToDo"],
        effects: {},
      },
    ],
  },

  whatToDo: {
    text: "What should you do now?",
    options: [
      {
        text: "Work on your project!",
        nextStep: ["goodCodingSession", "stuckOnBug", "minorProgress"],
        effects: {
          "energy": -1
        },
      },
      {
        text: "See what mini-events are happening around the hackathon.",
        nextStep: ["miniEvent"],
        effects: {},
      },
      {
        text: "Take a break.",
        nextStep: ["takeNap", "socialize"],
        effects: {},
      },
    ],
  },


  // WORK EVENTS
  "workingOnProject": {
    "text": "You decide to focus on your project. What's your approach?",
    "options": [
      {
        "text": "Dive straight into coding. You want to start building as soon as possible.",
        "nextStep": ["goodCodingSession", "stuckOnBug", "minorProgress"],
        "effects": {
          "energy": -1
        }
      },
      {
        "text": "Spend some time brainstorming and planning before coding.",
        "nextStep": ["clearPlan", "overthinking", "minorProgress"],
        "effects": {
          "energy": -1
        }
      },
      {
        "text": "Research the technologies you want to use for your project.",
        "nextStep": ["foundUsefulResources", "overwhelmedByResearch", "minorProgress"],
        "effects": {
          "energy": -1
        }
      }
    ]
  },
  "goodCodingSession": {
    "text": "You had an amazing coding session! Everything worked as expected, and you made significant progress.",
    "options": [
      {
        "text": "Keep the momentum going and continue coding.",
        "nextStep": ["goodCodingSession", "stuckOnBug"],
        "effects": {
          "energy": -2,
          "projectCompletion": 3
        }
      },
      {
        "text": "Take a short break to recharge before continuing.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "projectCompletion": 3,
          "dayProgress": 20
        }
      }
    ]
  },
  "stuckOnBug": {
    "text": "You ran into a frustrating bug that’s slowing you down. What do you do?",
    "options": [
      {
        "text": "Debug tirelessly until you fix the issue.",
        "nextStep": ["fixedBug", "stillStuck"],
        "effects": {
          "energy": -2
        }
      },
      {
        "text": "Ask a mentor for help.",
        "nextStep": ["mentorHelp", "mentorUnavailable"],
        "effects": {
          "energy": 0
        }
      },
      {
        "text": "Take a break and come back to it later.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      }
    ]
  },
  "fixedBug": {
    "text": "You resolved the bug after some effort. Progress is back on track!",
    "options": [
      {
        "text": "Keep coding with renewed motivation.",
        "nextStep": ["goodCodingSession", "minorProgress"],
        "effects": {
          "energy": -1,
          "projectCompletion": 1
        }
      },
      {
        "text": "Take a moment to document the solution, and destress after resolving the bug.",
        "nextStep": ["whatToDo"],
        "effects": {
          "projectCompletion": 1,
          "energy": 1,
          "dayProgress": 20
        }
      }
    ]
  },
  "stillStuck": {
    "text": "The bug persists, and you're feeling frustrated. What's your next move?",
    "options": [
      {
        "text": "Push through and try a different approach.",
        "nextStep": ["fixedBug", "stillStuck"],
        "effects": {
          "energy": -2
        }
      },
      {
        "text": "Ask for help from another hacker or mentor.",
        "nextStep": ["mentorHelp", "mentorUnavailable"],
        "effects": {
          "energy": 0
        }
      },
      {
        "text": "Take a break a find something else to do for a bit.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      },
    ]
  },
  "clearPlan": {
    "text": "Your brainstorming session paid off! You now have a clear plan for your project.",
    "options": [
      {
        "text": "Start coding based on your plan.",
        "nextStep": ["goodCodingSession", "minorProgress"],
        "effects": {
          "projectCompletion": 1,
          "energy": -1
        }
      },
      {
        "text": "Refine the plan further before starting.",
        "nextStep": ["overthinking", "clearPlan"],
        "effects": {
          "projectCompletion": 1,
          "energy": -1
        }
      }
    ]
  },
  "overthinking": {
    "text": "You spent too much time planning, and now you're feeling behind. How will you proceed?",
    "options": [
      {
        "text": "Start coding anyway. It's better to have something than nothing.",
        "nextStep": ["stuckOnBug", "minorProgress"],
        "effects": {
          "energy": -1
        }
      },
      {
        "text": "Take a break to reset your mind.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      }
    ]
  },
  "foundUsefulResources": {
    "text": "You found some excellent resources that will help you with your project.",
    "options": [
      {
        "text": "Dive into coding with your newfound knowledge.",
        "nextStep": ["goodCodingSession", "minorProgress"],
        "effects": {
          "projectCompletion": 1,
          "energy": -1
        }
      },
      {
        "text": "Continue researching to deepen your understanding.",
        "nextStep": ["overwhelmedByResearch", "foundUsefulResources"],
        "effects": {
          "projectCompletion": 1,
          "energy": -1
        }
      }
    ]
  },
  "overwhelmedByResearch": {
    "text": "You feel overwhelmed by the amount of information. It’s hard to focus.",
    "options": [
      {
        "text": "Take a break to process everything you’ve learned.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      },
      {
        "text": "Jump into coding and hope for the best.",
        "nextStep": ["stuckOnBug", "minorProgress"],
        "effects": {
          "energy": -1
        }
      }
    ]
  },
  "mentorHelp": {
    "text": "The mentor provided valuable insights that helped you overcome your challenge.",
    "options": [
      {
        "text": "Use the advice to refine your project.",
        "nextStep": ["goodCodingSession", "minorProgress"],
        "effects": {
          "projectCompletion": 1
        }
      },
      {
        "text": "Continue planning your project based on the feedback.",
        "nextStep": ["clearPlan", "overthinking"],
        "effects": {
          "projectCompletion": 1
        }
      }
    ]
  },
  "mentorUnavailable": {
    "text": "Unfortunately, the mentor was busy helping someone else. What do you do now?",
    "options": [
      {
        "text": "Try solving the issue on your own.",
        "nextStep": ["stuckOnBug", "fixedBug"],
        "effects": {
          "energy": -1
        }
      },
      {
        "text": "Take a break to calm your nerves.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      }
    ]
  },
  "minorProgress": {
    "text": "You made some minor progress, but there’s still a long way to go.",
    "options": [
      {
        "text": "Keep working and try to build momentum.",
        "nextStep": ["goodCodingSession", "stuckOnBug"],
        "effects": {
          "projectCompletion": 1,
          "energy": -1
        }
      },
      {
        "text": "Take a step back and start planning your next steps.",
        "nextStep": ["clearPlan", "overthinking"],
        "effects": {
          "projectCompletion": 1,
          "energy": -1
        }
      },
      {
        "text": "Take a break! You need to recharge before continuing.",
        "nextStep": ["whatToDo"],
        "effects": {
          "projectCompletion": 1,
          "energy": 1,
          "dayProgress": 20
        }
      }
    ]
  },
  "miniEvent": {
    "text": "You decide to check out some of the mini-events happening around the hackathon. There’s a lot going on! What catches your eye?",
    "options": [
      {
        "text": "A workshop on using AI for creative projects.",
        "nextStep": ["learnedNewSkill", "feltOverwhelmed"],
        "effects": {
          "energy": -1
        }
      },
      {
        "text": "A fun mini event with an exciting challenge.",
        "nextStep": ["wonChallenge", "lostChallenge"],
        "effects": {
          "energy": 0
        }
      },
      {
        "text": "A casual networking session with mentors and industry professionals.",
        "nextStep": ["madeConnection", "missedOpportunity"],
        "effects": {
          "energy": 0
        }
      }
    ]
  },
  "learnedNewSkill": {
    "text": "The workshop was insightful! You picked up some great tips that might help with your project.",
    "options": [
      {
        "text": "Get back to working on your project with the new knowledge.",
        "nextStep": ["goodCodingSession"],
        "effects": {
          "projectCompletion": 0,
        }
      }
    ]
  },
  "feltOverwhelmed": {
    "text": "The workshop was a bit too advanced for you, and you left feeling slightly discouraged.",
    "options": [
      {
        "text": "Take a break to clear your head.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      }
    ]
  },
  "wonChallenge": {
    "text": "You crushed the challenge and even won a small prize! The confidence boost is real.",
    "options": [
      {
        "text": "Channel that energy into your project.",
        "nextStep": ["minorProgress"],
        "effects": {
          "energy": 0
        }
      }
    ]
  },
  "lostChallenge": {
    "text": "You didn’t win the coding challenge, but you learned a lot and had fun.",
    "options": [
      {
        "text": "Refocus on your project with a new perspective.",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      }
    ]
  },
  "madeConnection": {
    "text": "You had a great conversation with a mentor who gave you valuable advice.",
    "options": [
      {
        "text": "Incorporate their advice into your project.",
        "nextStep": ["goodCodingSession"],
        "effects": {
          "projectCompletion": 1
        }
      }
    ]
  },
  "missedOpportunity": {
    "text": "You struggled to connect with anyone meaningful during the session, but it was still a good experience.",
    "options": [
      {
        "text": "Reset and find something else to do.",
        "nextStep": ["whatToDo"],
        "effects": {
          "projectCompletion": 0,
          "dayProgress": 20
        }
      }
    ]
  },

  takeNap: {
    "text": "You decide to take a quick nap and feel very recharged!",
    "options": [
      {
        "text": "Let's Go!",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 2,
          "dayProgress": 20
        }
      },
    ]
  },
  socialize: {
    "text": "You decide socialize with some other hackers and make some new friends!",
    "options": [
      {
        "text": "Let's Go!",
        "nextStep": ["whatToDo"],
        "effects": {
          "energy": 1,
          "dayProgress": 20
        }
      },
    ]
  },

  hackathonEnding: {
    "text": "The hackathon is coming to an end. How will you finish strong?",
    "options": [
      {
        "text": "Make some last minute changes to make your project presentable.",
        "nextStep": ["judging"],
        "effects": {
          "dayProgress": 10,
          "phase": 1,
        }
      },
      {
        "text": "Demo what you got. Start preparing your presentation.",
        "nextStep": ["judging"],
        "effects": {
          "dayProgress": 10,
          "phase": 1,
        }
      }
    ]
  },
  judging: {
    "text": "It's time for judging! What's the game plan?",
    "options": [
      {
        "text": "Present your project with a clear and consice demo that has been well timed to fit the judging period. The judging time is limited so you want to make sure you get all your points across.",
        "nextStep": ["gameOverIncomplete"],
        "effects": {
          "dayProgress": 10
        }
      }
    ]
  },
  gameOverIncomplete: {
    "text": "You have completed the UofTHacks 12 HackLife Game. While you did not get to complete your project, you still had a great time at the hackathon! We look forward to see your real projects during judging on Sunday.",
  },
  gameOverComplete: {
    "text": "You have completed your project, and successfully won the UofTHacks 12 HackLife Game! We look forward to see your real projects during judging on Sunday.",
  },
};

// export const soloHackathonQuestions = [
//   {
//     id: 1,
//     question: (
//       <QuizQuestion>
//         You wake up feeling excited. Today is the hackathon! You reach the
//         Hackathon at MyHal in Toronto and what should you do once you reach the
//         building?
//       </QuizQuestion>
//     ),
//     dayProgress: 25,
//     day: "Day 1 - Hackathon",
//     time: "4:00 PM",
//     options: [
//       {
//         text: "Check in at the front desk at the Myhal first floor lobby! You want to receive your hacker pack and sign in before doing any activities.",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "You see the snacks area and you make a beeline for the snacks!",
//         nextStep: 17,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "You notice that the opening ceremony is starting soon! You go to to the Opening Ceremony at MY150 Auditorium.",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 2,
//     question: (
//       <QuizQuestion>
//         You walk up to the front desk at the first floor in Myhal. The person at
//         the front desk asks for your name and successfully finds you on the
//         registration list. They give you your hacker swag and a lanyard. Now
//         that you are checked in, you decide on what to do next...
//       </QuizQuestion>
//     ),
//     dayProgress: 40,
//     day: "Day 1 - Hackathon",
//     time: "5:30 PM",
//     options: [
//       {
//         text: "There's never too many snacks!",
//         nextStep: 13,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "You notice that the opening ceremony is starting soon! You go to to the Opening Ceremony at MY150 Auditorium.",
//         nextStep: 3,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 3,
//     question: (
//       <QuizQuestion>
//         The opening ceremonies are about to begin! You walk into the MY150
//         Auditorium, the buzz of excited chatter filling the air around you.
//         After scanning your lanyard at the entrance, you're free to explore your
//         next move.
//       </QuizQuestion>
//     ),
//     dayProgress: 45,
//     day: "Day 1 - Hackathon",
//     time: "6:00 PM",
//     options: [
//       {
//         text: "Find a seat near the front to get the best view of the stage.",
//         nextStep: 4,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Strike up a conversation with someone nearby to make a new connection and share the anticipation.",
//         nextStep: 4,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Find any seat, and while waiting for the opening ceremonies to start, you check the event schedule for your next steps after the opening ceremony.",
//         nextStep: 4,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 4,
//     question: (
//       <QuizQuestion>
//         The opening ceremony has just wrapped up, and you're brimming with
//         excitement. The theme has been revealed, and you now know the various
//         categories you can compete in. It's time to strategize your approach for
//         the hackathon.
//       </QuizQuestion>
//     ),
//     dayProgress: 60,
//     day: "Day 1 - Hackathon",
//     time: "8:00 PM",
//     options: [
//       {
//         text: "Dive into brainstorming: Grab a notebook or open your laptop to jot down ideas inspired by the theme. Start exploring ways to make your project unique and impactful.",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 5,
//         },
//       },
//       {
//         text: "Go to the sponsor booths and do some networking!",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 3,
//         },
//       },
//       {
//         text: "Attend a workshop! You always been excited to learn new technology.",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 4,
//         },
//       },
//       {
//         text: "Dive right into hacking! You came up with an idea during opening ceremonies and you know time is important so you start hacking right away.",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 10,
//         },
//       },
//     ],
//   },
//   {
//     id: 5,
//     question: (
//       <QuizQuestion>
//         You made some good progress on your project and its now midnight! You
//         start feeling tired while working on your project. Should you continue
//         working or get some rest?
//       </QuizQuestion>
//     ),
//     dayProgress: 0,
//     day: "Day 2 - Hackathon",
//     time: "12:00 AM",
//     options: [
//       {
//         text: "Rest is for the weak! Grab a coffee and stay up all night to get as much as your project done as you can.",
//         nextStep: 14,
//         effects: {
//           energy: -3,
//           projectCompletion: 5,
//         },
//       },
//       {
//         text: "Get a good night's rest. You know you can't make much progress anyways when you are tired.",
//         nextStep: 6,
//         effects: {
//           energy: 3,
//           projectCompletion: 2,
//         },
//       },
//       {
//         text: "A quick little nap won't hurt. You need a refresher anyways so you decide you will sleep for a couple hours, and wake up at 5AM.",
//         nextStep: 15,
//         effects: {
//           energy: -1,
//           projectCompletion: 1,
//         },
//       },
//       {
//         text: "You decide you need a break anyways, but you plan to stay up for the night. You walk around Myhal and see if there are any hacker activities.",
//         nextStep: 6,
//         effects: {
//           energy: 0,
//           projectCompletion: 2,
//         },
//       },
//     ],
//   },
//   {
//     id: 6,
//     question: (
//       <QuizQuestion>
//         You got a good nights rest and you wake up feeling refreshed! You are
//         ready to take on the day with more hacking. What should you do with your
//         morning?
//       </QuizQuestion>
//     ),
//     dayProgress: 25,
//     day: "Day 2 - Hackathon",
//     time: "8:00 AM",
//     options: [
//       {
//         text: "Eat some breakfast! It's still important to eat food!",
//         nextStep: 7,
//         effects: {
//           energy: 2,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Continue where you left off on your project! A break can wait...",
//         nextStep: 7,
//         effects: {
//           energy: 0,
//           projectCompletion: 3,
//         },
//       },
//       {
//         text: "Since you have plenty of time and you made good progress on your project last night, hit the gym!",
//         nextStep: 7,
//         effects: {
//           energy: 0,
//           projectCompletion: 1,
//         },
//       },
//       {
//         text: "Work on the project and attend the sponsor booths later on!",
//         nextStep: 7,
//         effects: {
//           energy: 0,
//           projectCompletion: 2,
//         },
//       },
//     ],
//   },
//   {
//     id: 7,
//     question: (
//       <QuizQuestion>
//         While you were working on the project, you encounter a error! Your
//         codebase isn't working anymore. What should you do?
//       </QuizQuestion>
//     ),
//     dayProgress: 50,
//     day: "Day 2 - Hackathon",
//     time: "12:00 PM",
//     options: [
//       {
//         text: "Ask a mentor for help",
//         nextStep: 16,
//         effects: {
//           energy: 0,
//           projectCompletion: 1,
//         },
//       },
//       {
//         text: "Look for resources online to help you.",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 5,
//         },
//       },
//       {
//         text: "Skip that part and come back to it later.",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Continue trying to solve the project... you know you are so close to solving it!",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 2,
//         },
//       },
//     ],
//   },
//   {
//     id: 8,
//     question: (
//       <QuizQuestion>
//         You found the solution! Hooray! But with the solution, you realize it
//         would take too long for you to finisht the project. Do you...
//       </QuizQuestion>
//     ),
//     dayProgress: 90,
//     day: "Day 2 - Hackathon",
//     time: "11:00 PM",
//     options: [
//       {
//         text: "Re-evaluate and see how you can simplify the project so you are guaranteed to finish.",
//         nextStep: 9,
//         effects: {
//           energy: 0,
//           projectCompletion: 5,
//         },
//       },
//       {
//         text: "Power on! You have faith in yourself to finish it",
//         nextStep: 9,
//         effects: {
//           energy: -2,
//           projectCompletion: 3,
//         },
//       },
//     ],
//   },
//   {
//     id: 9,
//     question: (
//       <QuizQuestion>
//         Hacking is about to end! But you are not done yet... What should you do?
//       </QuizQuestion>
//     ),
//     dayProgress: 20,
//     day: "Day 3 - Hackathon",
//     time: "5:00 AM",
//     options: [
//       {
//         text: "Beg the organizers for some time. Maybe a bribe will help.",
//         nextStep: NextStepSpecial.BRIBERY,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Submit what you have and in your presentation, explain what you wanted to do.",
//         nextStep: 10,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 11,
//     question: (
//       <QuizQuestion>
//         You approach people scanning for IDs at the MyHal Auditorium, however
//         since you don't have your ID and lanyard, you are unable to enter the
//         opening ceremony.
//       </QuizQuestion>
//     ),
//     dayProgress: 60,
//     day: "Day 2 - Hackathon",
//     time: "6:15 PM",
//     options: [
//       {
//         text: "Go to the front desk at the first floor of MyHal",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Argue with them! The opening ceremony is about to start and you don't want to be late!",
//         nextStep: NextStepSpecial.ARGUING_WITH_ORGANIZERS,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 17,
//     question: (
//       <QuizQuestion>
//         You approach the snack booth. The organizers at the snack booth say you
//         must be a hacker to get snacks and they need to see your lanyard. Then,
//         they tell you to go to the Myhal first floor lobby to check in.
//       </QuizQuestion>
//     ),
//     dayProgress: 60,
//     day: "Day 1 - Hackathon",
//     time: "6:15 PM",
//     options: [
//       {
//         text: "Go to the front desk at the first floor of MyHal",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Argue with them! You want your snacks!",
//         nextStep: NextStepSpecial.ARGUING_WITH_ORGANIZERS,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 12,
//     question: (
//       <QuizQuestion>
//         You approach people scanning for IDs at the MyHal Auditorium, however
//         since you don't have your ID and lanyard, you are unable to enter the
//         opening ceremony.
//       </QuizQuestion>
//     ),
//     dayProgress: 60,
//     day: "Day 2 - Hackathon",
//     time: "6:15 PM",
//     options: [
//       {
//         text: "Go to the front desk at the first floor of MyHal",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Argue with them! The opening ceremony is about to start and you don't want to be late!",
//         nextStep: NextStepSpecial.ARGUING_WITH_ORGANIZERS,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 13,
//     question: (
//       <QuizQuestion>
//         You have a great conversation with the organizers and volunteers at the
//         snack booth. There are a bunch of snacks, which one will you choose
//         before going to the opening ceremony?
//       </QuizQuestion>
//     ),
//     dayProgress: 65,
//     day: "Day 2 - Hackathon",
//     time: "6:25 PM",
//     options: [
//       {
//         text: "Chips",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Granola bar",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Juice",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Gummies",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 14,
//     question: (
//       <QuizQuestion>
//         You feel absolutely exhausted and you are struggling to keep your eyes
//         open.
//       </QuizQuestion>
//     ),
//     dayProgress: 5,
//     day: "Day 3 - Hackathon",
//     time: "3:15 AM",
//     options: [
//       {
//         text: "Tough it out a little longer! Sleep is for the weak.",
//         nextStep: 15,
//         effects: {
//           energy: -2,
//           projectCompletion: 2,
//         },
//       },
//       {
//         text: "Go to bed! You still have some more time.",
//         nextStep: 15,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 15,
//     question: (
//       <QuizQuestion>
//         You ended up sleeping much longer than you thought! You slept for more
//         than 12 hours! For the remaining hackathon, what would you do?
//       </QuizQuestion>
//     ),
//     dayProgress: 65,
//     day: "Day 2 - Hackathon",
//     time: "5:00 PM",
//     options: [
//       {
//         text: "It's okay. What is done is done. Try and get as much done as you can for the remainder of the time, but still sleep so you feel refreshed for the next day.",
//         nextStep: 7,
//         effects: {
//           energy: 1,
//           projectCompletion: 2,
//         },
//       },
//       {
//         text: "Pull another all-nighter because you are behind schedule.",
//         nextStep: 7,
//         effects: {
//           energy: -2,
//           projectCompletion: 1,
//         },
//       },
//     ],
//   },
//   {
//     id: 16,
//     question: (
//       <QuizQuestion>
//         You go up to the mentors but you realize they are busy. What should you
//         do?
//       </QuizQuestion>
//     ),
//     dayProgress: 65,
//     day: "Day 2 - Hackathon",
//     time: "6:00 PM",
//     options: [
//       {
//         text: "Interrupt the mentors while they are helping another group. Your project quetions are more urgent!",
//         nextStep: NextStepSpecial.TELLING_JUDGES_TO_WAIT,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Patiently wait until one of the mentors is available to help you with your project.",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   // NOTE: THIS MUST BE THE ENDING QUESTION FOR THE FINISH BUTTON TO MAKE SENSE
//   {
//     id: 10,
//     question: (
//       <QuizQuestion>
//         You submit your project and you are presenting your project. Your 5
//         minutes for judging is almost up and you realize you still have lots to
//         say about your project.
//       </QuizQuestion>
//     ),
//     dayProgress: 50,
//     day: "Day 3 - Hackathon",
//     time: "12:00 PM",
//     options: [
//       {
//         text: "Concisely summarize what you have left to present in the remaining time.",
//         nextStep: 0, // TODO: Game over: Congratulations!
//         effects: {
//           energy: 0,
//           projectCompletion: 10,
//         },
//       },
//       {
//         text: "Tell the judge to wait so you can finish your presentation.",
//         nextStep: NextStepSpecial.TELLING_JUDGES_TO_WAIT,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
// ];

// export const teamHackathonQuestions = [
//   {
//     id: 1,
//     question: (
//       <QuizQuestion>
//         You wake up feeling excited. Today is the hackathon! You reach the
//         Hackathon at MyHal in Toronto and what should you do once you reach the
//         building?
//       </QuizQuestion>
//     ),
//     dayProgress: 25,
//     day: "Day 1 - Hackathon",
//     time: "4:00 PM",
//     options: [
//       {
//         text: "Check in at the front desk at the Myhal first floor lobby! You want to receive your hacker pack and sign in before doing any activities.",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "You see the snacks area and you make a beeline for the snacks!",
//         nextStep: 17,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "You notice that the opening ceremony is starting soon! You go to to the Opening Ceremony at MY150 Auditorium.",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 2,
//     question: (
//       <QuizQuestion>
//         You walk up to the front desk at the first floor in Myhal. The person at
//         the front desk asks for your name and successfully finds you on the
//         registration list. They give you your hacker swag and a lanyard. Now
//         that you are checked in, you decide on what to do next...
//       </QuizQuestion>
//     ),
//     dayProgress: 40,
//     day: "Day 1 - Hackathon",
//     time: "5:30 PM",
//     options: [
//       {
//         text: "There's never too many snacks!",
//         nextStep: 13,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "You notice that the opening ceremony is starting soon! You go to to the Opening Ceremony at MY150 Auditorium.",
//         nextStep: 3,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 3,
//     question: (
//       <QuizQuestion>
//         The opening ceremonies are about to begin! You walk into the MY150
//         Auditorium, the buzz of excited chatter filling the air around you.
//         After scanning your lanyard at the entrance, you're free to explore your
//         next move.
//       </QuizQuestion>
//     ),
//     dayProgress: 45,
//     day: "Day 1 - Hackathon",
//     time: "6:00 PM",
//     options: [
//       {
//         text: "Find a seat near the front to get the best view of the stage.",
//         nextStep: 4,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Strike up a conversation with someone nearby to make a new connection and share the anticipation.",
//         nextStep: 4,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Find any seat, and while waiting for the opening ceremonies to start, you check the event schedule for your next steps after the opening ceremony.",
//         nextStep: 4,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 4,
//     question: (
//       <QuizQuestion>
//         The opening ceremony has just wrapped up, and you're brimming with
//         excitement. The theme has been revealed, and you now know the various
//         categories you can compete in. It's time to strategize your approach for
//         the hackathon.
//       </QuizQuestion>
//     ),
//     dayProgress: 60,
//     day: "Day 1 - Hackathon",
//     time: "8:00 PM",
//     options: [
//       {
//         text: "Dive into brainstorming: Grab a notebook or open your laptop to jot down ideas inspired by the theme. Start exploring ways to make your project unique and impactful.",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 3,
//         },
//       },
//       {
//         text: "Go to the sponsor booths and do some networking!",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 1,
//         },
//       },
//       {
//         text: "Attend a workshop! You always been excited to learn new technology.",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 1,
//         },
//       },
//       {
//         text: "Dive right into hacking! You came up with an idea during opening ceremonies and you know time is important so you start hacking right away.",
//         nextStep: 5,
//         effects: {
//           energy: 0,
//           projectCompletion: 5,
//         },
//       },
//     ],
//   },
//   {
//     id: 5,
//     question: (
//       <QuizQuestion>
//         You made some good progress on your project and its now midnight! You
//         start feeling tired while working on your project. Should you continue
//         working or get some rest?
//       </QuizQuestion>
//     ),
//     dayProgress: 0,
//     day: "Day 2 - Hackathon",
//     time: "12:00 AM",
//     options: [
//       {
//         text: "Rest is for the weak! Grab a coffee and stay up all night to get as much as your project done as you can.",
//         nextStep: 14,
//         effects: {
//           energy: -3,
//           projectCompletion: 5,
//         },
//       },
//       {
//         text: "Get a good night's rest. You know you can't make much progress anyways when you are tired.",
//         nextStep: 6,
//         effects: {
//           energy: 3,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "A quick little nap won't hurt. You need a refresher anyways so you decide you will sleep for a couple hours, and wake up at 5AM.",
//         nextStep: 15,
//         effects: {
//           energy: -1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "You decide you need a break anyways, but you plan to stay up for the night. You walk around Myhal and see if there are any hacker activities.",
//         nextStep: 6,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 6,
//     question: (
//       <QuizQuestion>
//         You got a good nights rest and you wake up feeling refreshed! You are
//         ready to take on the day with more hacking. What should you do with your
//         morning?
//       </QuizQuestion>
//     ),
//     dayProgress: 25,
//     day: "Day 2 - Hackathon",
//     time: "8:00 AM",
//     options: [
//       {
//         text: "Eat some breakfast! It's still important to eat food!",
//         nextStep: 18,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Continue where you left off on your project! A break can wait...",
//         nextStep: 18,
//         effects: {
//           energy: 0,
//           projectCompletion: 3,
//         },
//       },
//       {
//         text: "Since you have plenty of time and you made good progress on your project last night, hit the gym!",
//         nextStep: 18,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Work on the project and attend the sponsor booths later on!",
//         nextStep: 18,
//         effects: {
//           energy: 0,
//           projectCompletion: 2,
//         },
//       },
//     ],
//   },
//   {
//     id: 7,
//     question: (
//       <QuizQuestion>
//         While you were working on the project, you encounter a error! Your
//         codebase isn't working anymore. What should you do?
//       </QuizQuestion>
//     ),
//     dayProgress: 50,
//     day: "Day 2 - Hackathon",
//     time: "12:00 PM",
//     options: [
//       {
//         text: "Ask a mentor for help",
//         nextStep: 16,
//         effects: {
//           energy: 0,
//           projectCompletion: 1,
//         },
//       },
//       {
//         text: "Look for resources online to help you.",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 3,
//         },
//       },
//       {
//         text: "Skip that part and come back to it later.",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Continue trying to solve the project... you know you are so close to solving it!",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 2,
//         },
//       },
//     ],
//   },
//   {
//     id: 8,
//     question: (
//       <QuizQuestion>
//         You found the solution! Hooray! But with the solution, you realize it
//         would take too long for you to finisht the project. Do you...
//       </QuizQuestion>
//     ),
//     dayProgress: 90,
//     day: "Day 2 - Hackathon",
//     time: "11:00 PM",
//     options: [
//       {
//         text: "Re-evaluate and see how you can simplify the project so you are guaranteed to finish.",
//         nextStep: 9,
//         effects: {
//           energy: 0,
//           projectCompletion: 1,
//         },
//       },
//       {
//         text: "Power on! You have faith in yourself to finish it",
//         nextStep: 9,
//         effects: {
//           energy: -2,
//           projectCompletion: 1,
//         },
//       },
//     ],
//   },
//   {
//     id: 9,
//     question: (
//       <QuizQuestion>
//         Hacking is about to end! But you are not done yet... What should you do?
//       </QuizQuestion>
//     ),
//     dayProgress: 20,
//     day: "Day 3 - Hackathon",
//     time: "5:00 AM",
//     options: [
//       {
//         text: "Beg the organizers for some time. Maybe a bribe will help.",
//         nextStep: NextStepSpecial.BRIBERY,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Submit what you have and in your presentation, explain what you wanted to do.",
//         nextStep: 10,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 11,
//     question: (
//       <QuizQuestion>
//         You approach people scanning for IDs at the MyHal Auditorium, however
//         since you don't have your ID and lanyard, you are unable to enter the
//         opening ceremony.
//       </QuizQuestion>
//     ),
//     dayProgress: 60,
//     day: "Day 1 - Hackathon",
//     time: "6:15 PM",
//     options: [
//       {
//         text: "Go to the front desk at the first floor of MyHal",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Argue with them! The opening ceremony is about to start and you don't want to be late!",
//         nextStep: NextStepSpecial.ARGUING_WITH_ORGANIZERS,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 17,
//     question: (
//       <QuizQuestion>
//         You approach the snack booth. The organizers at the snack booth say you
//         must be a hacker to get snacks and they need to see your lanyard. Then,
//         they tell you to go to the Myhal first floor lobby to check in.
//       </QuizQuestion>
//     ),
//     dayProgress: 65,
//     day: "Day 1 - Hackathon",
//     time: "6:25 PM",
//     options: [
//       {
//         text: "Go to the front desk at the first floor of MyHal",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Argue with them! You want your snacks!",
//         nextStep: NextStepSpecial.ARGUING_WITH_ORGANIZERS,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 18,
//     question: (
//       <QuizQuestion>
//         You and your teammate start to have an argument over a feature you
//         created. Your teammate had a different idea in mind and starts insulting
//         you about the feature you made. Would you...
//       </QuizQuestion>
//     ),
//     dayProgress: 55,
//     day: "Day 2 - Hackathon",
//     time: "2:25 PM",
//     options: [
//       {
//         text: "Listen to them and apologize. Do what they want so you can keep the peace of the team.",
//         nextStep: 7,
//         effects: {
//           energy: -1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Listen to them, but also give your reasoning on why you implemented this feature like this. Try and see if you and your teammate can reach a compromise.",
//         nextStep: 19,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 19,
//     question: (
//       <QuizQuestion>
//         Your teammate argues back. After a couple minutes of arguing, both you
//         decided to continue with the project. Suddenly, your teammate decides
//         they rather work solo and not be part of your team. They were going to
//         implement a crucial feature that ties your entire project together, but
//         they decide to leave.
//       </QuizQuestion>
//     ),
//     dayProgress: 65,
//     day: "Day 2 - Hackathon",
//     time: "3:35 PM",
//     options: [
//       {
//         text: "Ignore them. You and your other teammates can do their work anyways.",
//         nextStep: 7,
//         effects: {
//           energy: -1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Apologize and try to compromise with them to get them to stay.",
//         nextStep: 7,
//         effects: {
//           energy: 0,
//           projectCompletion: 5,
//         },
//       },
//       {
//         text: "Apologize, say that are you wrong and ask your teammate to stay.",
//         nextStep: 7,
//         effects: {
//           energy: 0,
//           projectCompletion: 2,
//         },
//       },
//     ],
//   },
//   {
//     id: 12,
//     question: (
//       <QuizQuestion>
//         You approach people scanning for IDs at the MyHal Auditorium, however
//         since you don't have your ID and lanyard, you are unable to enter the
//         opening ceremony.
//       </QuizQuestion>
//     ),
//     dayProgress: 60,
//     day: "Day 1 - Hackathon",
//     time: "6:15 PM",
//     options: [
//       {
//         text: "Go to the front desk at the first floor of MyHal",
//         nextStep: 2,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Argue with them! The opening ceremony is about to start and you don't want to be late!",
//         nextStep: NextStepSpecial.ARGUING_WITH_ORGANIZERS,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 13,
//     question: (
//       <QuizQuestion>
//         You have a great conversation with the organizers and volunteers at the
//         snack booth. There are a bunch of snacks, which one will you choose
//         before going to the opening ceremony?
//       </QuizQuestion>
//     ),
//     dayProgress: 70,
//     day: "Day 1 - Hackathon",
//     time: "6:15 PM",
//     options: [
//       {
//         text: "Chips",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Granola bar",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Juice",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Gummies",
//         nextStep: 3,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 14,
//     question: (
//       <QuizQuestion>
//         You feel absolutely exhausted and you are struggling to keep your eyes
//         open.
//       </QuizQuestion>
//     ),
//     dayProgress: 5,
//     day: "Day 2 - Hackathon",
//     time: "3:15 AM",
//     options: [
//       {
//         text: "Tough it out a little longer! Sleep is for the weak.",
//         nextStep: 15,
//         effects: {
//           energy: -2,
//           projectCompletion: 2,
//         },
//       },
//       {
//         text: "Go to bed! You still have some more time.",
//         nextStep: 15,
//         effects: {
//           energy: 1,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   {
//     id: 15,
//     question: (
//       <QuizQuestion>
//         You ended up sleeping much longer than you thought! You slept for more
//         than 12 hours! For the remaining hackathon, what would you do?
//       </QuizQuestion>
//     ),
//     dayProgress: 65,
//     day: "Day 2 - Hackathon",
//     time: "5:00 PM",
//     options: [
//       {
//         text: "It's okay. What is done is done. Try and get as much done as you can for the remainder of the time, but still sleep so you feel refreshed for the next day.",
//         nextStep: 7,
//         effects: {
//           energy: 1,
//           projectCompletion: 2,
//         },
//       },
//       {
//         text: "Pull another all-nighter because you are behind schedule.",
//         nextStep: 7,
//         effects: {
//           energy: -2,
//           projectCompletion: 1,
//         },
//       },
//     ],
//   },
//   {
//     id: 16,
//     question: (
//       <QuizQuestion>
//         You go up to the mentors but you realize they are busy. What should you
//         do?
//       </QuizQuestion>
//     ),
//     dayProgress: 65,
//     day: "Day 2 - Hackathon",
//     time: "6:00 PM",
//     options: [
//       {
//         text: "Interrupt the mentors while they are helping another group. Your project quetions are more urgent!",
//         nextStep: NextStepSpecial.TELLING_JUDGES_TO_WAIT,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//       {
//         text: "Patiently wait until one of the mentors is available to help you with your project.",
//         nextStep: 8,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
//   // NOTE: THIS MUST BE THE ENDING QUESTION FOR THE FINISH BUTTON TO MAKE SENSE
//   {
//     id: 10,
//     question: (
//       <QuizQuestion>
//         You submit your project and you are presenting your project. Your 5
//         minutes for judging is almost up and you realize you still have lots to
//         say about your project.
//       </QuizQuestion>
//     ),
//     dayProgress: 50,
//     day: "Day 3 - Hackathon",
//     time: "12:00 PM",
//     options: [
//       {
//         text: "Concisely summarize what you have left to present in the remaining time.",
//         nextStep: 0, // TODO: Game over: Congratulations!
//         effects: {
//           energy: 0,
//           projectCompletion: 10,
//         },
//       },
//       {
//         text: "Tell the judge to wait so you can finish your presentation.",
//         nextStep: NextStepSpecial.TELLING_JUDGES_TO_WAIT,
//         effects: {
//           energy: 0,
//           projectCompletion: 0,
//         },
//       },
//     ],
//   },
// ];
