import styled from "styled-components";
import SpaceGrotesk from "../space-grotesk";
import Inter from "../../components/inter";
import Manrope from "../../components/manrope";

type HomePageContentProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
  newHeader?: boolean | null;
};

type ButtonProps = {
  color?: string;
  disabled?: boolean;
};

export const RequestItem = styled.div`
    border: 1.5px solid var(--Brand-Primary-200, #0DAEF1);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
`;

type ContentWrapperProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
  profile?: boolean | null;
};

const DashboardHeaderContainer = styled.div<HomePageContentProps>`
  border-radius: 10px;
  color: var(--White, #225c90);
  padding: 3.125rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  ${({ mobile }) =>
    mobile &&
    `
    max-width: 70vw;
  `}
`;

const MemberInfo = styled.div<HomePageContentProps>``;

const ContentWrapper = styled.div<ContentWrapperProps>`
  margin-left: ${({ isDrawerOpen }) => (isDrawerOpen ? "250px" : "0")};
  transition: margin-left 0.3s ease-in-out;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(13, 174, 241, 0.1),
    rgba(255, 205, 247, 0.1)
  );
  padding: ${({ mobile, profile }) =>
    mobile
      ? profile
        ? "3.75rem 0 3.75rem 1.25rem"
        : "3.75rem 1.25rem"
      : "3.75rem"};
  ${({ mobile, isDrawerOpen }) =>
    isDrawerOpen &&
    mobile &&
    `
    overflow: hidden;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
    margin-left: 0;
    `}
`;

const StyledSpaceGrotesk = styled(SpaceGrotesk)<HomePageContentProps>`
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "40px" : "60px")};
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  color: #225c90;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledManrope = styled(Manrope)<HomePageContentProps>`
  leading-trim: both;
  text-edge: cap;
  /* Body 1 (16px) */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #225c90;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const RSVPDateText = styled.b<HomePageContentProps>`
  color: var(--White, #f9f9f9);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const RSVPButtonContainer = styled.div<HomePageContentProps>`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
  justify-content: ${({ mobile }) => (mobile ? "flex-start" : "flex-end")};
`;

const ButtonContainer = styled.div<HomePageContentProps>`
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
  padding-right: ${({ mobile }) => (mobile ? "0" : "1.5rem")};
`;

const ButtonStyle = styled.div<ButtonProps>`
  background-color: ${({ color }) => color};
  padding: 1.25rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #0DAEF1;
  width: 100%;
  max-width: 75vw;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #ECECEC;
    border: 1px solid #0b96d4;
    cursor: not-allowed;
    opacity: 0.7;
  `}

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  gap: 1.25rem;
  align-items: center;
  align-content: center;
  align-self: center;
  height: 100px;
  outline: 1px solid transparent;
  transition: 0.2s ease-in-out;
  ${({ disabled }) =>
    !disabled &&
    `
  &:hover {
    outline: 1px solid var(--Black, #0b96d4);
  `}
}
`;

const ButtonText = styled(Inter)<ButtonProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const RequestsTable = styled.table`
  width: 100%;
  border-collapse: separate;
  margin-top: 20px;
`;

const RequestsTableHeader = styled.th`
  background-color: #e9f7ff;
  color: #007acc;
  font-weight: 600;
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #d1eaff;
  border-radius: 8px 8px 0 0;
`;

const RequestsTableRow = styled.tr`
  cursor: pointer;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f0f8ff;
  }
`;

const RequestsTableCell = styled.td`
  padding: 14px 12px;
  border: none;
  color: #444444;
  font-size: 14px;
  vertical-align: middle;
`;

const AcceptButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #45a049;
  }
`;

const DenyButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #e53935;
  }
`;

export {
  StyledSpaceGrotesk,
  StyledManrope,
  ButtonContainer,
  DashboardHeaderContainer,
  ContentWrapper,
  ButtonStyle,
  ButtonText,
  RequestsTable,
  RequestsTableHeader,
  RequestsTableRow,
  RequestsTableCell,
  AcceptButton,
  DenyButton,
};
