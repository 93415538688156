import DashboardComponent from "../../components/dashboard";

type DashboardProps = {
    showFAQ?: boolean;
    showProfile?: boolean;
    showRSVP?: boolean;
    showSchedule?: boolean;
    showApplication?: boolean;
    showQuiz?: boolean;
    showChatBot?: boolean;
    showHackerQuiz?: boolean;
};

const Dashboard = ({
                       showFAQ,
                       showProfile,
                       showRSVP,
                       showSchedule,
                       showApplication,
                       showQuiz,
                       showChatBot,
                       showHackerQuiz,
                   }: DashboardProps) => {
    return (
        <DashboardComponent
            showFAQ={showFAQ}
            showProfile={showProfile}
            showRSVP={showRSVP}
            showSchedule={showSchedule}
            showApplication={showApplication}
            showQuiz={showQuiz}
            showChatBot={showChatBot}
            showHackerQuiz={showHackerQuiz}
        />
    );
};

export default Dashboard;
