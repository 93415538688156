import React, { useState } from "react";
import {
  DescriptionBold,
  EnergyBarWrapper,
  EnergyIconsContainer,
  FadeInOnlyComponent,
  Label,
  Popover,
  PopoverContent,
} from "./index.styles";
import { useMobileDetect } from "../../hooks/useMobileDetect";

interface EnergyBarProps {
  energyLevel: number; // Current energy level
  maxEnergy?: number; // Maximum energy (default to 8)
  permanent_popover?: boolean; // Whether the popover should be permanent
}

export const EnergyBar: React.FC<EnergyBarProps> = ({
  energyLevel,
  maxEnergy = 8,
  permanent_popover = false,
}) => {
  const isMobile = useMobileDetect();
  const [showPopover, setShowPopover] = useState(false);

  return (
    <EnergyBarWrapper
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
    >
      <Label>
        <DescriptionBold>Energy</DescriptionBold>
      </Label>
      <EnergyIconsContainer>
        {Array.from({ length: maxEnergy }, (_, index) => {
          const isFilled = index < energyLevel;
          return (
            <EnergyIcon
              key={index}
              stroke="#0DAEF1"
              fill={isFilled ? "#FFCDF7" : "#BAE8FC"}
              width={isMobile ? "24px" : "32px"}
              height={isMobile ? "33px" : "40px"}
            />
          );
        })}
      </EnergyIconsContainer>
      <FadeInOnlyComponent shouldFadeInOpp={showPopover} style={{ zIndex: 3 }}>
        {(showPopover || permanent_popover) && (
          <Popover>
            <PopoverContent>
              <p>
                Depending on your response, your <strong>energy</strong> may go
                up, down, or stay the same.
              </p>
              <p>Beware if your energy gets too low, you will be too tired to finish your project!</p>
            </PopoverContent>
          </Popover>
        )}
      </FadeInOnlyComponent>
    </EnergyBarWrapper>
  );
};

// Energy Icon
interface EnergyIconProps {
  fill: string;
  stroke: string;
  width?: string;
  height?: string;
}

const EnergyIcon: React.FC<EnergyIconProps> = ({
  fill,
  stroke,
  width = "32px",
  height = "40px",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 40"
      fill="none"
    >
      <path
        d="M17.3333 3.33301L4 23.333H16L14.6667 36.6663L28 16.6663H16L17.3333 3.33301Z"
        fill={fill}
        stroke={stroke}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        style={{
          transition: "fill 1s ease, stroke 1s ease",
        }}
      />
    </svg>
  );
};

export default EnergyBar;
