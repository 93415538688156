import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import ResetPassword from "./pages/reset-password";
import CreateAccount from "./pages/create-account";
import { useSelector } from "react-redux";
import {
  RootState,
  resetApplication,
  resetData,
  resetRSVP,
  setDidRSVP,
  setIsAccepted,
  setIsAuthenticatedRedux,
  setIsRound2,
} from "./redux/store";
import ErrorPage from "./pages/error-page";
import { VerifyPageComponent } from "./components/other";
import VerifyAPIPage from "./components/verifyAPIPage";
import { useDispatch } from "react-redux";
import ResetPassForm from "./components/reset-pass-form";
import { API_BASE_URL, API_TYPE, API_VERSION } from "./api/apiConfig";
import TeamFinder from "./pages/team-finder";
import TeamFinderInfo from "./pages/team-finder-info";
import TeamInfo from "./pages/team-info";
import TeamRequests from "./pages/team-requests";
import {
  CHAT_BOT_RELEASE,
  PERSONALITY_QUIZ_RELEASE,
  TEAM_FINDER_RELEASE,
  SCHEDULE_RELEASE,
  HACKLIFE_RELEASE,
  RSVP_CLOSED,
} from "./utils/featureFlags";

import { isQualifiedName } from "typescript";

function App() {
  const { app_completed, character_name } = useSelector(
    (state: RootState) => state.application
  );

  const {
    isAuthenticated,
    jwtExp,
    refreshToken,
    authToken,
    didRSVP,
    isAccepted,
    isWaitlisted,
    isRound2,
  } = useSelector((state: RootState) => state.data);

  const dispatch = useDispatch();

  const resetApp = () => {
    dispatch(setIsAuthenticatedRedux(false));
    dispatch(resetApplication());
    dispatch(resetData());
    dispatch(resetRSVP());
  };

  useEffect(() => {
    if (jwtExp && jwtExp < Date.now() / 1000) {
      resetApp();
    }
  }, [jwtExp]);

  useEffect(() => {
    if (isAuthenticated) {
      fetch(
        `${API_BASE_URL}/${API_VERSION}/${API_TYPE.APP}/getuserstatus/ch3ckth3s7a7s`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      ).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            dispatch(setDidRSVP(data.has_rsvped));
            dispatch(setIsAccepted(data.is_accepted === 1));
            dispatch(setIsRound2(data.round2 === 1));
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      resetApp();
    }
  }, [isAuthenticated]);

  // return (
  //     <Router>
  //   <Routes>
  //     <Route
  //       path="/"
  //       element={<Construction />}
  //     />
  //   </Routes>
  // </Router>
  // )

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
        />
        {/* <Route
          path="/create-account"
          element={isAuthenticated ? <Navigate to="/" /> : <CreateAccount />}
        /> */}
        <Route
          path="/faq"
          element={
            isAuthenticated ? <Dashboard showFAQ /> : <Navigate to="/login" />
          }
        />
        {/* <Route
          path="/schedule"
          element={
            isAuthenticated && didRSVP && isAccepted && SCHEDULE_RELEASE ? (
              <Dashboard showSchedule />
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}

        <Route
          path="/schedule"
          element={SCHEDULE_RELEASE && <Dashboard showSchedule />}
        />

        <Route
          path="/profile"
          element={
            isAuthenticated && didRSVP && (isAccepted || isWaitlisted) ? (
              <Dashboard showProfile />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/rsvp"
          element={
            isAuthenticated &&
            !didRSVP &&
            (isAccepted || isWaitlisted) &&
            !RSVP_CLOSED ? (
              <Dashboard showRSVP />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        {/* <Route
          path="/rsvp"
          element={
            isAuthenticated && isAccepted && isRound2 ? (
              // only for round 2
              <Dashboard showRSVP />
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}
        <Route
          path="/chat"
          element={
            isAuthenticated && CHAT_BOT_RELEASE ? (
              // only for round 2
              <Dashboard showChatBot />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
        {/* <Route
          path="/signup"
          element={isAuthenticated ? <Navigate to="/" /> : <CreateAccount />}
        /> */}
        <Route
          path="/reset-password"
          element={isAuthenticated ? <Navigate to="/" /> : <ResetPassword />}
        />
        {/* <Route
          path="/application"
          element={
            isAuthenticated ? (
              <Dashboard showApplication />
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route
          path="/quiz"
          element={
            isAuthenticated && app_completed && PERSONALITY_QUIZ_RELEASE ? (
              <Dashboard showQuiz />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/game"
          element={
            isAuthenticated && app_completed && HACKLIFE_RELEASE ? (
              <Dashboard showHackerQuiz />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/team-finder"
          element={
            isAuthenticated && isAccepted && didRSVP && TEAM_FINDER_RELEASE ? (
              <TeamFinder />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/team-info/:id"
          element={
            isAuthenticated && isAccepted && didRSVP && TEAM_FINDER_RELEASE ? (
              <TeamInfo />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/team-finder-info"
          element={
            isAuthenticated && isAccepted && didRSVP && TEAM_FINDER_RELEASE ? (
              <TeamFinderInfo />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/team-requests"
          element={
            isAuthenticated && isAccepted && didRSVP && TEAM_FINDER_RELEASE ? (
              <TeamRequests />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        {
          <Route
            path="/verified"
            element={
              <VerifyPageComponent
                title={"Account Verified"}
                body={
                  "Thank you for verifying your email! Please go to the login page to start your application!"
                }
              />
            }
          />
        }
        <Route path="/verify-account/:token" element={<VerifyAPIPage />} />
        <Route path="/new-password/:token" element={<ResetPassForm />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
