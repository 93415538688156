import React, { useState, useEffect } from "react";
import {
    ButtonContainer,
    CancelButton,
    GrayButton,
    RadioButton,
    RadioButtonGroup,
    SaveButton,
    Switch,
    ToggleContainer,
} from "./index.styles";
import { SectionContainer, SectionHeaderText } from "../index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { useParams } from "react-router-dom";
import { useFetchTeamById, useGetMyTeam, useEditTeam, useTogglePrivacy } from "../../../hooks/useTeamFinder";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useToaster } from "../../../providers/ToasterProvider";

const TeamInfoSection = () => {
    const isMobile = useMobileDetect();
    const { id } = useParams<{ id: string }>();
    const teamId = parseInt(id || "0", 10);
    const { authToken } = useSelector((state: RootState) => state.data);
    const { setToaster } = useToaster();

    const { data: teamData, isLoading: isTeamLoading } = useFetchTeamById(teamId, authToken);
    const { data: myTeamData, isLoading: isMyTeamLoading } = useGetMyTeam(authToken);
    const { mutate: editTeam, isPending: isEditingLoading } = useEditTeam(authToken);
    const { mutate: togglePrivacy, isPending: isTogglingPrivacy } = useTogglePrivacy(authToken);

    const [role, setRole] = useState<"viewer" | "member" | "admin">("viewer");
    const [isEditing, setIsEditing] = useState(false);
    const [editableTeamData, setEditableTeamData] = useState({
        team_name: "",
        team_motto: "",
        team_project: "",
        looking_for_member: false,
    });

    useEffect(() => {
        if (teamData) {
            setEditableTeamData({
                team_name: teamData.team_name,
                team_motto: teamData.team_motto,
                team_project: teamData.team_project || "",
                looking_for_member: teamData.looking_for_member,
            });
        }
    }, [teamData]);

    useEffect(() => {
        if (!isMyTeamLoading && myTeamData) {
            if (myTeamData.team_id === teamId) {
                setRole(myTeamData.is_captain ? "admin" : "member");
            } else {
                setRole("viewer");
            }
        }
    }, [myTeamData, isMyTeamLoading, teamId]);

    const handleSaveChanges = () => {
        editTeam(
            {
                team_id: teamId,
                team_name: editableTeamData.team_name,
                team_project: editableTeamData.team_project,
                team_motto: editableTeamData.team_motto,
                looking_for_member: editableTeamData.looking_for_member,
            },
            {
                onSuccess: () => {
                    setIsEditing(false);
                    setToaster("Team information updated successfully!", "success");
                },
                onError: (error: any) => {
                    setToaster(`Failed to update team information: ${error.message}`, "error");
                },
            }
        );
    };

    const handleTogglePrivacy = () => {
        togglePrivacy(
            { team_id: teamId },
            {
                onSuccess: () => {
                    setToaster("Privacy status updated successfully!", "success");
                },
                onError: (error: any) => {
                    setToaster(`Failed to update privacy status: ${error.message}`, "error");
                },
            }
        );
    };

    if (isTeamLoading || isMyTeamLoading) {
        return <p>Loading team information...</p>;
    }

    if (!teamData) {
        return <p>Team not found.</p>;
    }

    return (
        <SectionContainer>
            {!isEditing ? (
                <>
                    <SectionHeaderText>{teamData.team_name}</SectionHeaderText>
                    <h3 style={{ fontSize: "16px", margin: "10px 0", color: "#6E6F74" }}>Description</h3>
                    <p style={{ fontSize: "16px", lineHeight: "1.5", color: "#333333" }}>{teamData.team_motto}</p>

                    {/* <h3 style={{ fontSize: "16px", margin: "10px 0", color: "#6E6F74" }}>Devpost Project</h3>
                    <p style={{ fontSize: "16px", lineHeight: "1.5", color: "#333333" }}>
                        {teamData.team_project || "N/A"}
                    </p> */}

                    <p style={{marginTop: "16px", color: "#333", fontSize: "20px", fontWeight: "bold"}}>
                        <strong>Team ID:</strong> {teamData.team_id}
                    </p>

                    {(role === "member" || role === "admin") && (
                        <p style={{marginTop: "16px", color: "#333"}}>
                        <strong>Team PIN:</strong> {myTeamData.team_pin}
                        </p>
                    )}

                    {role === "admin" && (
                        <GrayButton onClick={() => setIsEditing(true)}>Edit team info</GrayButton>
                    )}
                </>
            ) : (
                <>
                    <SectionHeaderText>Edit Team Profile</SectionHeaderText>

                    <label
                        style={{
                            display: "block",
                            marginBottom: "8px",
                            fontSize: "14px",
                            color: "#333",
                        }}
                    >
                        Team Name
                    </label>
                    <input
                        type="text"
                        value={editableTeamData.team_name}
                        onChange={(e) =>
                            setEditableTeamData({ ...editableTeamData, team_name: e.target.value })
                        }
                        style={{
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            marginBottom: "16px",
                        }}
                    />

                    <label
                        style={{
                            display: "block",
                            marginBottom: "8px",
                            fontSize: "14px",
                            color: "#333",
                        }}
                    >
                        Team Description
                    </label>
                    <textarea
                        value={editableTeamData.team_motto}
                        onChange={(e) =>
                            setEditableTeamData({
                                ...editableTeamData,
                                team_motto: e.target.value,
                            })
                        }
                        style={{
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            marginBottom: "16px",
                            resize: "vertical",
                        }}
                    />

                    {/* <label
                        style={{
                            display: "block",
                            marginBottom: "8px",
                            fontSize: "14px",
                            color: "#333",
                        }}
                    >
                        Devpost Project
                    </label>
                    <input
                        type="text"
                        value={editableTeamData.team_project}
                        onChange={(e) =>
                            setEditableTeamData({
                                ...editableTeamData,
                                team_project: e.target.value,
                            })
                        }
                        placeholder="Link to the Devpost project associated with this team"
                        style={{
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            marginBottom: "16px",
                        }}
                    /> */}

                    <label
                        style={{
                            display: "block",
                            marginBottom: "8px",
                            fontSize: "14px",
                            color: "#333",
                        }}
                    >
                        Visibility
                    </label>

                    <RadioButtonGroup>
                        <RadioButton>
                            <input
                                type="radio"
                                name="visibility"
                                checked={!myTeamData.is_private}
                                onChange={() => !isTogglingPrivacy && handleTogglePrivacy()}
                            />
                            Public
                        </RadioButton>
                        <RadioButton>
                            <input
                                type="radio"
                                name="visibility"
                                checked={myTeamData.is_private}
                                onChange={() => !isTogglingPrivacy && handleTogglePrivacy()}
                            />
                            Private
                        </RadioButton>
                    </RadioButtonGroup>

                    <ToggleContainer
                        style={{
                            flexDirection: isMobile ? "column" : "row",
                            alignItems: isMobile ? "flex-start" : "center",
                        }}
                    >
                        <div>
                            <label style={{ fontSize: "14px", color: "#333", fontWeight: "bold" }}>
                                Open to New Members
                            </label>
                            <p style={{ margin: "8px 0", fontSize: "14px", color: "#6E6F74" }}>
                                Choose whether your group is still looking for members or not.
                            </p>
                        </div>
                        <Switch>
                            <input
                                type="checkbox"
                                checked={editableTeamData.looking_for_member}
                                onChange={(e) =>
                                    setEditableTeamData({
                                        ...editableTeamData,
                                        looking_for_member: e.target.checked,
                                    })
                                }
                            />
                            <span></span>
                        </Switch>
                    </ToggleContainer>

                    <ButtonContainer>
                        <SaveButton onClick={handleSaveChanges} disabled={isEditingLoading}>
                            Save Changes
                        </SaveButton>
                        <CancelButton onClick={() => setIsEditing(false)}>Cancel</CancelButton>
                    </ButtonContainer>
                </>
            )}
        </SectionContainer>
    );
};

export default TeamInfoSection;
